@import "@/styles/_breakpoints.sass";

.font-medium {
    font-size: 18px;

    .header__font {
        &-medium {
            display: flex; }
        &-normal,
        &-big,
        &-hover {
            display: none; } }

    .footer {
        &__bottom-bar {
            &--container-right {
                @include touch {
                    max-width: 280px; } }
            &--link {
                margin-right: 20px; }
            &--author {
                .btn-link-external {
                    margin-right: 0; } } } } }

.font-big {
    font-size: 20px;

    .header__font {
        &-big {
            display: flex; }
        &-normal,
        &-medium {
            display: none; } }

    .footer {
        &__bottom-bar {
            &--container-right {
                @include touch {
                    max-width: 280px; } } } } }
