@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&display=swap);
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.container-fluid {
  padding: 0; }

@media (min-width: 1920px) {
  .container {
    max-width: 1740px; } }

.dropdown-menu {
  padding: 7px 0;
  z-index: 1; }

.dropdown.show .dropdown-toggle::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bg-light {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent; }

.form-control {
  width: 100%; }
  .form-control:focus {
    box-shadow: none; }

select.form-control:focus {
  background-color: #236B83;
  border: solid 1px #707070;
  color: #fff;
  color: #fff; }

label {
  margin: 0; }

@font-face {
  font-family: 'fw_100';
  src: url(/static/media/fw_100.92437988.eot);
  src: url(/static/media/fw_100.92437988.eot) format("embedded-opentype"), url(/static/media/fw_100.116e583e.woff2) format("woff2"), url(/static/media/fw_100.47629bda.woff) format("woff"), url(/static/media/fw_100.8e79d487.ttf) format("truetype"), url(/static/media/fw_100.7e548deb.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fw_100";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-question:before {
  content: '\e800'; }

.icon-accept:before {
  content: '\e801'; }

.icon-accept-double:before {
  content: '\e802'; }

.icon-add:before {
  content: '\e803'; }

.icon-add-area:before {
  content: '\e804'; }

.icon-add-circle:before {
  content: '\e805'; }

.icon-add-reversed:before {
  content: '\e806'; }

.icon-arrow-double-left-full:before {
  content: '\e807'; }

.icon-arrow-double-right-full:before {
  content: '\e808'; }

.icon-arrow-down-full:before {
  content: '\e809'; }

.icon-arrow-down-long:before {
  content: '\e80a'; }

.icon-arrow-left:before {
  content: '\e80b'; }

.icon-arrow-left-full:before {
  content: '\e80c'; }

.icon-arrow-right:before {
  content: '\e80d'; }

.icon-arrow-right-full:before {
  content: '\e80e'; }

.icon-arrow-up-full:before {
  content: '\e80f'; }

.icon-arrow-up-long:before {
  content: '\e810'; }

.icon-bib:before {
  content: '\e811'; }

.icon-bold:before {
  content: '\e812'; }

.icon-book:before {
  content: '\e813'; }

.icon-book-add:before {
  content: '\e814'; }

.icon-book-locked:before {
  content: '\e815'; }

.icon-books:before {
  content: '\e816'; }

.icon-box:before {
  content: '\e817'; }

.icon-calendar:before {
  content: '\e818'; }

.icon-calendar2:before {
  content: '\e819'; }

.icon-calendar-checked:before {
  content: '\e81a'; }

.icon-chevron-double-left:before {
  content: '\e81b'; }

.icon-chevron-double-right:before {
  content: '\e81c'; }

.icon-chevron-down:before {
  content: '\e81d'; }

.icon-chevron-left:before {
  content: '\e81e'; }

.icon-chevron-right:before {
  content: '\e81f'; }

.icon-chevron-right-circle:before {
  content: '\e820'; }

.icon-chevron-up:before {
  content: '\e821'; }

.icon-close:before {
  content: '\e822'; }

.icon-close-circle:before {
  content: '\e823'; }

.icon-comment:before {
  content: '\e824'; }

.icon-comment2:before {
  content: '\e825'; }

.icon-compare:before {
  content: '\e826'; }

.icon-contrast:before {
  content: '\e827'; }

.icon-contrast-active:before {
  content: '\e828'; }

.icon-contrast-reversed:before {
  content: '\e829'; }

.icon-cookies:before {
  content: '\e82a'; }

.icon-dash-short:before {
  content: '\e82b'; }

.icon-dash-wide:before {
  content: '\e82c'; }

.icon-dataset:before {
  content: '\e82d'; }

.icon-datasets:before {
  content: '\e82e'; }

.icon-dot:before {
  content: '\e82f'; }

.icon-download:before {
  content: '\e830'; }

.icon-edit:before {
  content: '\e831'; }

.icon-external:before {
  content: '\e832'; }

.icon-eye:before {
  content: '\e833'; }

.icon-eye-disabled:before {
  content: '\e834'; }

.icon-facebook:before {
  content: '\e835'; }

.icon-files:before {
  content: '\e836'; }

.icon-files2:before {
  content: '\e837'; }

.icon-font:before {
  content: '\e838'; }

.icon-font-active:before {
  content: '\e839'; }

.icon-font-double-active:before {
  content: '\e83a'; }

.icon-font-reversed:before {
  content: '\e83b'; }

.icon-forward:before {
  content: '\e83c'; }

.icon-full-screen:before {
  content: '\e83d'; }

.icon-full-screen-close:before {
  content: '\e83e'; }

.icon-geolocation:before {
  content: '\e83f'; }

.icon-grid:before {
  content: '\e840'; }

.icon-hamburger:before {
  content: '\e841'; }

.icon-header:before {
  content: '\e842'; }

.icon-header2:before {
  content: '\e843'; }

.icon-header3:before {
  content: '\e844'; }

.icon-heart:before {
  content: '\e845'; }

.icon-home:before {
  content: '\e846'; }

.icon-image:before {
  content: '\e847'; }

.icon-image-close:before {
  content: '\e848'; }

.icon-image-geolocation:before {
  content: '\e849'; }

.icon-image-locked:before {
  content: '\e84a'; }

.icon-image-time:before {
  content: '\e84b'; }

.icon-info:before {
  content: '\e84c'; }

.icon-instagram:before {
  content: '\e84d'; }

.icon-italic:before {
  content: '\e84e'; }

.icon-like:before {
  content: '\e84f'; }

.icon-link2:before {
  content: '\e850'; }

.icon-link:before {
  content: '\e851'; }

.icon-list:before {
  content: '\e852'; }

.icon-list-end:before {
  content: '\e853'; }

.icon-locked:before {
  content: '\e854'; }

.icon-locked-circle:before {
  content: '\e855'; }

.icon-mail:before {
  content: '\e856'; }

.icon-more:before {
  content: '\e857'; }

.icon-page-description:before {
  content: '\e858'; }

.icon-page-number:before {
  content: '\e859'; }

.icon-question-reversed:before {
  content: '\e85a'; }

.icon-quote:before {
  content: '\e85b'; }

.icon-reload:before {
  content: '\e85c'; }

.icon-remove-reversed:before {
  content: '\e85d'; }

.icon-search:before {
  content: '\e85e'; }

.icon-search2:before {
  content: '\e85f'; }

.icon-search-cmwl:before {
  content: '\e860'; }

.icon-settings:before {
  content: '\e861'; }

.icon-settings2:before {
  content: '\e862'; }

.icon-settings3:before {
  content: '\e863'; }

.icon-share:before {
  content: '\e864'; }

.icon-solution:before {
  content: '\e865'; }

.icon-star:before {
  content: '\e866'; }

.icon-symbol:before {
  content: '\e867'; }

.icon-symbol2:before {
  content: '\e868'; }

.icon-time:before {
  content: '\e869'; }

.icon-to-end:before {
  content: '\e86a'; }

.icon-to-start:before {
  content: '\e86b'; }

.icon-transcription:before {
  content: '\e86c'; }

.icon-twitter:before {
  content: '\e86d'; }

.icon-unlocked:before {
  content: '\e86e'; }

.icon-verse-answer:before {
  content: '\e86f'; }

.icon-verse-auto-transcription:before {
  content: '\e870'; }

.icon-verse-clear:before {
  content: '\e871'; }

.icon-verse-comment:before {
  content: '\e872'; }

.icon-verse-delete:before {
  content: '\e873'; }

.icon-verse-down:before {
  content: '\e874'; }

.icon-verse-footer:before {
  content: '\e875'; }

.icon-verse-header:before {
  content: '\e876'; }

.icon-verse-move:before {
  content: '\e877'; }

.icon-verse-up:before {
  content: '\e878'; }

.icon-warning:before {
  content: '\e879'; }

.icon-zoom-in:before {
  content: '\e87a'; }

.icon-zoom-out:before {
  content: '\e87b'; }

.Checkbox.checkbox--europeana {
  top: -4px;
  width: 18px;
  height: 18px;
  margin-right: 5px; }
  .Checkbox.checkbox--europeana svg {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  .Checkbox.checkbox--europeana .square {
    stroke: #54A7AE;
    stroke-width: 1px;
    fill: rgba(29, 27, 25, 0.6); }
  .Checkbox.checkbox--europeana.unchecked .square {
    fill-opacity: 1; }
  .Checkbox.checkbox--europeana.checked .square, .Checkbox.checkbox--europeana.checked:hover .square {
    stroke: #22737B;
    fill: #3A979F; }
  .Checkbox.checkbox--europeana:hover .square {
    stroke: #71CDD5;
    fill: rgba(58, 151, 159, 0.47); }
  .Checkbox.checkbox--europeana .tick {
    stroke-width: 2px;
    d: path("M 7 7 v 7 h 12"); }

.Checkbox.checkbox--facet {
  top: -3px;
  width: 18px;
  height: 18px;
  margin-right: 5px; }
  .Checkbox.checkbox--facet svg {
    -webkit-transform: scale(0.65);
            transform: scale(0.65); }
  .Checkbox.checkbox--facet .square {
    stroke: #dddddd;
    stroke-width: 1px;
    fill: #f4f4f4; }
  .Checkbox.checkbox--facet.unchecked .square {
    fill-opacity: 1; }
  .Checkbox.checkbox--facet.checked .square, .Checkbox.checkbox--facet.checked:hover .square {
    stroke: #22737B;
    fill: #22737B; }
  .Checkbox.checkbox--facet:hover .square {
    stroke: #22737B;
    fill: #e4f4f5; }
  .Checkbox.checkbox--facet .tick {
    stroke-width: 2px;
    d: path("M 7 7 v 7 h 12"); }

.Checkbox.checkbox--light {
  top: -5px;
  left: -3px;
  width: 18px;
  height: 18px;
  margin-right: 10px; }
  .Checkbox.checkbox--light svg {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  .Checkbox.checkbox--light .square {
    stroke: #54A7AE;
    stroke-width: 1px;
    fill: #F5F5F5; }
  .Checkbox.checkbox--light.unchecked .square {
    fill-opacity: 1; }
  .Checkbox.checkbox--light.checked .square, .Checkbox.checkbox--light.checked:hover .square {
    stroke: #22737B;
    fill: #3A979F; }
  .Checkbox.checkbox--light:hover .square, .Checkbox.checkbox--light:focus .square {
    stroke: #71CDD5;
    fill: rgba(58, 151, 159, 0.47); }
  .Checkbox.checkbox--light .tick {
    stroke-width: 2px;
    d: path("M 7 7 v 7 h 12"); }

.tt.show {
  opacity: 1; }

.tt.type-info {
  background: white;
  color: black;
  border: 1px solid #d6d6d6;
  border-radius: 0;
  font-size: 0.75rem;
  line-height: 0.9rem;
  max-width: 430px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15); }
  .tt.type-info.place-bottom:after {
    border-bottom-color: white; }
  .tt.type-info.place-bottom:before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -7px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: #d6d6d6;
    border-bottom-style: solid;
    border-bottom-width: 6px; }
  .tt.type-info.place-top:after {
    border-top-color: white; }
  .tt.type-info.place-top:before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -7px;
    left: 50%;
    margin-left: -8px;
    border-top-color: #d6d6d6;
    border-top-style: solid;
    border-top-width: 6px; }
  .tt.type-info.place-right:after {
    border-right-color: white; }
  .tt.type-info.place-right:before {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: -7px;
    top: 50%;
    margin-top: -4px;
    border-right-color: #d6d6d6;
    border-right-style: solid;
    border-right-width: 6px; }

.ReactModal__Overlay {
  z-index: 999; }

body.ReactModal__Body--open {
  overflow: hidden; }

.contrast {
  background-color: #0D0C0C; }
  .contrast .header__contrast-btn .header__contrast-active {
    display: block;
    color: #FFF500;
    background-color: #171514; }
  .contrast .header__contrast-btn .header__contrast-normal, .contrast .header__contrast-btn .header__contrast-hover {
    display: none; }
  .contrast .header__top-bar {
    background-color: #171514;
    border-bottom: 1px solid #707070; }
    .contrast .header__top-bar--link {
      color: #FFF500; }
  .contrast .header__lang-current.nav-item .nav-link {
    color: #FFF500; }
    .contrast .header__lang-current.nav-item .nav-link:hover, .contrast .header__lang-current.nav-item .nav-link:focus {
      border-color: #FFF500;
      color: #FFF500; }
  .contrast .header__lang-current.nav-item.show .nav-link {
    background: #FFF500;
    color: #171514;
    border-color: #FFF500; }
  .contrast .header__lang-item--current.dropdown-item {
    background-color: #FFFFFF;
    color: #171514; }
  .contrast .header__font-btn.btn-primary {
    color: #FFF500;
    background-color: transparent;
    border-color: transparent; }
  .contrast .header__bar {
    background-color: #171514 !important;
    border-bottom: 1px solid #707070; }
    .contrast .header__bar-menu--hamburger span {
      background-color: #FFF500; }
    .contrast .header__bar-menu--hamburger:not(.collapsed) span {
      background-color: #FFF500; }
    .contrast .header__bar-menu .navbar-collapse {
      background: #171514;
      border: 1px solid #333; }
      .contrast .header__bar-menu .navbar-collapse .header__menu-item .nav-link {
        background-color: #171514;
        border-color: #FFF500; }
        .contrast .header__bar-menu .navbar-collapse .header__menu-item .nav-link:hover, .contrast .header__bar-menu .navbar-collapse .header__menu-item .nav-link:focus {
          background-color: #FFF500;
          color: #171514; }
          .contrast .header__bar-menu .navbar-collapse .header__menu-item .nav-link:hover:after, .contrast .header__bar-menu .navbar-collapse .header__menu-item .nav-link:focus:after {
            color: #171514; }
        .contrast .header__bar-menu .navbar-collapse .header__menu-item .nav-link[aria-expanded="true"] {
          background-color: #FFF500;
          color: #171514; }
    .contrast .header__bar-logo--img-leo, .contrast .header__bar-logo--img-uwr {
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1); }
    .contrast .header__bar-logo--img-eu {
      -webkit-filter: invert(1);
              filter: invert(1); }
  .contrast .header__menu-item.nav-item .nav-link {
    color: #FFF500;
    border: 1px solid transparent; }
    .contrast .header__menu-item.nav-item .nav-link:hover, .contrast .header__menu-item.nav-item .nav-link:focus {
      color: #FFF500;
      border-color: #FFF500; }
    .contrast .header__menu-item.nav-item .nav-link::after {
      color: #FFF500; }
  .contrast .header__menu-item.nav-item.show .nav-link {
    background-color: #FFF500;
    color: #171514; }
    .contrast .header__menu-item.nav-item.show .nav-link:after {
      color: #171514; }
  .contrast .header__menu-list--item.dropdown-item {
    color: #FFF500; }
    .contrast .header__menu-list--item.dropdown-item:hover, .contrast .header__menu-list--item.dropdown-item:focus, .contrast .header__menu-list--item.dropdown-item:active, .contrast .header__menu-list--item.dropdown-item.active {
      color: #171514;
      background-color: #FFF500; }
  .contrast .header__menu-title {
    color: #FFFFFF; }
  .contrast .footer__bottom-bar {
    background-color: #171514;
    color: #FFFFFF;
    border-top: 1px solid #383636; }
    .contrast .footer__bottom-bar a {
      color: #FFF500; }
      .contrast .footer__bottom-bar a:focus {
        outline: 1px solid #FFF500;
        outline-offset: 2px;
        box-shadow: none; }
  .contrast .footer__partners {
    background-color: #141414;
    border-top-color: #383636; }
    .contrast .footer__partners-text {
      color: #FFFFFF; }
    .contrast .footer__partners-logos {
      display: none; }
      .contrast .footer__partners-logos--contrast {
        display: flex; }
  .contrast .dropdown-menu {
    background-color: #171514;
    border-color: #FFF500; }
    .contrast .dropdown-menu .dropdown-item:active {
      background: #FFF500;
      color: #171514; }
  .contrast .dropdown-item {
    color: #FFF500; }
    .contrast .dropdown-item:hover, .contrast .dropdown-item:focus {
      background: #FFF500;
      color: #171514; }
  .contrast .cookies {
    border-top: 2px solid #FFF500;
    border-bottom: 2px solid #FFF500; }
    .contrast .cookies__btn {
      border-color: #FFF500;
      color: #FFF500; }
      .contrast .cookies__btn:hover, .contrast .cookies__btn:focus {
        background-color: #FFF500;
        color: #171514; }
  .contrast .content.light {
    background-color: #171514; }
  .contrast .btn-link, .contrast .btn-link-external {
    color: #FFF500; }
    .contrast .btn-link:hover, .contrast .btn-link:focus, .contrast .btn-link-external:hover, .contrast .btn-link-external:focus {
      color: #FFF500; }
  .contrast .btn-primary {
    background-color: #FFF500;
    border-color: #FFF500;
    color: #171514; }
    .contrast .btn-primary:hover, .contrast .btn-primary:focus, .contrast .btn-primary:active, .contrast .btn-primary:not(:disabled):not(.disabled):active {
      background-color: #171514;
      border-color: #FFF500;
      color: #FFF500; }
    .contrast .btn-primary.disabled, .contrast .btn-primary:disabled {
      background-color: #FFF500;
      border-color: #FFF500;
      color: #171514; }
  .contrast .dropdown .btn-regular {
    border-bottom-color: #FFF500;
    color: #FFF500; }
    .contrast .dropdown .btn-regular::after {
      color: #FFF500; }
    .contrast .dropdown .btn-regular + .dropdown-menu .dropdown-item {
      color: #FFF500; }
      .contrast .dropdown .btn-regular + .dropdown-menu .dropdown-item:hover {
        background: #FFF500;
        color: #171514; }
  .contrast .dropdown.show .btn-regular {
    background: #FFF500;
    border-color: #FFF500;
    color: #171514; }
    .contrast .dropdown.show .btn-regular::after {
      color: #171514; }
  .contrast .Checkbox.checkbox--light .square, .contrast .Checkbox.checkbox--europeana .square {
    stroke: #FFF500;
    fill: #171514; }
  .contrast .Checkbox.checkbox--light.checked:hover .square, .contrast .Checkbox.checkbox--light.checked:focus .square, .contrast .Checkbox.checkbox--europeana.checked:hover .square, .contrast .Checkbox.checkbox--europeana.checked:focus .square {
    fill: #FFF500; }
  .contrast .Checkbox.checkbox--light.checked:hover .tick, .contrast .Checkbox.checkbox--light.checked:focus .tick, .contrast .Checkbox.checkbox--europeana.checked:hover .tick, .contrast .Checkbox.checkbox--europeana.checked:focus .tick {
    stroke: #171514; }
  .contrast .ReactModal__Overlay {
    background: rgba(23, 21, 20, 0.8) !important; }
  .contrast .map__marker--cluster, .contrast .map__marker--object {
    -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(1);
    filter: invert(30%) grayscale(100%) brightness(70%) contrast(1); }
  .contrast .leaflet-tile {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%); }
  .contrast .leaflet-popup-content-wrapper, .contrast .leaflet-popup-tip {
    background: black; }
  .contrast .leaflet-container a {
    color: black; }
    .contrast .leaflet-container a.leaflet-popup-close-button {
      color: #FFF000; }

.font-medium {
  font-size: 18px; }
  .font-medium .header__font-medium {
    display: flex; }
  .font-medium .header__font-normal, .font-medium .header__font-big, .font-medium .header__font-hover {
    display: none; }
  @media screen and (max-width: 991.98px) {
    .font-medium .footer__bottom-bar--container-right {
      max-width: 280px; } }
  .font-medium .footer__bottom-bar--link {
    margin-right: 20px; }
  .font-medium .footer__bottom-bar--author .btn-link-external {
    margin-right: 0; }

.font-big {
  font-size: 20px; }
  .font-big .header__font-big {
    display: flex; }
  .font-big .header__font-normal, .font-big .header__font-medium {
    display: none; }
  @media screen and (max-width: 991.98px) {
    .font-big .footer__bottom-bar--container-right {
      max-width: 280px; } }

html, #root {
  height: 100%;
  font-size: 16px; }
  html.medium, #root.medium {
    font-size: 18px; }
  html.big, #root.big {
    font-size: 20px; }

body {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dropdown-toggle:focus {
  outline: 1px solid #236B83; }

.dropdown-item:focus {
  outline: 1px solid #236B83; }

.contrast .dropdown-toggle:focus, .contrast .dropdown-item:focus {
  outline: none; }

.Main_app__3wGp2 {
  text-align: center;
  flex: 1 1; }
  @media (min-width: 1025px) {
    .Main_app__3wGp2 {
      margin-top: -123px; } }
  @media (min-width: 1200px) {
    .Main_app__3wGp2 {
      margin-top: -41px; } }
  .Main_app__container__2BDXx {
    height: 100%;
    display: flex;
    flex-direction: column; }

.btn {
  -webkit-user-select: initial;
     -moz-user-select: initial;
      -ms-user-select: initial;
          user-select: initial; }

.btn-primary {
  background-color: #236B83;
  border-color: #64A3AC;
  color: #fff;
  border-radius: 4px; }
  .btn-primary:hover, .btn-primary:focus {
    background-color: #185367;
    border-color: #508D95; }
  .btn-primary:focus {
    box-shadow: none;
    text-decoration: underline; }
  .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
    background-color: #0E2E39;
    border-color: #3E767E; }
    .btn-primary:active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
      box-shadow: none; }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #236B83;
    border-color: #64A3AC;
    color: #fff;
    opacity: 0.5; }

.btn.disabled, .btn:disabled {
  opacity: 0.5; }

.btn-secondary {
  background-color: #22677E; }

.btn-success {
  background-color: #22677E; }

.btn-warning {
  background-color: #22677E; }

.btn-danger {
  background-color: #22677E; }

.btn-light {
  background-color: #22677E; }

.btn-dark {
  background-color: #22677E; }

.btn-gray {
  background-color: #ECEEEF;
  border-color: #DDDDDD;
  color: #828a91; }

.btn-shadow {
  outline: 0;
  color: #71CDD5;
  background: rgba(28, 28, 28, 0.52); }
  .btn-shadow:hover {
    color: #71CDD5;
    background: rgba(28, 28, 28, 0.52); }
    .btn-shadow:hover span {
      text-decoration: underline; }
  .btn-shadow:active {
    color: white;
    background-color: rgba(28, 28, 28, 0.52); }
  .btn-shadow:focus {
    box-shadow: none; }

.btn-transparent {
  background-color: transparent;
  color: #22737B; }

.btn-link {
  background-color: transparent;
  color: #22737B; }
  .btn-link:active, .btn-link:hover, .btn-link:focus {
    color: #185367;
    text-decoration: underline; }

.btn-link-external {
  background-color: transparent;
  color: #22737B;
  padding: 0;
  font-size: 0.75rem;
  line-height: 0.9375rem; }
  .btn-link-external:hover {
    color: #22737B;
    text-decoration: underline; }
  .btn-link-external:active {
    color: #185367; }
  .btn-link-external i {
    font-size: 0.65rem;
    line-height: 0.7rem;
    vertical-align: text-top; }

.btn-rounded {
  background-color: transparent;
  border: 1px solid #64A3AC;
  color: #71CDD5;
  border-radius: 2rem; }
  .btn-rounded:hover {
    background-color: #236B83;
    color: #FFFFFF; }
  .btn-rounded:focus {
    box-shadow: none; }
  .btn-rounded:active {
    background-color: #185367; }

.btn-link:focus, .btn-link-external:focus {
  outline: 1px solid #22737B;
  outline-offset: 2px; }

.contrast .btn-link:focus, .contrast .btn-link-external:focus {
  outline: 1px solid #fff000; }

.AutoInput_auto-input__3llJs {
  width: 100%;
  position: relative;
  width: 100%; }
  .AutoInput_auto-input__form-control__1LhXZ {
    height: 100%;
    border-radius: 0px; }
  .AutoInput_auto-input__label__-7Gac {
    width: 100%;
    height: 100%; }
  .AutoInput_auto-input__label-span__3H5vk {
    display: none; }
  .AutoInput_auto-input__list__2_N1K {
    background: #fff;
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 5px 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    width: 100%; }
  .AutoInput_auto-input__list-button__LqbvO {
    width: 100%;
    text-align: left;
    background: #fff;
    border: solid 1px #fff;
    color: #212529;
    padding: 0 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem; }
    .AutoInput_auto-input__list-button__LqbvO:hover, .AutoInput_auto-input__list-button__LqbvO:focus {
      background-color: #F5F5F5; }
    .AutoInput_auto-input__list-button__LqbvO:focus {
      border: 1px solid #64A3AC;
      outline: 0; }
    .AutoInput_auto-input__list-button__LqbvO:active {
      background: #e6f4f5;
      color: #212529; }

.AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ {
  background: #FFFFFF; }
  @media screen and (max-width: 991.98px) {
    .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ {
      border-radius: 0; } }
  @media screen and (min-width: 992px) and (max-width: 1199.98px) {
    .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ {
      font-size: 1.1em; } }
  .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ::-webkit-input-placeholder {
    color: #818A91;
    font-style: italic; }
  .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ::-moz-placeholder {
    color: #818A91;
    font-style: italic; }
  .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ:-moz-placeholder {
    color: #818A91;
    font-style: italic; }
  .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ:-ms-input-placeholder {
    color: #818A91;
    font-style: italic; }
  .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ:hover {
    background: #f5f5f5; }
  .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ:active {
    background: #e6f4f5; }
  .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ:focus {
    border: 1px solid #64A3AC;
    box-shadow: none;
    background: #FFFFFF; }

.AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ {
  background-color: transparent;
  border-radius: 0;
  border-color: transparent;
  border-bottom: solid 1px #A5A5A5;
  color: #4B4B4B;
  font-size: 0.85rem;
  padding: 3px 0;
  line-height: 1;
  height: calc(1em + 0.65rem + 2px); }
  .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ:hover {
    border-bottom: solid 1px #22737B; }
  .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ:active {
    border-bottom: solid 1px #4B4B4B; }
  .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ:focus {
    border-color: transparent;
    border-bottom-color: #707070; }
  .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ::-webkit-input-placeholder {
    color: #818A91;
    font-style: italic; }
  .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ::-moz-placeholder {
    color: #818A91;
    font-style: italic; }
  .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ:-moz-placeholder {
    color: #818A91;
    font-style: italic; }
  .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ:-ms-input-placeholder {
    color: #818A91;
    font-style: italic; }

.AutoInput_small__2c3iv .AutoInput_auto-input__field-error__3A4Ke {
  color: #22737B;
  font-size: 0.7rem;
  line-height: 1rem;
  display: inline-block; }

.contrast .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ {
  background: #121212;
  border: 2px solid #FFF500;
  color: #FFF500; }
  .contrast .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ::-webkit-input-placeholder {
    color: #FFF; }
  .contrast .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ::-moz-placeholder {
    color: #FFF; }
  .contrast .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ:-moz-placeholder {
    color: #FFF; }
  .contrast .AutoInput_primary__2rii5 .AutoInput_auto-input__form-control__1LhXZ:-ms-input-placeholder {
    color: #FFF; }

.contrast .AutoInput_primary__2rii5 .AutoInput_auto-input__list-button__LqbvO {
  background: #0D0C0C;
  border-color: #333; }
  .contrast .AutoInput_primary__2rii5 .AutoInput_auto-input__list-button__LqbvO:hover, .contrast .AutoInput_primary__2rii5 .AutoInput_auto-input__list-button__LqbvO:focus {
    background: #FFF500; }

.contrast .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ {
  border-bottom-color: #FFF500;
  color: #FFF500; }
  .contrast .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ::-webkit-input-placeholder {
    color: #FFF; }
  .contrast .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ::-moz-placeholder {
    color: #FFF; }
  .contrast .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ:-moz-placeholder {
    color: #FFF; }
  .contrast .AutoInput_small__2c3iv .AutoInput_auto-input__form-control__1LhXZ:-ms-input-placeholder {
    color: #FFF; }

.Search_search__1QYC9 {
  background-image: url(/static/media/bg.61e3a711.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  background-color: #161616;
  position: relative;
  height: 565px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 991.98px) {
    .Search_search__1QYC9 {
      height: 350px; } }
  @media screen and (min-width: 1200px) {
    .Search_search__1QYC9 {
      height: 405px; } }
  @media screen and (min-width: 1415px) {
    .Search_search__1QYC9 {
      height: 565px; } }
  @media screen and (max-width: 991.98px) {
    .Search_search__container__zXrlz {
      padding: 0;
      margin-top: 6px;
      max-width: none; } }
  @media screen and (min-width: 1200px) {
    .Search_search__container__zXrlz {
      max-width: 800px; } }
  @media screen and (min-width: 1415px) {
    .Search_search__container__zXrlz {
      max-width: 1055px; } }
  @media screen and (max-width: 991.98px) {
    .Search_search__row__2SB8K {
      margin-left: 0;
      margin-right: 0; } }
  @media screen and (max-width: 991.98px) {
    .Search_search__col__XWlf8 {
      padding: 0; } }
  .Search_search__header__3L-yK {
    margin-bottom: 21px; }
  .Search_search__form-wrapper__1xvCO, .Search_search__form-wrapper-links__2-OfT {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 22.5px; }
    @media (max-width: 1024px) {
      .Search_search__form-wrapper__1xvCO, .Search_search__form-wrapper-links__2-OfT {
        display: none; } }
    .Search_search__form-wrapper__1xvCO.Search_header__3j0OL, .Search_search__form-wrapper-links__2-OfT.Search_header__3j0OL {
      position: relative;
      margin-top: 10px; }
      @media (max-width: 1199px) {
        .Search_search__form-wrapper__1xvCO.Search_header__3j0OL, .Search_search__form-wrapper-links__2-OfT.Search_header__3j0OL {
          top: 15px; } }
      @media (max-width: 1024px) {
        .Search_search__form-wrapper__1xvCO.Search_header__3j0OL, .Search_search__form-wrapper-links__2-OfT.Search_header__3j0OL {
          display: flex; } }
      .Search_search__form-wrapper__1xvCO.Search_header__3j0OL .Search_search__advsearch__link__2_jE8, .Search_search__form-wrapper-links__2-OfT.Search_header__3j0OL .Search_search__advsearch__link__2_jE8 {
        font-size: 0.8rem;
        line-height: 1.5rem; }
      @media (min-width: 1024px) {
        .Search_search__form-wrapper__1xvCO.Search_header__3j0OL:first-of-type, .Search_search__form-wrapper-links__2-OfT.Search_header__3j0OL:first-of-type {
          margin-top: 0; } }
    @media (max-width: 1024px) {
      .Search_search__form-wrapper__1xvCO, .Search_search__form-wrapper-links__2-OfT {
        display: none; } }
  @media (max-width: 1024px) {
    .Search_search__inputrow__3GeS3 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0; } }
  @media screen and (min-width: 992px) {
    .Search_search__inputrow__3GeS3 {
      margin-top: 1rem;
      margin-bottom: 1rem; } }
  .Search_search__inputgroup__3E-RC {
    display: flex;
    flex-direction: column;
    position: static; }
    @media screen and (min-width: 992px) and (max-width: 1199.98px) {
      .Search_search__inputgroup__3E-RC {
        position: relative;
        top: -16px; } }
  .Search_search__form__4KBjI .Search_search__button__171Sn {
    z-index: 0;
    background: #326b82;
    color: white;
    border-color: #71a6ae;
    border-radius: 0px; }
    .Search_search__form__4KBjI .Search_search__button__171Sn:disabled {
      background-color: #ECEEEF;
      border-color: #DDDDDD;
      color: #828a91; }
    .Search_search__form__4KBjI .Search_search__button__171Sn:hover {
      background: #245366;
      color: white;
      border-color: #52858e; }
  .Search_search__form__4KBjI.Search_header__3j0OL {
    padding: 0px 20px; }
    @media screen and (max-width: 1024px) {
      .Search_search__form__4KBjI.Search_header__3j0OL {
        padding: 0px; } }
  .Search_search__form__4KBjI:focus-within .Search_search__form-wrapper__1xvCO {
    outline: 1px solid #64A3AC; }
  .Search_search__form__4KBjI:focus-within .Search_search__input__1T4DD > input {
    border: 1px solid #64A3AC !important; }
  .Search_search__form__4KBjI:focus-within .Search_search__button__171Sn {
    background: #236B83;
    color: #fff;
    border-color: #64A3AC; }
  @media screen and (max-width: 991.98px) {
    .Search_search__button__171Sn {
      border-radius: 0; } }
  @media screen and (min-width: 992px) {
    .Search_search__button__171Sn {
      width: 78px; } }
  @media screen and (min-width: 1200px) {
    .Search_search__button__171Sn {
      width: 68px; } }
  @media screen and (min-width: 1415px) {
    .Search_search__button__171Sn {
      width: 78px; } }
  .Search_search__button__171Sn:focus {
    border: 1px solid #dfe1e1;
    box-shadow: none; }
  .Search_search__button__171Sn:hover {
    background: #245366;
    color: white;
    border-color: #52858e; }
  .Search_search__button__171Sn:disabled {
    pointer-events: none;
    opacity: 1; }
  .Search_search__button__171Sn.Search_header__3j0OL {
    width: 50px; }
  .Search_search__button__171Sn span {
    display: none; }
  .Search_search__logo__2sgJP {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: -5px;
    z-index: 0;
    height: 60px; }
    .Search_search__logo__2sgJP:before {
      content: "";
      display: block;
      border-radius: 30px;
      width: 180px;
      height: 60px;
      background: rgba(29, 27, 25, 0.7);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 1; }
    .Search_search__logo__2sgJP:after {
      content: "";
      display: block;
      width: 190px;
      height: 70px;
      border-radius: 35px;
      background-image: url(/static/media/bg.61e3a711.png);
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center -155px;
      position: absolute;
      z-index: 0; }
    .Search_search__logo__2sgJP img {
      width: 116px;
      z-index: 2;
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1); }
  .Search_search__motto__1RvY9 {
    background: rgba(29, 27, 25, 0.7);
    color: white;
    font-size: 1.25rem;
    padding: 17px 15px 16px; }
    @media screen and (max-width: 991.98px) {
      .Search_search__motto__1RvY9 {
        font-size: 0.95rem;
        padding: 21px 15px 13px;
        line-height: 1.3; }
        .Search_search__motto__1RvY9 span {
          max-width: 320px; } }
    @media screen and (min-width: 1200px) {
      .Search_search__motto__1RvY9 {
        font-size: 1rem;
        padding: 12px 15px 13px; } }
    @media screen and (min-width: 1415px) {
      .Search_search__motto__1RvY9 {
        font-size: 1.25rem;
        padding: 17px 15px 16px; } }
  .Search_search__input__1T4DD {
    height: 100%;
    flex: 1 1;
    height: 54px; }
    @media screen and (max-width: 991.98px) {
      .Search_search__input__1T4DD {
        height: 45px; } }
    @media screen and (min-width: 1200px) {
      .Search_search__input__1T4DD {
        height: 46px; } }
    @media screen and (min-width: 1415px) {
      .Search_search__input__1T4DD {
        height: 58px; } }
    .Search_search__input__1T4DD.Search_header__3j0OL {
      height: 40px; }
  .Search_search__advsearch__link__2_jE8 {
    border: 0;
    font-size: 0.9rem;
    outline: 0;
    color: #71CDD5;
    background: rgba(28, 28, 28, 0.52);
    float: right;
    white-space: nowrap;
    padding: 0 5px; }
    .Search_search__advsearch__link__2_jE8:hover {
      color: #71CDD5; }
      .Search_search__advsearch__link__2_jE8:hover span {
        text-decoration: underline; }
    .Search_search__advsearch__link__2_jE8:focus {
      color: #71CDD5; }
      .Search_search__advsearch__link__2_jE8:focus span {
        text-decoration: underline; }
    .Search_search__advsearch__link__2_jE8.Search_header__3j0OL {
      background-color: transparent; }
      .Search_search__advsearch__link__2_jE8.Search_header__3j0OL span {
        color: #22737B; }
      .Search_search__advsearch__link__2_jE8.Search_header__3j0OL i {
        color: #22737B; }
      .Search_search__advsearch__link__2_jE8.Search_header__3j0OL:active {
        color: #4B4B4B; }
        .Search_search__advsearch__link__2_jE8.Search_header__3j0OL:active span {
          color: #4B4B4B; }
        .Search_search__advsearch__link__2_jE8.Search_header__3j0OL:active i {
          color: #4B4B4B; }
    .Search_search__advsearch__link__2_jE8:active {
      color: white; }
    @media screen and (min-width: 1415px) {
      .Search_search__advsearch__link__2_jE8 {
        font-size: 1rem; } }
    .Search_search__advsearch__link__2_jE8 i {
      position: relative;
      top: -3px; }
  .Search_search__europeana__3e5PR {
    color: white;
    background: rgba(28, 28, 28, 0.52);
    display: flex;
    align-items: center; }
    .Search_search__europeana__3e5PR.Search_header__3j0OL {
      color: #212529;
      background: transparent;
      line-height: 1.5rem;
      padding-left: 0; }
      .Search_search__europeana__3e5PR.Search_header__3j0OL .Search_search__checkbox__Tvouv {
        margin-right: 0;
        top: -5px; }
      .Search_search__europeana__3e5PR.Search_header__3j0OL label {
        font-size: 0.8rem; }
    .Search_search__europeana__3e5PR label {
      margin-bottom: 0;
      padding: 0 5px;
      font-size: 0.9rem;
      white-space: nowrap;
      cursor: pointer; }
      .Search_search__europeana__3e5PR label:hover, .Search_search__europeana__3e5PR label:focus {
        text-decoration: underline; }
      @media screen and (min-width: 1415px) {
        .Search_search__europeana__3e5PR label {
          font-size: 1rem; } }
    .Search_search__europeana__3e5PR span {
      margin-left: 5px; }
    .Search_search__europeana__3e5PR i {
      color: #71CDD5;
      position: relative;
      top: -3px; }
    .Search_search__europeana__3e5PR svg {
      -webkit-transform: scale(0.65);
              transform: scale(0.65); }
      @media screen and (min-width: 1415px) {
        .Search_search__europeana__3e5PR svg {
          -webkit-transform: scale(0.85);
                  transform: scale(0.85); } }
    .Search_search__europeana__label--span__jA3Rz {
      display: none; }

.contrast .Search_search__1QYC9 {
  background-blend-mode: difference; }
  .contrast .Search_search__motto__1RvY9 {
    background: #1D1B19; }
  .contrast .Search_search__logo__2sgJP::before {
    background: #1D1B19; }
  .contrast .Search_search__logo__2sgJP::after {
    background: rgba(29, 27, 25, 0.7); }
  .contrast .Search_search__advsearch__link__2_jE8,
  .contrast .Search_search__advsearch__link__2_jE8.Search_header__3j0OL span,
  .contrast .Search_search__advsearch__link__2_jE8.Search_header__3j0OL i {
    color: #FFF500;
    background: #1C1C1C; }
    .contrast .Search_search__advsearch__link__2_jE8:active,
    .contrast .Search_search__advsearch__link__2_jE8.Search_header__3j0OL span:active,
    .contrast .Search_search__advsearch__link__2_jE8.Search_header__3j0OL i:active {
      color: #FFF; }
  .contrast .Search_search__europeana__3e5PR i {
    color: #FFF500; }
  .contrast .Search_search__europeana__3e5PR.Search_header__3j0OL span {
    color: #FFF; }
  .contrast .Search_search__button__171Sn {
    background: #1C1C1C;
    border: 2px solid #FFF500;
    color: #FFF; }
  .contrast .Search_search__form__4KBjI .Search_search__button__171Sn:disabled {
    background: #1C1C1C;
    border: 2px solid #FFF500;
    color: #FFF; }
  .contrast .Search_search__form__4KBjI:focus-within .Search_search__form-wrapper__1xvCO {
    outline: 1px solid #FFF500; }
  .contrast .Search_search__form__4KBjI:focus-within .Search_search__input__1T4DD > input {
    border: 1px solid #FFF500 !important; }
  .contrast .Search_search__form__4KBjI:focus-within .Search_search__button__171Sn, .contrast .Search_search__form__4KBjI:focus-within .Search_search__button__171Sn:hover, .contrast .Search_search__form__4KBjI:focus-within .Search_search__button__171Sn:focus {
    background: #FFF500;
    color: #1C1C1C;
    border-color: #FFF500; }

.font-medium .Search_search__input__1T4DD {
  height: 43px; }

@media (max-width: 1024px) {
  .font-big .Search_search__button__171Sn {
    height: 40px;
    padding: 0; } }

.SelectedResources_resources__34h7n {
  background-color: #F5F5F5;
  padding: 50px 0 80px; }
  .SelectedResources_resources__top__3itKD {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .SelectedResources_resources__top-title__377qn {
      color: #4B4B4B;
      font-size: 1rem;
      margin: 0;
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 992px) {
        .SelectedResources_resources__top-title__377qn {
          font-size: 1.25rem; } }
      @media screen and (min-width: 1200px) {
        .SelectedResources_resources__top-title__377qn {
          font-size: 1.375rem; } }
      @media screen and (min-width: 1800px) {
        .SelectedResources_resources__top-title__377qn {
          font-size: 1.75rem; } }
      .SelectedResources_resources__top-title__377qn::after {
        content: '';
        display: block;
        align-self: center;
        width: 145px;
        height: 1px;
        background-color: #C9C9C9;
        margin-top: 20px; }
        @media screen and (min-width: 1800px) {
          .SelectedResources_resources__top-title__377qn::after {
            margin-top: 60px; } }
    .SelectedResources_resources__top-img__10wnw {
      height: 55px; }
      @media screen and (min-width: 992px) {
        .SelectedResources_resources__top-img__10wnw {
          height: 60px;
          margin: 0; } }
      @media screen and (min-width: 1415px) {
        .SelectedResources_resources__top-img__10wnw {
          height: 80px; } }
      @media screen and (min-width: 1800px) {
        .SelectedResources_resources__top-img__10wnw {
          height: 90px; } }
      .SelectedResources_resources__top-img--right__bKNbp {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1); }
  .SelectedResources_resources__content__1plh4 {
    padding-top: 50px; }
    .SelectedResources_resources__content-blocks__2B7cR {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      list-style: none; }
      @media screen and (max-width: 767.98px) {
        .SelectedResources_resources__content-blocks__2B7cR {
          max-width: 270px;
          margin: 0 auto; } }
      @media screen and (min-width: 768px) {
        .SelectedResources_resources__content-blocks__2B7cR {
          display: inline-grid;
          grid-gap: 30px 30px;
          grid-template-columns: 330px 330px;
          grid-template-rows: 1fr auto; } }
      @media screen and (min-width: 1200px) {
        .SelectedResources_resources__content-blocks__2B7cR {
          grid-gap: 40px 40px;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr auto; } }
    .SelectedResources_resources__content-link__1tLOC {
      background-color: #236B83;
      border: 1px solid #64A3AC;
      color: #FFFFFF;
      font-size: 0.875rem;
      border-radius: 4px;
      padding: 15px; }
      @media screen and (min-width: 768px) {
        .SelectedResources_resources__content-link__1tLOC {
          grid-column: span 2;
          max-width: 350px;
          margin: 0 auto;
          font-size: 1rem;
          grid-row-end: auto; } }
      @media screen and (min-width: 1200px) {
        .SelectedResources_resources__content-link__1tLOC {
          grid-column: 2/3;
          max-width: none;
          max-width: initial;
          margin: 0;
          grid-row-end: none; } }
      @media screen and (min-width: 1800px) {
        .SelectedResources_resources__content-link__1tLOC {
          font-size: 1.0625rem;
          padding: 25px; } }
      .SelectedResources_resources__content-link__1tLOC:hover, .SelectedResources_resources__content-link__1tLOC:focus {
        background-color: #185367;
        border-color: #508D95;
        color: #FFFFFF;
        text-decoration: none;
        outline: none; }
      .SelectedResources_resources__content-link__1tLOC:focus {
        box-shadow: 0 0 0 2px #71CDD5; }
      .SelectedResources_resources__content-link__1tLOC:active {
        background-color: #0E2E39;
        border-color: #3E767E; }
  .SelectedResources_resources__block__EFUwq {
    background-color: #FFFFFF;
    text-align: left; }
    @media screen and (max-width: 767.98px) {
      .SelectedResources_resources__block__EFUwq {
        margin-bottom: 20px; } }
    .SelectedResources_resources__block-top__UALLE {
      margin: 0;
      height: 115px;
      position: relative; }
      @media screen and (min-width: 768px) {
        .SelectedResources_resources__block-top__UALLE {
          height: 160px; } }
      @media (min-width: 1920px) {
        .SelectedResources_resources__block-top__UALLE {
          height: 180px; } }
      .SelectedResources_resources__block-top--img__26m-v {
        display: flex;
        width: 100%;
        background-size: cover;
        height: 100%;
        position: absolute;
        top: 0;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
      .SelectedResources_resources__block-top--desc__3kiVO {
        color: #4B4B4B;
        font-size: 1rem;
        display: -webkit-box;
        padding: 25px 15px 5px;
        -webkit-box-orient: vertical;
        overflow: hidden;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0; }
        @media (min-width: 1920px) {
          .SelectedResources_resources__block-top--desc__3kiVO {
            padding: 45px 25px 5px; } }
      .SelectedResources_resources__block-top--text__3XV0f {
        -webkit-transform: translate3d(0, 20px, 0);
                transform: translate3d(0, 20px, 0);
        -webkit-transition: -webkit-transform 0.35s;
        transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        transition: transform 0.35s, -webkit-transform 0.35s;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden; }
        @media screen and (max-width: 767.98px) {
          .SelectedResources_resources__block-top--text__3XV0f {
            -webkit-line-clamp: 3; } }
    .SelectedResources_resources__block-bottom__2XErK {
      display: flex;
      height: 62px; }
      @media screen and (min-width: 1415px) {
        .SelectedResources_resources__block-bottom__2XErK {
          height: 72px; } }
      @media (min-width: 1920px) {
        .SelectedResources_resources__block-bottom__2XErK {
          height: 95px; } }
      .SelectedResources_resources__block-bottom--title__w_2HH {
        font-size: 0.875rem;
        color: #4B4B4B;
        padding: 15px;
        flex: 1 1;
        margin: 0;
        font-weight: 700; }
        @media screen and (min-width: 992px) {
          .SelectedResources_resources__block-bottom--title__w_2HH {
            font-size: 1rem; } }
        @media (min-width: 1920px) {
          .SelectedResources_resources__block-bottom--title__w_2HH {
            font-size: 1.125rem;
            padding: 20px 25px; } }
        .SelectedResources_resources__block-bottom--title__w_2HH span {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden; }
      .SelectedResources_resources__block-bottom--icon__2-H8z {
        background-color: #ECEEEF;
        color: #818A91;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 62px; }
        @media screen and (min-width: 1415px) {
          .SelectedResources_resources__block-bottom--icon__2-H8z {
            flex: 0 1 72px; } }
        @media (min-width: 1920px) {
          .SelectedResources_resources__block-bottom--icon__2-H8z {
            flex: 0 1 95px; } }
    .SelectedResources_resources__block-link__A4sgm {
      display: block;
      box-shadow: 3px 2px 6px 2px rgba(197, 197, 197, 0.72);
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .SelectedResources_resources__block-link__A4sgm:hover, .SelectedResources_resources__block-link__A4sgm:focus {
        text-decoration: none;
        box-shadow: 3px 2px 6px 2px #adadad;
        outline: none;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
        .SelectedResources_resources__block-link__A4sgm:hover .SelectedResources_resources__block-top--desc__3kiVO, .SelectedResources_resources__block-link__A4sgm:focus .SelectedResources_resources__block-top--desc__3kiVO {
          opacity: 1;
          -webkit-transition: 0.3s;
          transition: 0.3s; }
        .SelectedResources_resources__block-link__A4sgm:hover .SelectedResources_resources__block-top--img__26m-v, .SelectedResources_resources__block-link__A4sgm:focus .SelectedResources_resources__block-top--img__26m-v {
          opacity: 0;
          -webkit-transition: 0.3s;
          transition: 0.3s; }
        .SelectedResources_resources__block-link__A4sgm:hover .SelectedResources_resources__block-top--text__3XV0f, .SelectedResources_resources__block-link__A4sgm:focus .SelectedResources_resources__block-top--text__3XV0f {
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0); }
        .SelectedResources_resources__block-link__A4sgm:hover .SelectedResources_resources__block-bottom--title__w_2HH, .SelectedResources_resources__block-link__A4sgm:focus .SelectedResources_resources__block-bottom--title__w_2HH {
          background-color: #F5F5F5;
          -webkit-transition: 0.3s;
          transition: 0.3s; }
        .SelectedResources_resources__block-link__A4sgm:hover .SelectedResources_resources__block-bottom--icon__2-H8z, .SelectedResources_resources__block-link__A4sgm:focus .SelectedResources_resources__block-bottom--icon__2-H8z {
          background-color: #236B83;
          color: #FFFFFF;
          -webkit-transition: 0.3s;
          transition: 0.3s; }
      .SelectedResources_resources__block-link__A4sgm:focus {
        outline: 1px solid #818A91; }
      .SelectedResources_resources__block-link__A4sgm:active .SelectedResources_resources__block-bottom--title__w_2HH {
        background-color: #E4F4F5; }

.contrast .SelectedResources_resources__34h7n {
  background-color: #0D0C0C; }
  .contrast .SelectedResources_resources__top-title__377qn {
    color: #FFF; }
  .contrast .SelectedResources_resources__block__EFUwq {
    background-color: #171514; }
    .contrast .SelectedResources_resources__block-bottom--title__w_2HH {
      color: #FFF500; }
    .contrast .SelectedResources_resources__block-bottom--icon__2-H8z {
      background-color: #FFF500;
      color: #171514; }
    .contrast .SelectedResources_resources__block-top--desc__3kiVO {
      color: #FFF; }
    .contrast .SelectedResources_resources__block-link__A4sgm {
      box-shadow: 0px 0px 0px 1px #707070; }
      .contrast .SelectedResources_resources__block-link__A4sgm:hover, .contrast .SelectedResources_resources__block-link__A4sgm:focus {
        outline: 0;
        box-shadow: 0px 0px 0px 2px #FFF500; }
        .contrast .SelectedResources_resources__block-link__A4sgm:hover .SelectedResources_resources__block-bottom--icon__2-H8z, .contrast .SelectedResources_resources__block-link__A4sgm:focus .SelectedResources_resources__block-bottom--icon__2-H8z {
          background-color: #FFF500;
          color: #171514; }
        .contrast .SelectedResources_resources__block-link__A4sgm:hover .SelectedResources_resources__block-bottom--title__w_2HH, .contrast .SelectedResources_resources__block-link__A4sgm:focus .SelectedResources_resources__block-bottom--title__w_2HH {
          background-color: #171514; }
  .contrast .SelectedResources_resources__content-link__1tLOC {
    background-color: #FFF500;
    border: 2px solid #FFF500;
    color: #171514; }
    .contrast .SelectedResources_resources__content-link__1tLOC:hover, .contrast .SelectedResources_resources__content-link__1tLOC:focus {
      background-color: #0B0B0A;
      color: #FFF500;
      box-shadow: none; }
    .contrast .SelectedResources_resources__content-link__1tLOC:active {
      background-color: #262626; }

.Breadcrumbs_breadcrumbs__3crNl {
  width: 100%;
  padding: 10px 0; }
  @media screen and (max-width: 767.98px) {
    .Breadcrumbs_breadcrumbs__3crNl {
      padding: 10px 15px; } }
  .Breadcrumbs_breadcrumbs__list__2HI1Q {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; }
    .Breadcrumbs_breadcrumbs__list-item__3ApKk {
      font-size: 0.875rem;
      line-height: 1rem;
      margin-left: 10px; }
      .Breadcrumbs_breadcrumbs__list-item__3ApKk:before {
        content: '';
        height: 10px;
        border-left: 1px solid #B2B2B2;
        margin-right: 10px;
        display: inline-flex; }
      .Breadcrumbs_breadcrumbs__list-item__3ApKk:first-of-type {
        margin-left: 0; }
        .Breadcrumbs_breadcrumbs__list-item__3ApKk:first-of-type:before {
          content: none; }
      .Breadcrumbs_breadcrumbs__list-item--home__305xx {
        color: #22737B; }
        .Breadcrumbs_breadcrumbs__list-item--home__305xx span {
          display: none; }
        .Breadcrumbs_breadcrumbs__list-item--home__305xx:hover {
          color: #22737B; }
        .Breadcrumbs_breadcrumbs__list-item--home__305xx:active {
          color: #4D4D4D; }
        .Breadcrumbs_breadcrumbs__list-item--home__305xx:focus {
          outline: 1px solid #236B83; }
      .Breadcrumbs_breadcrumbs__list-item--link__1EIC0 {
        color: #22737B; }
        .Breadcrumbs_breadcrumbs__list-item--link__1EIC0:hover {
          color: #22737B; }
        .Breadcrumbs_breadcrumbs__list-item--link__1EIC0:active {
          color: #4D4D4D; }
        .Breadcrumbs_breadcrumbs__list-item--link__1EIC0:focus {
          outline: 1px solid #236B83; }
      .Breadcrumbs_breadcrumbs__list-item--label__3WJUW {
        color: #4B4B4B; }

.contrast .Breadcrumbs_breadcrumbs__list-item__3ApKk {
  border-color: #5A5959; }
  .contrast .Breadcrumbs_breadcrumbs__list-item--home__305xx {
    color: #FFF500; }
    .contrast .Breadcrumbs_breadcrumbs__list-item--home__305xx:focus {
      outline: 1px solid #fff; }
  .contrast .Breadcrumbs_breadcrumbs__list-item--label__3WJUW {
    color: #FFF; }
    .contrast .Breadcrumbs_breadcrumbs__list-item--label__3WJUW:focus {
      outline: 1px solid #fff; }

.GetToKnowLeopoldina_app__3pXFH {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1 1; }
  .GetToKnowLeopoldina_app__title__3djA6 {
    font-size: 1.25rem;
    color: #4B4B4B;
    margin: 5px 0 0; }
    @media screen and (max-width: 575.98px) {
      .GetToKnowLeopoldina_app__title__3djA6 {
        padding: 0 15px; } }
  .GetToKnowLeopoldina_app__line__3_Cxu {
    border-top: 1px solid #EAEAEA;
    margin-top: 25px; }
  @media screen and (max-width: 1199.98px) {
    .GetToKnowLeopoldina_app__container__3brtm {
      flex-direction: column; } }
  .GetToKnowLeopoldina_app__content__11rxj {
    display: flex;
    flex: 1 1; }
    @media screen and (max-width: 1199.98px) {
      .GetToKnowLeopoldina_app__content__11rxj {
        flex-direction: column; } }
  .GetToKnowLeopoldina_app__menu__2ynmE {
    background-color: #FFF;
    border-right: 1px solid #EAEAEA;
    flex: 0 0 395px;
    padding: 30px 15px 30px 0; }
    @media screen and (max-width: 1199.98px) {
      .GetToKnowLeopoldina_app__menu__2ynmE {
        border-right: 0;
        flex: 1 1; } }
    @media screen and (max-width: 575.98px) {
      .GetToKnowLeopoldina_app__menu__2ynmE {
        padding: 30px 15px; } }
    .GetToKnowLeopoldina_app__menu-list__1dVom {
      list-style: none;
      margin: 0;
      padding: 0; }
      @media screen and (max-width: 1199.98px) {
        .GetToKnowLeopoldina_app__menu-list__1dVom {
          margin-left: 10px; } }
      .GetToKnowLeopoldina_app__menu-list--top__dCa0b {
        display: flex;
        justify-content: space-between;
        padding-bottom: 25px; }
        @media screen and (max-width: 1199.98px) {
          .GetToKnowLeopoldina_app__menu-list--top__dCa0b {
            padding: 10px;
            background-color: #ECEEEF;
            align-items: center; } }
      .GetToKnowLeopoldina_app__menu-list--title__28z7a {
        font-size: 1rem;
        font-weight: 700;
        margin: 0; }
      .GetToKnowLeopoldina_app__menu-list--btn__26F7b {
        padding: 0;
        line-height: normal;
        line-height: initial;
        display: inline-block; }
        @media screen and (min-width: 992px) {
          .GetToKnowLeopoldina_app__menu-list--btn__26F7b {
            display: none; } }
        .GetToKnowLeopoldina_app__menu-list--btn__26F7b i {
          font-size: 1.2rem;
          line-height: 1rem; }
          .GetToKnowLeopoldina_app__menu-list--btn__26F7b i:before {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg); }
        .GetToKnowLeopoldina_app__menu-list--btn__26F7b:focus, .GetToKnowLeopoldina_app__menu-list--btn__26F7b:hover {
          text-decoration: none;
          color: #22737B; }
      .GetToKnowLeopoldina_app__menu-list--link__3JyRo {
        color: #22737B;
        font-size: 0.9375rem;
        line-height: 2rem;
        width: 100%;
        display: flex; }
        .GetToKnowLeopoldina_app__menu-list--link__3JyRo i {
          font-size: 1.2rem;
          display: none; }
        .GetToKnowLeopoldina_app__menu-list--link__3JyRo:hover {
          color: #22737B; }
        .GetToKnowLeopoldina_app__menu-list--link__3JyRo:active {
          color: #4D4D4D; }
        .GetToKnowLeopoldina_app__menu-list--link__3JyRo[aria-current="page"] {
          color: #4B4B4B;
          font-weight: 700;
          font-size: 0.875rem;
          pointer-events: none; }
          .GetToKnowLeopoldina_app__menu-list--link__3JyRo[aria-current="page"] i {
            display: inline-block; }
  .GetToKnowLeopoldina_app__article__2PeMw {
    flex: 1 1;
    display: flex;
    flex-direction: column; }
    .GetToKnowLeopoldina_app__article-title__4hinz {
      font-size: 1rem;
      padding: 20px 0 20px 70px;
      font-weight: 700;
      margin: 0;
      position: relative;
      background: #EAEAEA; }
      .GetToKnowLeopoldina_app__article-title__4hinz::after {
        content: '';
        display: block;
        background: #EAEAEA;
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        z-index: -2;
        margin-right: -100%;
        top: 0; }
        @media screen and (max-width: 1199.98px) {
          .GetToKnowLeopoldina_app__article-title__4hinz::after {
            width: 200%;
            margin-right: -50%; } }
      @media screen and (max-width: 1199.98px) {
        .GetToKnowLeopoldina_app__article-title__4hinz {
          padding-left: 0; } }
      @media screen and (max-width: 575.98px) {
        .GetToKnowLeopoldina_app__article-title__4hinz {
          padding: 25px 15px; } }
    .GetToKnowLeopoldina_app__article-content__35ovm {
      padding: 0 0 40px;
      margin-top: 2px;
      color: #4B4B4B;
      flex: 1 1;
      position: relative;
      background: #F5F5F5; }
      .GetToKnowLeopoldina_app__article-content__35ovm::after {
        content: '';
        display: block;
        background: #F5F5F5;
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        z-index: -2;
        margin-right: -100%;
        top: 0; }
        @media screen and (max-width: 1199.98px) {
          .GetToKnowLeopoldina_app__article-content__35ovm::after {
            width: 200%;
            margin-right: -50%; } }
      .GetToKnowLeopoldina_app__article-content__35ovm p, .GetToKnowLeopoldina_app__article-content__35ovm h1, .GetToKnowLeopoldina_app__article-content__35ovm h2, .GetToKnowLeopoldina_app__article-content__35ovm h3, .GetToKnowLeopoldina_app__article-content__35ovm h4, .GetToKnowLeopoldina_app__article-content__35ovm h5, .GetToKnowLeopoldina_app__article-content__35ovm h6, .GetToKnowLeopoldina_app__article-content__35ovm pre {
        margin: 0;
        position: relative;
        padding: 40px 0 0 70px; }
        @media screen and (max-width: 1199.98px) {
          .GetToKnowLeopoldina_app__article-content__35ovm p, .GetToKnowLeopoldina_app__article-content__35ovm h1, .GetToKnowLeopoldina_app__article-content__35ovm h2, .GetToKnowLeopoldina_app__article-content__35ovm h3, .GetToKnowLeopoldina_app__article-content__35ovm h4, .GetToKnowLeopoldina_app__article-content__35ovm h5, .GetToKnowLeopoldina_app__article-content__35ovm h6, .GetToKnowLeopoldina_app__article-content__35ovm pre {
            padding-left: 0; } }
        @media screen and (max-width: 575.98px) {
          .GetToKnowLeopoldina_app__article-content__35ovm p, .GetToKnowLeopoldina_app__article-content__35ovm h1, .GetToKnowLeopoldina_app__article-content__35ovm h2, .GetToKnowLeopoldina_app__article-content__35ovm h3, .GetToKnowLeopoldina_app__article-content__35ovm h4, .GetToKnowLeopoldina_app__article-content__35ovm h5, .GetToKnowLeopoldina_app__article-content__35ovm h6, .GetToKnowLeopoldina_app__article-content__35ovm pre {
            padding: 40px 15px 0; } }
      .GetToKnowLeopoldina_app__article-content__35ovm h1, .GetToKnowLeopoldina_app__article-content__35ovm h2, .GetToKnowLeopoldina_app__article-content__35ovm h3, .GetToKnowLeopoldina_app__article-content__35ovm h4, .GetToKnowLeopoldina_app__article-content__35ovm h5, .GetToKnowLeopoldina_app__article-content__35ovm h6 {
        font-size: 1rem;
        font-weight: 700; }
      .GetToKnowLeopoldina_app__article-content__35ovm p {
        font-size: 0.8125rem;
        line-height: 1.625rem; }
      .GetToKnowLeopoldina_app__article-content__35ovm pre {
        font-size: 0.9375rem;
        text-transform: uppercase;
        font-family: inherit;
        font-weight: 700;
        letter-spacing: 0.5px; }
      .GetToKnowLeopoldina_app__article-content__35ovm blockquote {
        background-color: #EAEAEA;
        margin: 20px 0 0;
        position: relative;
        background: #EAEAEA; }
        .GetToKnowLeopoldina_app__article-content__35ovm blockquote::after {
          content: '';
          display: block;
          background: #EAEAEA;
          position: absolute;
          width: 100%;
          height: 100%;
          right: 0;
          z-index: -2;
          margin-right: -100%;
          top: 0; }
          @media screen and (max-width: 1199.98px) {
            .GetToKnowLeopoldina_app__article-content__35ovm blockquote::after {
              width: 200%;
              margin-right: -50%; } }
        .GetToKnowLeopoldina_app__article-content__35ovm blockquote::after {
          z-index: -1; }
        .GetToKnowLeopoldina_app__article-content__35ovm blockquote p {
          padding-top: 30px;
          padding-bottom: 20px; }
      .GetToKnowLeopoldina_app__article-content__35ovm a {
        color: #004FA3; }
  .GetToKnowLeopoldina_app__table__1y0Dp {
    position: relative;
    background: #F5F5F5; }
    .GetToKnowLeopoldina_app__table__1y0Dp::after {
      content: '';
      display: block;
      background: #F5F5F5;
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      z-index: -2;
      margin-right: -100%;
      top: 0; }
      @media screen and (max-width: 1199.98px) {
        .GetToKnowLeopoldina_app__table__1y0Dp::after {
          width: 200%;
          margin-right: -50%; } }
    .GetToKnowLeopoldina_app__table-title__3UyHU {
      font-size: 1.125rem;
      font-weight: 700;
      padding: 25px 0 25px 70px;
      margin: 0;
      background-color: #F5F5F5;
      color: #4B4B4B; }
      @media screen and (max-width: 1199.98px) {
        .GetToKnowLeopoldina_app__table-title__3UyHU {
          padding-left: 0; } }
      @media screen and (max-width: 575.98px) {
        .GetToKnowLeopoldina_app__table-title__3UyHU {
          padding: 25px 15px; } }
    .GetToKnowLeopoldina_app__table-header__zi-Cn {
      padding: 15px 0 15px 70px;
      display: flex;
      position: relative;
      background: #EAEAEA; }
      @media screen and (max-width: 1199.98px) {
        .GetToKnowLeopoldina_app__table-header__zi-Cn {
          display: none; } }
      .GetToKnowLeopoldina_app__table-header__zi-Cn::after {
        content: '';
        display: block;
        background: #EAEAEA;
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        z-index: -2;
        margin-right: -100%;
        top: 0; }
        @media screen and (max-width: 1199.98px) {
          .GetToKnowLeopoldina_app__table-header__zi-Cn::after {
            width: 200%;
            margin-right: -50%; } }
      .GetToKnowLeopoldina_app__table-header__zi-Cn::after {
        z-index: -1; }
      .GetToKnowLeopoldina_app__table-header--column__3ckVB {
        flex: 1 1;
        padding-right: 15px;
        font-size: 0.875rem; }
    .GetToKnowLeopoldina_app__table-content__3rzjX {
      background-color: #F5F5F5;
      list-style: none;
      padding: 15px 0 15px 70px;
      margin: 0;
      position: relative;
      background: #F5F5F5; }
      @media screen and (max-width: 1199.98px) {
        .GetToKnowLeopoldina_app__table-content__3rzjX {
          padding-left: 0; } }
      @media screen and (max-width: 575.98px) {
        .GetToKnowLeopoldina_app__table-content__3rzjX {
          padding: 15px; } }
      .GetToKnowLeopoldina_app__table-content__3rzjX::after {
        content: '';
        display: block;
        background: #F5F5F5;
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        z-index: -2;
        margin-right: -100%;
        top: 0; }
        @media screen and (max-width: 1199.98px) {
          .GetToKnowLeopoldina_app__table-content__3rzjX::after {
            width: 200%;
            margin-right: -50%; } }
      .GetToKnowLeopoldina_app__table-content--portal__CEaPe {
        text-align: left; }
      .GetToKnowLeopoldina_app__table-content--item__2xpuc {
        display: flex;
        margin-bottom: 20px;
        z-index: 0; }
        @media screen and (max-width: 1199.98px) {
          .GetToKnowLeopoldina_app__table-content--item__2xpuc {
            flex-direction: column;
            margin-bottom: 0;
            padding: 20px 0 25px;
            position: relative; }
            .GetToKnowLeopoldina_app__table-content--item__2xpuc:nth-child(odd) {
              position: relative;
              background: #EAEAEA; }
              .GetToKnowLeopoldina_app__table-content--item__2xpuc:nth-child(odd)::after {
                content: '';
                display: block;
                background: #EAEAEA;
                position: absolute;
                width: 100%;
                height: 100%;
                right: 0;
                z-index: -2;
                margin-right: -100%;
                top: 0; } }
      @media screen and (max-width: 1199.98px) and (max-width: 1199.98px) {
        .GetToKnowLeopoldina_app__table-content--item__2xpuc:nth-child(odd)::after {
          width: 200%;
          margin-right: -50%; } }
        @media screen and (max-width: 1199.98px) {
              .GetToKnowLeopoldina_app__table-content--item__2xpuc:nth-child(odd)::after {
                z-index: -1; } }
      .GetToKnowLeopoldina_app__table-content--column__2Yebl {
        flex: 1 1;
        padding-right: 15px;
        font-size: 0.875rem; }
        @media screen and (max-width: 1199.98px) {
          .GetToKnowLeopoldina_app__table-content--column__2Yebl {
            margin-bottom: 20px; } }
        .GetToKnowLeopoldina_app__table-content--column__2Yebl:last-child {
          margin-bottom: 0; }
        .GetToKnowLeopoldina_app__table-content--column-text__2e7WA {
          display: none;
          font-size: 0.8125rem;
          font-weight: 700; }
          @media screen and (max-width: 1199.98px) {
            .GetToKnowLeopoldina_app__table-content--column-text__2e7WA {
              display: flex;
              margin-bottom: 5px; } }
      .GetToKnowLeopoldina_app__table-content--link__3Jp5o {
        padding: 5px 13px;
        background-color: #236B83;
        color: #FFF;
        border-radius: 3px;
        font-size: 0.875rem;
        display: inline-flex; }
        .GetToKnowLeopoldina_app__table-content--link__3Jp5o:hover, .GetToKnowLeopoldina_app__table-content--link__3Jp5o:focus {
          color: #FFF;
          background-color: #185367;
          text-decoration: none; }
        .GetToKnowLeopoldina_app__table-content--link__3Jp5o:active {
          background-color: #0E2E39; }
      .GetToKnowLeopoldina_app__table-content--portal__CEaPe {
        font-size: 0.875rem; }

@media (max-width: 991px) {
  .GetToKnowLeopoldina_collapseMenu__22QzK {
    display: none; } }

.GetToKnowLeopoldina_collapseMenu-active__3rRtA i:before {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.contrast .GetToKnowLeopoldina_app__title__3djA6 {
  color: #FFF; }

.contrast .GetToKnowLeopoldina_app__menu__2ynmE {
  background-color: #0D0C0C;
  border-color: #333333; }
  .contrast .GetToKnowLeopoldina_app__menu-list--title__28z7a {
    color: #FFF; }
  .contrast .GetToKnowLeopoldina_app__menu-list--link__3JyRo {
    color: #FFF500; }
  @media screen and (max-width: 1199.98px) {
    .contrast .GetToKnowLeopoldina_app__menu-list--top__dCa0b {
      background-color: #0D0C0C;
      border: 1px solid #FFF500; } }

.contrast .GetToKnowLeopoldina_app__article__2PeMw {
  background-color: #333333; }
  .contrast .GetToKnowLeopoldina_app__article-title__4hinz {
    background-color: #0D0C0C;
    border-top: 1px solid #333333;
    color: #FFF; }
    .contrast .GetToKnowLeopoldina_app__article-title__4hinz::after {
      background-color: #0D0C0C;
      border-top: 1px solid #333333; }
  .contrast .GetToKnowLeopoldina_app__article-content__35ovm {
    background-color: #0D0C0C;
    color: #FFF; }
    .contrast .GetToKnowLeopoldina_app__article-content__35ovm::after {
      background-color: #0D0C0C; }
    .contrast .GetToKnowLeopoldina_app__article-content__35ovm a {
      color: #FFF500; }
    .contrast .GetToKnowLeopoldina_app__article-content__35ovm blockquote {
      background-color: #0D0C0C; }
      .contrast .GetToKnowLeopoldina_app__article-content__35ovm blockquote::after {
        background-color: #0D0C0C; }
    .contrast .GetToKnowLeopoldina_app__article-content__35ovm h1, .contrast .GetToKnowLeopoldina_app__article-content__35ovm h2, .contrast .GetToKnowLeopoldina_app__article-content__35ovm h3, .contrast .GetToKnowLeopoldina_app__article-content__35ovm h4, .contrast .GetToKnowLeopoldina_app__article-content__35ovm h5, .contrast .GetToKnowLeopoldina_app__article-content__35ovm h6, .contrast .GetToKnowLeopoldina_app__article-content__35ovm pre {
      color: #FFF; }

.contrast .GetToKnowLeopoldina_app__table__1y0Dp::after {
  background-color: #0D0C0C; }

.contrast .GetToKnowLeopoldina_app__table-title__3UyHU {
  background: #0D0C0C;
  color: #FFF; }

.contrast .GetToKnowLeopoldina_app__table-header__zi-Cn {
  background: #0D0C0C;
  color: #FFF;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333; }
  .contrast .GetToKnowLeopoldina_app__table-header__zi-Cn::after {
    background: #0D0C0C;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333; }

.contrast .GetToKnowLeopoldina_app__table-content__3rzjX {
  background: #0D0C0C;
  color: #FFF; }
  .contrast .GetToKnowLeopoldina_app__table-content__3rzjX a {
    color: #FFF500; }
  .contrast .GetToKnowLeopoldina_app__table-content--link__3Jp5o {
    background-color: #0D0C0C;
    border: 1px solid #FFF500; }
    .contrast .GetToKnowLeopoldina_app__table-content--link__3Jp5o:hover, .contrast .GetToKnowLeopoldina_app__table-content--link__3Jp5o:focus {
      color: #0D0C0C;
      background-color: #FFF500; }
  .contrast .GetToKnowLeopoldina_app__table-content--item__2xpuc:nth-child(odd)::after {
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
    background: #0D0C0C; }

.IndexesMenu_menu__1FFJe {
  background-color: #FFF;
  flex: 0 0 395px;
  padding: 30px 0px; }
  @media screen and (max-width: 991.98px) {
    .IndexesMenu_menu__1FFJe {
      flex: 0 1 auto; } }
  .IndexesMenu_menu__container__2VKVE {
    margin: 0 0 15px; }
  .IndexesMenu_menu__list__2LH5s {
    list-style: none;
    margin: 0;
    padding: 0; }
    @media screen and (max-width: 575.98px) {
      .IndexesMenu_menu__list__2LH5s {
        margin-left: 10px; } }
    .IndexesMenu_menu__list--top__1xlZg {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px; }
      @media screen and (max-width: 575.98px) {
        .IndexesMenu_menu__list--top__1xlZg {
          padding: 4px 15px; } }
    .IndexesMenu_menu__list--title__37mx8 {
      font-size: 0.875rem;
      font-weight: 700;
      margin: 0;
      white-space: nowrap; }
    .IndexesMenu_menu__list--text__3J4Qc {
      margin: auto;
      margin-left: 5px;
      font-weight: 500;
      font-size: 0.875rem;
      white-space: nowrap; }
      @media screen and (max-width: 991.98px) {
        .IndexesMenu_menu__list--text__3J4Qc {
          white-space: normal; } }

.contrast .IndexesMenu_menu__1FFJe {
  background-color: #0D0C0C;
  color: #FFF;
  border-top-color: #333; }
  @media screen and (min-width: 992px) {
    .contrast .IndexesMenu_menu__1FFJe {
      border-right: 1px solid #333; } }

.dropdown {
  position: relative; }
  .dropdown .btn-regular {
    padding: 5px 0 5px 0;
    border-bottom: solid 1px #A5A5A5;
    border-radius: 0;
    font-size: 0.875rem;
    color: #515A5D; }
    .dropdown .btn-regular:focus {
      outline: 0;
      box-shadow: none; }
    .dropdown .btn-regular:after {
      color: #22737B;
      margin-left: 40px; }
    .dropdown .btn-regular + .dropdown-menu {
      max-height: 270px;
      overflow: auto;
      font-size: 0.875rem;
      min-width: 150px; }
      .dropdown .btn-regular + .dropdown-menu .dropdown-item {
        color: #373A3C; }
        .dropdown .btn-regular + .dropdown-menu .dropdown-item:hover, .dropdown .btn-regular + .dropdown-menu .dropdown-item:focus {
          background: #F5F5F5; }
  .dropdown.show .btn-regular {
    background: #236B83;
    color: #fff;
    border: solid 1px #236B83;
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px; }
    .dropdown.show .btn-regular:after {
      color: #fff;
      margin-left: 40px; }

.IndexesPrefix_prefix__1uKpD {
  margin: 20px 0; }
  @media screen and (max-width: 575.98px) {
    .IndexesPrefix_prefix__1uKpD {
      margin-left: 15px;
      margin-right: 15px; } }

.IndexesList_results__1bR2r {
  background-color: #F5F5F5;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 60px 15px 60px 40px;
  position: relative; }
  @media screen and (max-width: 991.98px) {
    .IndexesList_results__1bR2r {
      padding: 40px 0; } }
  .IndexesList_results__1bR2r::after {
    content: '';
    display: block;
    background: #F5F5F5;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
    margin-right: -100%; }
    @media screen and (max-width: 991.98px) {
      .IndexesList_results__1bR2r::after {
        width: 200%;
        margin-right: -50%; } }
  @media screen and (max-width: 575.98px) {
    .IndexesList_results__1bR2r {
      padding: 40px 15px 0; } }
  .IndexesList_results__nav__293w9 {
    background: #EAEAEA;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0; }
    @media screen and (max-width: 767.98px) {
      .IndexesList_results__nav__293w9 {
        justify-content: flex-end; } }
    @media screen and (min-width: 992px) {
      .IndexesList_results__nav__293w9 {
        padding-left: 30px; } }
    @media screen and (min-width: 1415px) {
      .IndexesList_results__nav__293w9 {
        padding-left: 70px; } }
    @media screen and (min-width: 1800px) {
      .IndexesList_results__nav__293w9 {
        padding-left: 95px; } }
    .IndexesList_results__nav__293w9::after {
      content: '';
      display: block;
      background: #EAEAEA;
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      z-index: -1;
      margin-right: -100%;
      top: 0; }
      @media screen and (max-width: 991.98px) {
        .IndexesList_results__nav__293w9::after {
          width: 200%;
          margin-right: -50%; } }
    .IndexesList_results__nav--bottom__183y3 {
      justify-content: flex-end; }
  .IndexesList_results__list__2vDIc {
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    -webkit-column-rule: 1px solid #E8E8E8;
    -moz-column-rule: 1px solid #E8E8E8;
    column-rule: 1px solid #E8E8E8; }
    @media screen and (max-width: 991.98px) {
      .IndexesList_results__list__2vDIc {
        -webkit-column-count: 1;
           -moz-column-count: 1;
                column-count: 1; } }
    .IndexesList_results__list-item__1gG8Y {
      -webkit-column-break-inside: avoid;
         -moz-column-break-inside: avoid;
              break-inside: avoid-column; }
      .IndexesList_results__list-item--link__38H2m {
        color: #22737B;
        font-size: 0.875rem;
        line-height: 1.3rem;
        width: 100%;
        display: flex;
        padding-bottom: 8px;
        padding-left: 25px; }
        .IndexesList_results__list-item--link__38H2m:hover {
          color: #22737B; }
        .IndexesList_results__list-item--link__38H2m:active {
          color: #4D4D4D; }
        @media screen and (max-width: 991.98px) {
          .IndexesList_results__list-item--link__38H2m {
            padding-left: 0px; } }
        .IndexesList_results__list-item--link__38H2m i {
          font-size: 1.2rem;
          display: none; }
          .IndexesList_results__list-item--link__38H2m i[aria-current="page"] {
            color: #4B4B4B;
            font-weight: 700;
            font-size: 0.875rem;
            pointer-events: none; }
            .IndexesList_results__list-item--link__38H2m i[aria-current="page"] i {
              display: inline-block; }
  .IndexesList_results__content__1N0Cl {
    padding: 0 0 40px;
    color: #4B4B4B;
    flex: 1 1; }
    @media screen and (max-width: 991.98px) {
      .IndexesList_results__content__1N0Cl .IndexesList_collapseMenu__2FRBF {
        display: none; } }
    .IndexesList_results__content__1N0Cl .IndexesList_collapseMenu-active__14DPM i:before {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }

.contrast .IndexesList_results__1bR2r {
  background-color: #0D0C0C; }
  .contrast .IndexesList_results__1bR2r::after {
    background: #0D0C0C; }
  .contrast .IndexesList_results__list__2vDIc {
    -webkit-column-rule: 1px solid #333;
       -moz-column-rule: 1px solid #333;
            column-rule: 1px solid #333; }
    .contrast .IndexesList_results__list-item--link__38H2m {
      color: #FFF500; }
  .contrast .IndexesList_results__nav__293w9 {
    background-color: #0D0C0C; }
    .contrast .IndexesList_results__nav__293w9::after {
      background: #0D0C0C; }

.Pagination_pagination__pages__1HRu- {
  display: inline-block; }

.Pagination_pagination__button__2AWfQ {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.Pagination_pagination__form__D1AAz {
  display: inline-block; }

.Pagination_pagination__input__1qz-M {
  background: transparent;
  font-size: 0.8rem;
  border: none;
  border-bottom: solid 1px #A5A5A5;
  width: 50px;
  text-align: right;
  padding: 0 10px;
  line-height: 1rem;
  color: #22737B; }

.Pagination_pagination__input-label__1koAQ {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.Pagination_pagination__total__ZSeic {
  display: inline-block; }

.Pagination_pagination__total-label__38XJh {
  color: #4B4B4B;
  font-size: 0.8rem;
  margin: 0 3px 0 8px; }

.Pagination_pagination__list__2Mu-D {
  margin: 0;
  padding: 0;
  display: inline-block; }

.Pagination_pagination__list-item__2m8Qi {
  display: inline-block; }

.Pagination_pagination__item-button__10e4t {
  color: #22737B;
  font-size: 0.8rem;
  border: solid 1px transparent;
  background: transparent;
  line-height: calc(1rem + 1px);
  padding: 4px 5px; }
  .Pagination_pagination__item-button__10e4t:hover, .Pagination_pagination__item-button__10e4t:focus {
    text-decoration: underline; }
  .Pagination_pagination__item-button--disabled__3OeOW {
    color: #4B4B4B;
    pointer-events: none; }
    .Pagination_pagination__item-button--disabled__3OeOW:hover, .Pagination_pagination__item-button--disabled__3OeOW:focus {
      text-decoration: none; }

.Pagination_pagination__item-button--active__28F5u {
  color: #4B4B4B;
  pointer-events: none; }
  .Pagination_pagination__item-button--active__28F5u:hover, .Pagination_pagination__item-button--active__28F5u:focus {
    text-decoration: none; }

.Pagination_pagination__item-separator__1j6mc {
  color: #4B4B4B;
  font-size: 0.8rem;
  margin: 0 5px; }

.Pagination_pagination__next__Rg8g-, .Pagination_pagination__previous__1AgkI {
  color: #22737B;
  font-size: 0.8rem;
  border: solid 1px transparent;
  background: transparent;
  line-height: calc(1rem + 1px);
  padding: 4px 5px;
  position: relative;
  display: inline-block; }
  .Pagination_pagination__next__Rg8g-:hover, .Pagination_pagination__next__Rg8g-:focus, .Pagination_pagination__previous__1AgkI:hover, .Pagination_pagination__previous__1AgkI:focus {
    text-decoration: underline; }
  @media screen and (max-width: 575.98px) {
    .Pagination_pagination__next__Rg8g- span, .Pagination_pagination__previous__1AgkI span {
      display: none; } }

.Pagination_pagination__next__Rg8g- {
  margin-left: 10px;
  padding-right: 12px; }
  @media screen and (max-width: 575.98px) {
    .Pagination_pagination__next__Rg8g- {
      margin: 0;
      padding: 10px; } }
  .Pagination_pagination__next__Rg8g-:after {
    content: '';
    position: absolute;
    right: 0;
    width: 0;
    top: 8px;
    height: 0; }
    @media screen and (max-width: 575.98px) {
      .Pagination_pagination__next__Rg8g-:after {
        top: 2px;
        left: 7px;
        right: auto; } }
  .Pagination_pagination__next__Rg8g-:after {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #22737B; }

.Pagination_pagination__previous__1AgkI {
  margin-right: 10px;
  padding-left: 12px; }
  @media screen and (max-width: 575.98px) {
    .Pagination_pagination__previous__1AgkI {
      margin: 0;
      padding: 10px; } }
  .Pagination_pagination__previous__1AgkI:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 0;
    height: 0; }
    @media screen and (max-width: 575.98px) {
      .Pagination_pagination__previous__1AgkI:before {
        top: 2px;
        right: 7px;
        left: auto; } }
  .Pagination_pagination__previous__1AgkI:before {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #22737B; }

.contrast .Pagination_pagination__item-button__10e4t {
  color: #FFF500; }

.contrast .Pagination_pagination__item-separator__1j6mc {
  color: #FFF; }

.contrast .Pagination_pagination__total-label__38XJh {
  color: #FFF; }

.contrast .Pagination_pagination__next__Rg8g-, .contrast .Pagination_pagination__previous__1AgkI {
  color: #FFF500; }
  .contrast .Pagination_pagination__next__Rg8g-::after, .contrast .Pagination_pagination__previous__1AgkI::after {
    border-left-color: #FFF500; }
  .contrast .Pagination_pagination__next__Rg8g-::before, .contrast .Pagination_pagination__previous__1AgkI::before {
    border-right-color: #FFF500; }

.contrast .Pagination_pagination__input__1qz-M {
  color: #FFF500; }
  .contrast .Pagination_pagination__input-label__1koAQ {
    color: #FFF500; }

@media screen and (min-width: 992px) {
  .font-big .Pagination_pagination__next__Rg8g- {
    margin-left: 5px;
    padding-right: 10px; } }

@media screen and (min-width: 992px) {
  .font-big .Pagination_pagination__previous__1AgkI {
    margin-right: 5px;
    padding-left: 10px; } }

.PageSize_page-size__3F6zd {
  display: flex;
  align-items: center;
  white-space: nowrap; }
  .PageSize_page-size__list__3yz_y {
    list-style: none;
    margin: 0;
    padding: 0 7px; }
  .PageSize_page-size__item__3Qtld {
    display: inline-block; }
  .PageSize_page-size__text__3A0Gz {
    margin: 0;
    color: #4B4B4B;
    font-size: 0.8rem;
    line-height: calc(1rem + 2px); }
  .PageSize_page-size__button__16Q_w {
    color: #22737B;
    font-size: 0.8rem;
    border: solid 1px transparent;
    background: transparent;
    line-height: 1rem;
    padding: 4px 5px; }
    .PageSize_page-size__button__16Q_w:hover, .PageSize_page-size__button__16Q_w:focus {
      text-decoration: underline; }
  .PageSize_page-size__button--active__3WPU- {
    color: #4B4B4B;
    pointer-events: none; }

.contrast .PageSize_page-size__text__3A0Gz {
  color: #FFF; }

.contrast .PageSize_page-size__button__16Q_w {
  color: #FFF500; }
  .contrast .PageSize_page-size__button--active__3WPU- {
    color: #FFF; }

.Indexes_page__1f-Gz {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1 1; }
  .Indexes_page__no-results__ikCme {
    padding: 25px; }
  .Indexes_page__main__2bL4X {
    flex: 1 1; }
  .Indexes_page__title__3gXmo {
    font-size: 1.25rem;
    color: #4B4B4B;
    margin: 5px 0 0; }
    @media screen and (max-width: 575.98px) {
      .Indexes_page__title__3gXmo {
        padding: 0 15px; } }
  .Indexes_page__line__1YDDA {
    margin: 25px 0 0;
    border-bottom: 1px solid #EAEAEA; }
  .Indexes_page__content__1AqRZ {
    display: flex;
    padding-bottom: 8px;
    flex: 1 1;
    min-height: 600px; }
    @media screen and (max-width: 991.98px) {
      .Indexes_page__content__1AqRZ {
        flex-direction: column; } }
    .Indexes_page__content__1AqRZ i {
      font-size: 1.2rem; }
    .Indexes_page__content__1AqRZ[aria-current="page"] {
      color: #4B4B4B;
      font-weight: 700;
      font-size: 0.875rem;
      pointer-events: none; }
      .Indexes_page__content__1AqRZ[aria-current="page"] i {
        display: inline-block; }
  .Indexes_page-content__1PKpQ {
    padding: 0 0 40px;
    color: #4B4B4B;
    flex: 1 1; }

@media screen and (max-width: 991.98px) {
  .Indexes_collapseMenu__3PoQB {
    display: none; } }

.Indexes_collapseMenu-active__3eMvM i:before {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.contrast .Indexes_page__title__3gXmo {
  color: #FFF; }

.contrast .Indexes_page__no-results__ikCme {
  color: #FFF; }

.content {
  flex: 1 1; }
  .content.main {
    background-color: #f5f5f5; }
  .content.light {
    background-color: #fff; }

.AdvancedSearch_advanced-search__dF7yq {
  height: 100%;
  text-align: left;
  width: 100%;
  margin-bottom: 100px; }
  .AdvancedSearch_advanced-search__form-control__30CyK, .AdvancedSearch_advanced-search__field-date--form-control__4GU7E {
    background-color: transparent;
    border-radius: 0;
    border-color: transparent;
    border-bottom: solid 1px #A5A5A5;
    color: #4B4B4B;
    font-size: 0.85rem;
    padding: 3px 0;
    line-height: 1;
    height: calc(1em + 0.65rem + 2px); }
    .AdvancedSearch_advanced-search__form-control__30CyK:hover, .AdvancedSearch_advanced-search__field-date--form-control__4GU7E:hover {
      border-bottom: solid 1px #22737B; }
    .AdvancedSearch_advanced-search__form-control__30CyK:active, .AdvancedSearch_advanced-search__field-date--form-control__4GU7E:active {
      border-bottom: solid 1px #4B4B4B; }
    .AdvancedSearch_advanced-search__form-control__30CyK:focus, .AdvancedSearch_advanced-search__field-date--form-control__4GU7E:focus {
      border-color: transparent;
      border-bottom-color: #707070; }
    .AdvancedSearch_advanced-search__form-control__30CyK::-webkit-input-placeholder, .AdvancedSearch_advanced-search__field-date--form-control__4GU7E::-webkit-input-placeholder {
      color: #818A91;
      font-style: italic; }
    .AdvancedSearch_advanced-search__form-control__30CyK::-moz-placeholder, .AdvancedSearch_advanced-search__field-date--form-control__4GU7E::-moz-placeholder {
      color: #818A91;
      font-style: italic; }
    .AdvancedSearch_advanced-search__form-control__30CyK:-moz-placeholder, .AdvancedSearch_advanced-search__field-date--form-control__4GU7E:-moz-placeholder {
      color: #818A91;
      font-style: italic; }
    .AdvancedSearch_advanced-search__form-control__30CyK:-ms-input-placeholder, .AdvancedSearch_advanced-search__field-date--form-control__4GU7E:-ms-input-placeholder {
      color: #818A91;
      font-style: italic; }
  .AdvancedSearch_advanced-search__breadcrumbs__3ThVH {
    padding: 0 10px; }
    @media screen and (min-width: 576px) {
      .AdvancedSearch_advanced-search__breadcrumbs__3ThVH {
        padding: 0; } }
  .AdvancedSearch_advanced-search__header__3PTNJ {
    color: #4B4B4B;
    font-size: 1.6rem;
    margin-top: 10px;
    padding: 0 30px; }
    @media screen and (min-width: 576px) {
      .AdvancedSearch_advanced-search__header__3PTNJ {
        padding: 0; } }
  .AdvancedSearch_advanced-search__text__1C10F {
    margin-top: 60px;
    padding: 0 30px; }
    @media screen and (min-width: 576px) {
      .AdvancedSearch_advanced-search__text__1C10F {
        padding: 0; } }
  .AdvancedSearch_advanced-search__title__2Y090 {
    font-size: 1rem;
    color: #4B4B4B;
    font-weight: 700; }
  .AdvancedSearch_advanced-search__content__z-yxM, .AdvancedSearch_advanced-search__link__1a_rP {
    font-size: 0.875rem;
    line-height: 1.6rem;
    color: #4B4B4B;
    margin: 20px 0 30px; }
  .AdvancedSearch_advanced-search__link__1a_rP {
    display: inline;
    color: #22737B;
    margin-left: 3px; }
    .AdvancedSearch_advanced-search__link__1a_rP:hover, .AdvancedSearch_advanced-search__link__1a_rP:focus, .AdvancedSearch_advanced-search__link__1a_rP:active {
      color: #22737B; }
  .AdvancedSearch_advanced-search__form__10ydD {
    background-color: #F5F5F5;
    padding: 10px; }
    @media screen and (min-width: 576px) {
      .AdvancedSearch_advanced-search__form__10ydD {
        padding: 40px; } }
    @media screen and (min-width: 1200px) {
      .AdvancedSearch_advanced-search__form__10ydD {
        padding: 70px 70px 100px 70px; } }
  .AdvancedSearch_advanced-search__buttons__1HDs8 {
    text-align: right;
    padding: 0 15px;
    margin-top: 10px; }
  .AdvancedSearch_advanced-search__button--reset__3eQbv, .AdvancedSearch_advanced-search__button--submit__2Qq6a {
    font-size: 0.85rem;
    margin: 0 0 10px 15px; }
  .AdvancedSearch_advanced-search__button--submit__2Qq6a {
    padding: 5px 40px;
    border-color: #707070; }
  .AdvancedSearch_advanced-search__form-error__2HYCc {
    color: #22737B;
    font-size: 0.7rem;
    line-height: 1rem;
    display: inline-block;
    padding: 15px;
    text-align: right;
    width: 100%; }
  .AdvancedSearch_advanced-search__array__1xCc7 {
    list-style: none;
    margin: 0;
    padding: 0; }
  .AdvancedSearch_advanced-search__field__3JIti {
    display: flex;
    margin: 20px 15px; }
    .AdvancedSearch_advanced-search__field__3JIti:hover {
      background: #EAEAEA; }
    .AdvancedSearch_advanced-search__field--inner__2f3tB {
      display: flex;
      flex: 1 1;
      flex-direction: column; }
      @media screen and (min-width: 576px) {
        .AdvancedSearch_advanced-search__field--inner__2f3tB {
          padding-right: 20px;
          border-right: solid 1px #A5A5A5; } }
      @media screen and (min-width: 992px) {
        .AdvancedSearch_advanced-search__field--inner__2f3tB {
          border-right: none;
          flex-direction: row; } }
    .AdvancedSearch_advanced-search__field--query-conj__1uSz6 {
      display: flex;
      flex: 1 1;
      flex-direction: column; }
      @media screen and (min-width: 576px) {
        .AdvancedSearch_advanced-search__field--query-conj__1uSz6 {
          flex-direction: row; } }
    .AdvancedSearch_advanced-search__field--query__a6eKG {
      width: 100%;
      margin: 10px 10px 10px 0; }
      @media screen and (min-width: 992px) {
        .AdvancedSearch_advanced-search__field--query__a6eKG {
          margin: 0 15px; } }
      @media screen and (min-width: 576px) {
        .AdvancedSearch_advanced-search__field--query__a6eKG {
          flex: 1 1 400px; } }
    .AdvancedSearch_advanced-search__field--type__1H7x4 {
      width: 250px; }
      @media screen and (min-width: 992px) {
        .AdvancedSearch_advanced-search__field--type__1H7x4 {
          flex: 0 0 250px; } }
    .AdvancedSearch_advanced-search__field--conj__2-hsD {
      width: 80px;
      margin: 10px 0; }
      @media screen and (min-width: 992px) {
        .AdvancedSearch_advanced-search__field--conj__2-hsD {
          margin: 0; } }
      @media screen and (min-width: 576px) {
        .AdvancedSearch_advanced-search__field--conj__2-hsD {
          display: flex;
          align-items: flex-end;
          flex: 0 0 80px; } }
      @media screen and (min-width: 1200px) {
        .AdvancedSearch_advanced-search__field--conj__2-hsD {
          align-items: flex-start; } }
    .AdvancedSearch_advanced-search__field-text__1D8Z1, .AdvancedSearch_advanced-search__field-select__1IKF5, .AdvancedSearch_advanced-search__field-date__A4UGs {
      position: relative;
      display: flex;
      flex-direction: column; }
    .AdvancedSearch_advanced-search__field-date--form-control__4GU7E {
      width: 80px; }
    .AdvancedSearch_advanced-search__field-button__1W1zp {
      font-size: 0.85rem;
      padding-top: 0;
      padding-bottom: 0;
      align-self: flex-end;
      line-height: 1;
      align-self: flex-start; }
      @media screen and (min-width: 992px) {
        .AdvancedSearch_advanced-search__field-button__1W1zp {
          align-self: center;
          height: calc(1em + 0.65rem + 2px); } }
    .AdvancedSearch_advanced-search__field-error__3ECdM {
      color: #22737B;
      font-size: 0.7rem;
      line-height: 1rem;
      display: inline-block; }
  .AdvancedSearch_advanced-search__checkbox__3G1e2 {
    display: flex;
    align-items: center; }
  .AdvancedSearch_advanced-search__add__378UU {
    padding: 15px; }
    .AdvancedSearch_advanced-search__add-button__1lZCM {
      font-size: 0.85rem;
      padding: 3px 10px 3px 5px;
      border-color: #707070; }
  .AdvancedSearch_advanced-search__date__KdUUg {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1200px) {
      .AdvancedSearch_advanced-search__date__KdUUg {
        flex-direction: row; } }
    .AdvancedSearch_advanced-search__date--from__foV-5, .AdvancedSearch_advanced-search__date--to__2iobl {
      align-items: flex-start;
      margin-right: 5px;
      line-height: 1.6rem; }
    .AdvancedSearch_advanced-search__date--from__foV-5, .AdvancedSearch_advanced-search__date--to__2iobl, .AdvancedSearch_advanced-search__date--timeline__3wqVd {
      flex: 1 1;
      display: flex;
      font-size: 0.9rem;
      color: #4B4B4B; }
      .AdvancedSearch_advanced-search__date--from__foV-5 .AdvancedSearch_advanced-search__field-date__A4UGs, .AdvancedSearch_advanced-search__date--from__foV-5 .AdvancedSearch_advanced-search__field-select__1IKF5, .AdvancedSearch_advanced-search__date--to__2iobl .AdvancedSearch_advanced-search__field-date__A4UGs, .AdvancedSearch_advanced-search__date--to__2iobl .AdvancedSearch_advanced-search__field-select__1IKF5, .AdvancedSearch_advanced-search__date--timeline__3wqVd .AdvancedSearch_advanced-search__field-date__A4UGs, .AdvancedSearch_advanced-search__date--timeline__3wqVd .AdvancedSearch_advanced-search__field-select__1IKF5 {
        margin: 0 7px; }
      .AdvancedSearch_advanced-search__date--from__foV-5 .AdvancedSearch_advanced-search__field-select__1IKF5, .AdvancedSearch_advanced-search__date--to__2iobl .AdvancedSearch_advanced-search__field-select__1IKF5, .AdvancedSearch_advanced-search__date--timeline__3wqVd .AdvancedSearch_advanced-search__field-select__1IKF5 {
        width: 60px;
        flex: 0 0 60px; }
    .AdvancedSearch_advanced-search__date--timeline__3wqVd {
      line-height: 1;
      margin-top: 10px; }
      @media screen and (min-width: 1200px) {
        .AdvancedSearch_advanced-search__date--timeline__3wqVd {
          margin-top: 0;
          padding-left: 10px;
          margin-left: 10px;
          border-left: solid 1px #A5A5A5; } }

.contrast .AdvancedSearch_advanced-search__header__3PTNJ, .contrast .AdvancedSearch_advanced-search__title__2Y090, .contrast .AdvancedSearch_advanced-search__content__z-yxM, .contrast .AdvancedSearch_advanced-search__link__1a_rP {
  color: #FFF; }

.contrast .AdvancedSearch_advanced-search__link__1a_rP {
  color: #FFF500; }

.contrast .AdvancedSearch_advanced-search__form__10ydD {
  background-color: #0D0C0C;
  border: 1px solid #333; }
  .contrast .AdvancedSearch_advanced-search__form-control__30CyK, .contrast .AdvancedSearch_advanced-search__field-date--form-control__4GU7E {
    border-bottom-color: #FFF500;
    color: #FFF; }
    .contrast .AdvancedSearch_advanced-search__form-control__30CyK:focus, .contrast .AdvancedSearch_advanced-search__field-date--form-control__4GU7E:focus {
      background: #FFF500;
      border-color: #FFF500;
      color: #0D0C0C; }
    .contrast .AdvancedSearch_advanced-search__form-control__30CyK option, .contrast .AdvancedSearch_advanced-search__field-date--form-control__4GU7E option {
      color: #FFF500;
      background: #0D0C0C; }

.contrast .AdvancedSearch_advanced-search__date--timeline__3wqVd, .contrast .AdvancedSearch_advanced-search__date--from__foV-5, .contrast .AdvancedSearch_advanced-search__date--to__2iobl {
  border-color: #333;
  color: #FFF; }

.contrast .AdvancedSearch_advanced-search__field__3JIti {
  background: none; }
  .contrast .AdvancedSearch_advanced-search__field-date--form-control__4GU7E::-webkit-input-placeholder {
    color: #FFF; }
  .contrast .AdvancedSearch_advanced-search__field-date--form-control__4GU7E::-moz-placeholder {
    color: #FFF; }
  .contrast .AdvancedSearch_advanced-search__field-date--form-control__4GU7E:-moz-placeholder {
    color: #FFF; }
  .contrast .AdvancedSearch_advanced-search__field-date--form-control__4GU7E:-ms-input-placeholder {
    color: #FFF; }
  .contrast .AdvancedSearch_advanced-search__field-date--form-control__4GU7E:focus {
    background: #0D0C0C;
    border-color: #FFF500;
    outline: none;
    color: #FFF500; }
  .contrast .AdvancedSearch_advanced-search__field-select__1IKF5 {
    color: #FFF; }

.Results_results__Pz9L7 {
  flex: 1 1;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 991.98px) {
    .Results_results__container__2U8cA {
      flex-direction: column; } }
  .Results_results__title__279Ng {
    padding: 12px 0 22px;
    font-size: 1.375rem;
    line-height: 1.4375rem; }
    @media screen and (max-width: 767.98px) {
      .Results_results__title__279Ng {
        padding: 12px 15px 22px; } }
    @media screen and (min-width: 1800px) {
      .Results_results__title__279Ng {
        font-size: 1.75rem;
        line-height: 2rem; } }
  .Results_results__sidebar__3Kc3W {
    flex: 0 0 395px; }
    @media screen and (max-width: 767.98px) {
      .Results_results__sidebar__3Kc3W {
        padding: 0 15px; } }
  .Results_results__loading__9aUGA {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .Results_results-metadata__1u5qs {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    border-top: 1px solid #DADADA;
    border-bottom: 1px solid #DADADA;
    padding: 12px 0;
    margin-left: 20px; }
    @media screen and (max-width: 991.98px) {
      .Results_results-metadata__1u5qs {
        order: 4;
        margin-top: 15px;
        margin-left: 0; } }
  .Results_results--btn__32JJk {
    font-size: 0.9375rem;
    box-shadow: 0px -11px 30px #f5f5f5;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 15px; }
    @media screen and (max-width: 991.98px) {
      .Results_results--btn__32JJk {
        font-size: 0.875rem; } }
    .Results_results--btn__32JJk:focus {
      box-shadow: 0px -11px 30px #f5f5f5; }
    .Results_results--btn__32JJk:hover {
      color: #22737B; }
    .Results_results--btn-active__an1-m {
      box-shadow: none; }
      .Results_results--btn-active__an1-m:focus {
        box-shadow: none; }
    .Results_results--btn__32JJk i:before {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .Results_results--list__orD6W {
    padding-left: 0;
    list-style: none;
    font-size: 0.875rem;
    color: #4B4B4B;
    text-align: left;
    flex: 1 1;
    overflow: hidden; }
    @media screen and (max-width: 991.98px) {
      .Results_results--list__orD6W {
        padding-bottom: 40px; } }
    @media screen and (min-width: 1800px) {
      .Results_results--list__orD6W {
        flex: 0 0 415px; } }
    .Results_results--list-height__3QYd1 {
      max-height: 170px; }
    .Results_results--list-opened__2IVqa {
      max-height: 100%; }
  .Results_results__line__NCn4p {
    margin: 0;
    border-color: #EAEAEA; }
  .Results_results__no-results__3Vu-5 {
    padding: 25px; }
  .Results_results__content__2XldJ {
    display: flex; }
    @media screen and (max-width: 991.98px) {
      .Results_results__content__2XldJ {
        flex-direction: column; } }
  .Results_results__main__3hH5q {
    flex: 1 1; }
    @media screen and (max-width: 767.98px) {
      .Results_results__main__3hH5q {
        padding: 0 15px; } }
  .Results_results__list__1bxHx {
    background-color: #FFFFFF;
    list-style: none;
    padding: 0;
    margin-bottom: 3px; }
  .Results_results__item__1GMwP {
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-top: 3px;
    position: relative; }
    @media screen and (min-width: 992px) {
      .Results_results__item__1GMwP {
        padding-left: 30px; } }
    @media screen and (min-width: 1415px) {
      .Results_results__item__1GMwP {
        padding-left: 70px; } }
    @media screen and (min-width: 1800px) {
      .Results_results__item__1GMwP {
        padding-left: 95px; } }
    .Results_results__item__1GMwP::after {
      content: '';
      display: block;
      background: #F5F5F5;
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      z-index: -1;
      margin-right: -100%;
      margin-top: -18px; }
      @media screen and (max-width: 991.98px) {
        .Results_results__item__1GMwP::after {
          width: 200%;
          margin-right: -50%; } }
    .Results_results__item-external-link__1zowT {
      border: 0;
      color: #22737B;
      text-align: left;
      font-size: 0.875rem;
      position: relative;
      vertical-align: baseline; }
      @media screen and (max-width: 991.98px) {
        .Results_results__item-external-link__1zowT {
          flex-direction: column;
          margin-bottom: 5px;
          font-size: 0.75rem; } }
      .Results_results__item-external-link__1zowT:focus {
        box-shadow: none; }
      .Results_results__item-external-link__1zowT:active {
        color: #4B4B4B; }
      @media screen and (min-width: 1415px) {
        .Results_results__item-external-link__1zowT i {
          font-size: 0.75rem; } }
      @media screen and (max-width: 991.98px) {
        .Results_results__item-external-link__1zowT i {
          right: -15px;
          top: -1px; } }
      .Results_results__item-external-link--no-link__1Xi6s {
        color: #4B4B4B; }
    .Results_results__item-content__U5fFF {
      display: flex;
      flex-wrap: wrap;
      position: relative; }
    .Results_results__item-bottom__V2mvw {
      display: flex;
      flex: 1 1;
      margin-top: 10px; }
      @media screen and (max-width: 575.98px) {
        .Results_results__item-bottom__V2mvw {
          flex-direction: column; } }
      @media screen and (max-width: 991.98px) {
        .Results_results__item-bottom__V2mvw {
          align-items: flex-start; } }
      .Results_results__item-bottom--left__XAbP_ {
        display: flex;
        flex-direction: column;
        flex: 0 1 160px; }
        @media screen and (max-width: 575.98px) {
          .Results_results__item-bottom--left__XAbP_ {
            flex: 1 1; } }
      .Results_results__item-bottom--right__2A9BB {
        display: flex;
        flex: 1 1;
        justify-content: flex-end;
        align-self: flex-start; }
        @media screen and (max-width: 575.98px) {
          .Results_results__item-bottom--right__2A9BB {
            align-self: flex-end;
            flex-direction: column; } }
      .Results_results__item-bottom--type__33kI_ {
        font-size: 0.75rem;
        display: flex;
        color: #4F4F4F; }
        .Results_results__item-bottom--type__33kI_ i {
          font-size: 1.2rem;
          line-height: 1rem;
          color: #9CA3A8; }
          @media screen and (max-width: 991.98px) {
            .Results_results__item-bottom--type__33kI_ i {
              font-size: 1rem; } }
      .Results_results__item-bottom--copy__3u2J3, .Results_results__share-button__ASobX {
        padding: 0;
        font-size: 0.875rem; }
        .Results_results__item-bottom--copy__3u2J3 i, .Results_results__share-button__ASobX i {
          font-size: 1.1rem;
          line-height: 0.8rem; }
    .Results_results__item-title__3r0qE, .Results_results__item-external-link--no-link__1Xi6s, .Results_results__item-title--no-link__YszHw {
      color: #22737B;
      text-align: left;
      margin-bottom: 14px; }
      .Results_results__item-title__3r0qE:focus, .Results_results__item-external-link--no-link__1Xi6s:focus, .Results_results__item-title--no-link__YszHw:focus {
        box-shadow: none; }
      .Results_results__item-title__3r0qE:active, .Results_results__item-external-link--no-link__1Xi6s:active, .Results_results__item-title--no-link__YszHw:active {
        color: #4B4B4B; }
      @media screen and (max-width: 991.98px) {
        .Results_results__item-title__3r0qE, .Results_results__item-external-link--no-link__1Xi6s, .Results_results__item-title--no-link__YszHw {
          order: 2;
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: calc(100% - 90px);
          font-size: 0.9375rem;
          line-height: 1.25rem;
          margin-left: 10px;
          margin-bottom: 0; } }
      @media screen and (min-width: 992px) {
        .Results_results__item-title__3r0qE, .Results_results__item-external-link--no-link__1Xi6s, .Results_results__item-title--no-link__YszHw {
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: calc(100% - 60px);
          font-size: 0.9375rem; } }
      @media screen and (min-width: 1415px) {
        .Results_results__item-title__3r0qE, .Results_results__item-external-link--no-link__1Xi6s, .Results_results__item-title--no-link__YszHw {
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: calc(100% - 70px);
          font-size: 1rem;
          line-height: 1.25rem; } }
      @media screen and (min-width: 1800px) {
        .Results_results__item-title__3r0qE, .Results_results__item-external-link--no-link__1Xi6s, .Results_results__item-title--no-link__YszHw {
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: calc(100% - 95px);
          font-size: 1.25rem;
          line-height: 1.625rem; } }
      @media screen and (min-width: 1415px) {
        .Results_results__item-title__3r0qE i, .Results_results__item-external-link--no-link__1Xi6s i, .Results_results__item-title--no-link__YszHw i {
          font-size: 0.75rem; } }
      @media screen and (min-width: 1800px) {
        .Results_results__item-title__3r0qE i, .Results_results__item-external-link--no-link__1Xi6s i, .Results_results__item-title--no-link__YszHw i {
          font-size: 1rem; } }
      .Results_results__item-title--no-link__YszHw {
        color: #4B4B4B; }
    .Results_results__item-authors__mA58Q {
      flex: 1 1 100%;
      margin-top: -10px;
      font-size: 0.875rem;
      margin-bottom: 14px; }
      @media screen and (max-width: 991.98px) {
        .Results_results__item-authors__mA58Q {
          order: 3;
          font-size: 0.75rem;
          margin-top: 10px; } }
      .Results_results__item-authors__mA58Q span:not(:last-child):after {
        content: "; "; }
    .Results_results__item-img__3KKU8, .Results_results__item-img--error__3Mh-3 {
      display: flex;
      width: 160px;
      height: 220px;
      background-color: #EAEAEA;
      border: 1px solid #E0E0E0;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 991.98px) {
        .Results_results__item-img__3KKU8, .Results_results__item-img--error__3Mh-3 {
          width: 80px;
          height: 110px;
          order: 1;
          margin: 0; } }
      .Results_results__item-img--error__3Mh-3 {
        background-image: url(/static/media/icon-image.f890664f.svg);
        background-size: 66px;
        background-repeat: no-repeat; }
        @media screen and (max-width: 991.98px) {
          .Results_results__item-img--error__3Mh-3 {
            background-size: 40px; } }
      .Results_results__item-img__3KKU8 i, .Results_results__item-img--error__3Mh-3 i {
        font-size: 4.125rem;
        margin: auto;
        color: #D9D9D9; }
        @media screen and (max-width: 991.98px) {
          .Results_results__item-img__3KKU8 i, .Results_results__item-img--error__3Mh-3 i {
            font-size: 2.5rem; } }
      .Results_results__item-img__3KKU8 img, .Results_results__item-img--error__3Mh-3 img {
        margin: 0 auto;
        width: 66px;
        display: none; }
        @media screen and (max-width: 991.98px) {
          .Results_results__item-img__3KKU8 img, .Results_results__item-img--error__3Mh-3 img {
            width: 40px; } }
    .Results_results__item-metadata__2FRw4 {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      border-top: 1px solid #DADADA;
      border-bottom: 1px solid #DADADA;
      padding: 12px 0 50px;
      margin-left: 20px;
      position: relative; }
      @media screen and (max-width: 991.98px) {
        .Results_results__item-metadata__2FRw4 {
          order: 4;
          margin-top: 15px;
          margin-left: 0; } }
      .Results_results__item-metadata--btn__2-7GW {
        font-size: 0.9375rem;
        box-shadow: 0px -11px 30px #f5f5f5;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 0 15px;
        position: absolute;
        bottom: 10px; }
        .Results_results__item-metadata--btn__2-7GW:focus {
          box-shadow: 0px -11px 30px #f5f5f5; }
        .Results_results__item-metadata--btn__2-7GW:hover {
          color: #22737B; }
        @media screen and (max-width: 991.98px) {
          .Results_results__item-metadata--btn__2-7GW {
            font-size: 0.875rem; } }
        .Results_results__item-metadata--btn-active__3xMW3 {
          box-shadow: none; }
          .Results_results__item-metadata--btn-active__3xMW3:focus {
            box-shadow: none; }
          .Results_results__item-metadata--btn-active__3xMW3 i:before {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg); }
      .Results_results__item-metadata--list__1W1dV {
        padding-left: 0;
        list-style: none;
        font-size: 0.875rem;
        color: #4B4B4B;
        text-align: left; }
        .Results_results__item-metadata--list-secondary__3In89 {
          display: none; }
          .Results_results__item-metadata--list-secondary__3In89:empty + button {
            display: none; }
        .Results_results__item-metadata--list-active__3818M {
          display: block; }
        @media screen and (max-width: 991.98px) {
          .Results_results__item-metadata--list__1W1dV {
            font-size: 0.75rem; } }
      .Results_results__item-metadata--item__2Lnrh {
        display: flex;
        line-height: 1.75rem;
        margin-bottom: 5px; }
        @media screen and (max-width: 991.98px) {
          .Results_results__item-metadata--item__2Lnrh {
            flex-direction: column;
            line-height: 1.25rem;
            margin-bottom: 5px; } }
      .Results_results__item-metadata--label__3ft4Y {
        font-weight: 700;
        flex: 0 1 185px;
        line-height: 1.25rem; }
        @media screen and (max-width: 991.98px) {
          .Results_results__item-metadata--label__3ft4Y {
            flex: 1 1; } }
        .Results_results__item-metadata--label__3ft4Y i {
          color: #22737B;
          line-height: 1rem;
          font-size: 0.875rem;
          vertical-align: top; }
      .Results_results__item-metadata--desc__3E-fX {
        flex: 1 1;
        word-break: break-word;
        line-height: 1.25rem; }
        .Results_results__item-metadata--desc__3E-fX span:not(:last-child):after {
          content: "; "; }
      .Results_results__item-metadata--chapter__3s82g, .Results_results__item-metadata--publisher__1qii0, .Results_results__item-metadata--newspaper__2xzXT {
        margin-bottom: 8px;
        font-size: 0.875rem;
        line-height: 1.25rem; }
        @media screen and (max-width: 991.98px) {
          .Results_results__item-metadata--chapter__3s82g, .Results_results__item-metadata--publisher__1qii0, .Results_results__item-metadata--newspaper__2xzXT {
            font-size: 0.75rem; } }
        .Results_results__item-metadata--chapter__3s82g span:not(:last-child):after, .Results_results__item-metadata--publisher__1qii0 span:not(:last-child):after, .Results_results__item-metadata--newspaper__2xzXT span:not(:last-child):after {
          content: ", "; }
      .Results_results__item-metadata--info__39ZB- {
        margin-bottom: 8px; }
  .Results_results__share__3tPmT {
    line-height: 1.15; }
    .Results_results__share__3tPmT:before {
      content: "|";
      margin-left: 10px;
      margin-right: 3px;
      color: #22737B; }
    .Results_results__share__3tPmT:after {
      display: none; }
    .Results_results__share-list__3EEU9 {
      list-style-type: none;
      padding: 0;
      margin: 0; }
    .Results_results__share-item__2O8lX a {
      display: flex;
      padding: 5px 10px;
      color: #22737B; }
      .Results_results__share-item__2O8lX a:focus, .Results_results__share-item__2O8lX a:hover {
        background: rgba(0, 0, 0, 0.025); }
      .Results_results__share-item__2O8lX a svg {
        flex: 0 0 40px;
        width: 15px;
        height: 15px;
        position: relative;
        top: 2px; }
      .Results_results__share-item__2O8lX a path {
        fill: #8b8b8b; }
  .Results_results__nav__26nRU {
    background: #EAEAEA;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0; }
    @media screen and (max-width: 767.98px) {
      .Results_results__nav__26nRU {
        justify-content: flex-end; } }
    @media screen and (min-width: 992px) {
      .Results_results__nav__26nRU {
        padding-left: 30px; } }
    @media screen and (min-width: 1415px) {
      .Results_results__nav__26nRU {
        padding-left: 70px; } }
    @media screen and (min-width: 1800px) {
      .Results_results__nav__26nRU {
        padding-left: 95px; } }
    .Results_results__nav__26nRU::after {
      content: '';
      display: block;
      background: #EAEAEA;
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      z-index: -1;
      margin-right: -100%;
      top: 0; }
      @media screen and (max-width: 991.98px) {
        .Results_results__nav__26nRU::after {
          width: 200%;
          margin-right: -50%; } }
    .Results_results__nav--bottom__mGXKM {
      justify-content: flex-end; }
  @media screen and (max-width: 767.98px) {
    .Results_results__page-size__2Vmtc {
      display: none; } }

.contrast .Results_results__no-results__3Vu-5 {
  color: #FFF; }

.contrast .Results_results__line__NCn4p {
  border-color: #333333; }

@media screen and (min-width: 992px) {
  .contrast .Results_results__sidebar__3Kc3W {
    border-right: 1px solid #333; } }

.contrast .Results_results__title__279Ng {
  color: #FFF; }

.contrast .Results_results__nav__26nRU {
  background: #0D0C0C;
  border-bottom: 2px solid #333; }
  .contrast .Results_results__nav__26nRU::after {
    background: #0D0C0C; }

.contrast .Results_results__loading__9aUGA img {
  -webkit-filter: invert(1) brightness(100);
          filter: invert(1) brightness(100); }

.contrast .Results_results__list__1bxHx {
  background-color: #333333; }

.contrast .Results_results__item__1GMwP {
  background-color: #0D0C0C; }
  .contrast .Results_results__item__1GMwP::after {
    background: #0D0C0C; }
  .contrast .Results_results__item-img__3KKU8, .contrast .Results_results__item-img--error__3Mh-3 {
    background-color: #070606;
    border-color: #707070; }
    .contrast .Results_results__item-img__3KKU8 i, .contrast .Results_results__item-img--error__3Mh-3 i {
      color: #FFF; }
  .contrast .Results_results__item-authors__mA58Q {
    color: #FFF; }
  .contrast .Results_results__item-title__3r0qE, .contrast .Results_results__item-external-link--no-link__1Xi6s, .contrast .Results_results__item-title--no-link__YszHw {
    color: #FFF500; }
    .contrast .Results_results__item-title--no-link__YszHw {
      color: #FFF; }
  .contrast .Results_results__item-metadata__2FRw4 {
    border-color: #333333; }
    .contrast .Results_results__item-metadata--list__1W1dV, .contrast .Results_results__item-metadata--newspaper__2xzXT, .contrast .Results_results__item-metadata--publisher__1qii0, .contrast .Results_results__item-metadata--chapter__3s82g {
      color: #FFF; }
    .contrast .Results_results__item-metadata--label__3ft4Y i {
      color: #FFF500; }
    .contrast .Results_results__item-metadata--btn__2-7GW {
      box-shadow: 0px -11px 30px #0d0c0c;
      color: #FFF500; }
      .contrast .Results_results__item-metadata--btn-active__3xMW3, .contrast .Results_results__item-metadata--btn-active__3xMW3:focus {
        box-shadow: none; }
  .contrast .Results_results__item-bottom--type__33kI_ {
    color: #FFF; }
    .contrast .Results_results__item-bottom--type__33kI_ i {
      color: #9CA3A8; }

.contrast .Results_results__share-item__2O8lX a {
  color: #FFF500; }

.font-medium .Results_results__item-metadata--item__2Lnrh {
  line-height: 1.5rem; }

.font-big .Results_results__item-metadata--item__2Lnrh {
  line-height: 1.4rem; }

.Info_sidebar-info__2_YqT {
  position: relative;
  padding: 25px 0; }
  @media screen and (min-width: 992px) {
    .Info_sidebar-info__2_YqT {
      padding: 28px 35px 10px 0; } }
  @media screen and (min-width: 1800px) {
    .Info_sidebar-info__2_YqT {
      padding: 40px 35px 10px 0; } }
  @media screen and (min-width: 992px) {
    .Info_sidebar-info__2_YqT:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: -15px;
      width: 100vw;
      height: 100%;
      pointer-events: none;
      border-bottom: 1px solid #fafafa; } }
  .Info_sidebar-info__showmap__2ndb6 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    overflow: auto;
    outline: none;
    z-index: 999; }
    @media screen and (min-width: 992px) {
      .Info_sidebar-info__showmap__2ndb6 {
        border: 1px solid #cccccc;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        padding: 10px;
        border-radius: 4px; } }
    @media screen and (min-width: 1415px) {
      .Info_sidebar-info__showmap__2ndb6 {
        top: 40px;
        left: 40px;
        right: 40px;
        bottom: 40px;
        padding: 20px; } }
  .Info_sidebar-info__text--title__3BaRb {
    font-weight: bold;
    margin: 0;
    font-size: 0.9rem; }
  @media screen and (min-width: 1800px) {
    .Info_sidebar-info__text__12Spp {
      font-size: 1.25rem; } }
  .Info_sidebar-info__head__aMDtD {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .Info_sidebar-info__actions__1tpme {
    list-style-type: none;
    margin: 0 -7px;
    padding: 0;
    display: flex;
    line-height: 1.3rem; }
    @media screen and (min-width: 1800px) {
      .Info_sidebar-info__actions__1tpme {
        margin: 0 -10px; } }
    .Info_sidebar-info__actions--item__oLY3L {
      padding: 0 7px;
      position: relative; }
      @media screen and (min-width: 1800px) {
        .Info_sidebar-info__actions--item__oLY3L {
          padding: 0 10px; } }
      .Info_sidebar-info__actions--item__oLY3L:not(:last-child):after {
        content: "|";
        position: absolute;
        right: -2px;
        top: 50%;
        color: #B2B2B2;
        line-height: 0; }
      @media screen and (min-width: 1800px) {
        .Info_sidebar-info__actions--item__oLY3L:not(:last-child) {
          right: -3px; } }
    .Info_sidebar-info__actions--button__1y-H0 {
      padding: 0;
      font-size: 0.8rem;
      color: #22737B; }
      @media screen and (min-width: 1800px) {
        .Info_sidebar-info__actions--button__1y-H0 {
          font-size: 0.9rem; } }
      .Info_sidebar-info__actions--button__1y-H0:hover {
        color: #22737B; }
  .Info_sidebar-info__block__2Gr7B {
    margin: 10px 0 23px; }
    .Info_sidebar-info__block__2Gr7B:first-child {
      margin-bottom: 35px; }
    .Info_sidebar-info__block--link__35lML {
      padding: 0;
      text-align: left;
      font-size: 0.8rem; }
      @media screen and (min-width: 1800px) {
        .Info_sidebar-info__block--link__35lML {
          font-size: 0.93rem; } }
      .Info_sidebar-info__block--link__35lML[target="_blank"] i {
        position: relative;
        top: -5px; }
  .Info_sidebar-info__textbox__15Scc {
    margin: 0;
    line-height: 1.8rem;
    font-size: 0.8rem; }
    @media screen and (min-width: 1800px) {
      .Info_sidebar-info__textbox__15Scc {
        font-size: 0.93rem; } }
    .Info_sidebar-info__textbox--query__Gk6pi {
      margin-left: 5px; }
      .Info_sidebar-info__textbox--query__Gk6pi [data-tip] {
        position: relative;
        top: -5px; }
    .Info_sidebar-info__textbox--item__2ZIlA {
      display: flex;
      align-items: baseline; }
    .Info_sidebar-info__textbox--type__111aZ {
      margin-right: 5px; }
    .Info_sidebar-info__textbox--conj__2QpCA {
      background: rgba(0, 0, 0, 0.035);
      min-width: 1.5em;
      text-align: center;
      display: inline-block;
      margin-right: 5px;
      padding: 0 5px;
      border: 1px solid #fff; }
    .Info_sidebar-info__textbox--era__2mUIG {
      margin-left: 3px; }

.contrast .Info_sidebar-info__2_YqT {
  color: #FFF; }
  .contrast .Info_sidebar-info__2_YqT::after {
    border-color: #333; }
  .contrast .Info_sidebar-info__block--link__35lML {
    color: #FFF500; }
  .contrast .Info_sidebar-info__actions--button__1y-H0 {
    color: #FFF500; }
  .contrast .Info_sidebar-info__textbox__15Scc i {
    color: #FFF500; }
  .contrast .Info_sidebar-info__showmap__2ndb6 {
    background: #000000;
    border-color: #FFF000; }

.GeoMap_map__mwXiD {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .GeoMap_map__header__13Eta {
    display: flex;
    justify-content: space-between;
    align-items: baseline; }
  .GeoMap_map__info__17UPD {
    margin-top: 20px; }
    @media screen and (max-width: 991.98px) {
      .GeoMap_map__info__17UPD {
        display: none; } }
  .GeoMap_map__legend__-i8Co {
    margin-top: 10px;
    border-top: 1px solid #cccccc;
    padding: 8px 10px 10px;
    background: #F5F5F5;
    display: flex;
    align-items: baseline; }
    @media screen and (max-width: 991.98px) {
      .GeoMap_map__legend__-i8Co {
        display: none; } }
    .GeoMap_map__legend--list__3TvUt {
      margin: 0;
      padding: 0;
      list-style-type: none; }
      @media screen and (min-width: 1200px) {
        .GeoMap_map__legend--list__3TvUt {
          display: flex; } }
    .GeoMap_map__legend--item__1po0d {
      padding: 0 5px;
      position: relative; }
      @media screen and (min-width: 1200px) {
        .GeoMap_map__legend--item__1po0d:not(:last-child):after {
          position: absolute;
          right: 4px;
          top: 0.5em;
          content: "";
          width: 1px;
          height: 1em;
          display: block;
          background: #cccccc; } }
    .GeoMap_map__legend--img-cluster__3V78m, .GeoMap_map__legend--img-object__1b4H1 {
      margin-right: 5px; }
    .GeoMap_map__legend--img-cluster__3V78m {
      height: 22px; }
    .GeoMap_map__legend--img-object__1b4H1 {
      height: 17px; }
  .GeoMap_map__box__sO-NY {
    flex: 1 1;
    position: relative; }
    .GeoMap_map__box--loading__3SQ_3 {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 999; }
      .GeoMap_map__box--loading__3SQ_3 img {
        width: 50px; }
        @media screen and (max-width: 991.98px) {
          .GeoMap_map__box--loading__3SQ_3 img {
            width: 25px; } }
  @media screen and (max-width: 991.98px) {
    .GeoMap_map__close__22ixg span {
      display: none; } }
  .GeoMap_map__popup__2vbdp {
    background: red; }
    .GeoMap_map__popup__2vbdp > div {
      border-radius: 0 !important;
      max-height: 270px;
      overflow-y: auto; }
    .GeoMap_map__popup__2vbdp > a {
      margin: 10px 16px; }
    .GeoMap_map__popup--loading__uPyEH {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .GeoMap_map__popup--loading__uPyEH img {
        width: 50px; }
    .GeoMap_map__popup--list__3kmsJ {
      margin: 0;
      padding: 0;
      list-style-type: none; }
    .GeoMap_map__popup--item__2yIH9 {
      padding: 10px 0; }
      .GeoMap_map__popup--item__2yIH9:not(:last-child) {
        border-bottom: 1px solid #ccc; }
    .GeoMap_map__popup--buttons__2d6L9 {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 7px;
      margin-bottom: -3px; }
    .GeoMap_map__popup--button__29U93 {
      background: #22677E;
      padding: 5px;
      margin: 3px 0;
      color: white !important; }
  .GeoMap_map__text--title__a5Ts3 {
    font-size: 1rem;
    line-height: 1.3rem; }
    @media screen and (max-width: 991.98px) {
      .GeoMap_map__text--title__a5Ts3 {
        padding: 0 10px;
        font-weight: bold;
        text-transform: uppercase; } }
    @media screen and (min-width: 992px) {
      .GeoMap_map__text--title__a5Ts3 {
        font-size: 1.375rem;
        line-height: 1.4375rem; } }
    @media screen and (min-width: 1800px) {
      .GeoMap_map__text--title__a5Ts3 {
        font-size: 1.75rem;
        line-height: 2rem; } }
  .GeoMap_map__text--info-main__2vga3 {
    font-weight: bold;
    font-size: 1rem; }
  .GeoMap_map__text--info__6eqA5 {
    font-size: 0.9rem; }
  .GeoMap_map__text--legend-title__CHjdK {
    font-size: 0.85em;
    font-weight: normal;
    margin-right: 5px; }
  .GeoMap_map__text--legend-span__2o5tK {
    font-size: 0.85em;
    margin-right: 10px; }
  .GeoMap_map__text--popup-title__2o1xT {
    font-size: 1rem;
    margin: 0; }
  .GeoMap_map__text--popup-text__2-tGR {
    font-size: 0.8rem;
    margin: 5px 0 0 !important; }
  .GeoMap_map__container__3N78g {
    height: 100%;
    width: 100%; }
  .GeoMap_map__tooltip--cluster__wUhyw {
    background: transparent !important;
    border: 0 !important;
    border-radius: 0 !important;
    color: #fff !important;
    box-shadow: none !important;
    margin-left: 0 !important;
    width: 40px;
    text-align: center; }
    .GeoMap_map__tooltip--cluster__wUhyw:before {
      display: none !important; }

.contrast .GeoMap_map__legend__-i8Co {
  background: #fff000;
  border-color: #fff000; }
  .contrast .GeoMap_map__legend--img-cluster__3V78m, .contrast .GeoMap_map__legend--img-object__1b4H1 {
    -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(1);
    filter: invert(30%) grayscale(100%) brightness(70%) contrast(1); }
  .contrast .GeoMap_map__legend--item__1po0d:not(:last-child):after {
    background: black; }

.contrast .GeoMap_map__text--title__a5Ts3, .contrast .GeoMap_map__text--info-main__2vga3, .contrast .GeoMap_map__text--info__6eqA5, .contrast .GeoMap_map__text--popup-title__2o1xT, .contrast .GeoMap_map__text--popup-text__2-tGR {
  color: white; }

.contrast .GeoMap_map__text--legend-title__CHjdK, .contrast .GeoMap_map__text--legend-span__2o5tK {
  color: black; }

.contrast .GeoMap_map__popup--button__29U93 {
  background: #fff000;
  color: black !important; }

.Categories_categories__title__3o-Kt {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700; }
  @media screen and (max-width: 991.98px) {
    .Categories_categories__title__3o-Kt {
      margin: 0; } }
  .Categories_categories__title-container__2dsSY {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 991.98px) {
      .Categories_categories__title-container__2dsSY {
        background-color: #ECEEEF;
        padding: 10px; } }
    @media screen and (max-width: 991.98px) {
      .Categories_categories__title-container--hidden__3aN2M {
        background-color: #296376; }
        .Categories_categories__title-container--hidden__3aN2M .Categories_categories__title__3o-Kt,
        .Categories_categories__title-container--hidden__3aN2M .Categories_categories__catalogue-collapse__qlyRb {
          color: #FFFFFF; } }

@media screen and (max-width: 991.98px) {
  .Categories_categories__catalogue--hidden__OoxIH .Categories_categories__catalogue-list__L6UCJ {
    display: none; } }

.Categories_categories__catalogue-top__3OmXi {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 991.98px) {
    .Categories_categories__catalogue-top__3OmXi {
      margin: 12px 10px 0; } }
  @media screen and (max-width: 991.98px) {
    .Categories_categories__catalogue-top--selected__QoYdB {
      background-color: #ECEEEF;
      margin: 0;
      padding: 10px; } }
  @media screen and (max-width: 991.98px) {
    .Categories_categories__catalogue-top--selected__QoYdB .Categories_categories__catalogue-title__vfkAK {
      display: none; } }
  @media screen and (max-width: 991.98px) {
    .Categories_categories__catalogue-top--selected__QoYdB .Categories_categories__catalogue-selected__rhCLE {
      display: flex; } }
  @media screen and (max-width: 991.98px) {
    .Categories_categories__catalogue-top--clear__1DcZE {
      display: none; } }

.Categories_categories__catalogue-collapse__qlyRb {
  display: none;
  padding: 0; }
  @media screen and (max-width: 991.98px) {
    .Categories_categories__catalogue-collapse__qlyRb {
      display: inline-block;
      font-size: 0.8rem; } }
  .Categories_categories__catalogue-collapse__qlyRb i:before {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .Categories_categories__catalogue-collapse--hidden__3kmMR i:before {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }

.Categories_categories__catalogue-title__vfkAK {
  font-size: 0.9rem;
  font-weight: 700; }

.Categories_categories__catalogue-selected__rhCLE {
  color: #565656;
  display: none;
  flex-direction: column;
  font-weight: 700;
  font-size: 0.8rem; }
  .Categories_categories__catalogue-selected--span__1l6WC {
    color: #4B4B4B;
    font-weight: 400; }

.Categories_categories__catalogue-button__3Q1j6 {
  padding: 0;
  font-size: 0.8rem; }

.Categories_categories__catalogue-list__L6UCJ {
  padding: 10px 0 50px;
  list-style: none; }
  @media screen and (max-width: 991.98px) {
    .Categories_categories__catalogue-list__L6UCJ {
      margin: 0 10px;
      padding-bottom: 25px;
      border-bottom: 1px solid #EAEAEA; } }

.Categories_categories__catalogue-item__3KWkW {
  color: #4B4B4B;
  padding-top: 10px; }
  .Categories_categories__catalogue-item--container__2AfBi {
    display: flex;
    justify-content: space-between; }
  .Categories_categories__catalogue-item--label__3zJUB {
    align-items: center;
    display: flex;
    cursor: pointer; }
  .Categories_categories__catalogue-item--text__1pyb0 {
    font-size: 0.875rem;
    color: #22737B; }
    .Categories_categories__catalogue-item--text__1pyb0:hover {
      text-decoration: underline; }
  .Categories_categories__catalogue-item--count__MSyMT {
    color: #595959;
    font-size: 0.9375rem; }
  .Categories_categories__catalogue-item--input__2RW5w {
    width: 14px;
    height: 14px;
    margin-right: 10px; }
    .Categories_categories__catalogue-item--input__2RW5w:checked + .Categories_categories__catalogue-item--text__1pyb0 {
      color: #4B4B4B; }

.contrast .Categories_categories__catalogue-button__3Q1j6 {
  color: #FFF500; }

.contrast .Categories_categories__catalogue-item--text__1pyb0 {
  color: #FFF500; }

.contrast .Categories_categories__catalogue-item--count__MSyMT {
  color: #FFF; }

.contrast .Categories_categories__catalogue-item--input__2RW5w:checked + .Categories_categories__catalogue-item--text__1pyb0 {
  color: #FFF; }

.contrast .Categories_categories__catalogue-top--selected__QoYdB {
  background-color: #0d0c0c;
  border: 1px solid #333; }

.contrast .Categories_categories__catalogue-selected__rhCLE {
  color: #FFF; }
  .contrast .Categories_categories__catalogue-selected--span__1l6WC {
    color: #FFF; }

.contrast .Categories_categories__title-container__2dsSY {
  background-color: #0d0c0c;
  border: 1px solid #333; }

.FacetFormDateFields_date__1I4Cv {
  margin: 20px 0; }
  .FacetFormDateFields_date__shadow__27cIt {
    opacity: 0.5;
    pointer-events: none; }
  .FacetFormDateFields_date__container__1SVSc {
    display: flex;
    color: #4B4B4B;
    font-size: 0.9rem;
    line-height: calc(1em + 0.65rem + 2px);
    margin: 7px 0; }
  .FacetFormDateFields_date__form-control__LCHEH {
    background-color: transparent;
    border-radius: 0;
    border-color: transparent;
    border-bottom: solid 1px #A5A5A5;
    color: #4B4B4B;
    font-size: 0.85rem;
    padding: 3px 0;
    line-height: 1;
    width: 100%;
    height: calc(1em + 0.65rem + 2px); }
    .FacetFormDateFields_date__form-control__LCHEH:hover {
      border-bottom: solid 1px #22737B; }
    .FacetFormDateFields_date__form-control__LCHEH:active {
      border-bottom: solid 1px #4B4B4B; }
    .FacetFormDateFields_date__form-control__LCHEH:focus {
      border-color: transparent;
      border-bottom-color: #707070; }
    .FacetFormDateFields_date__form-control__LCHEH::-webkit-input-placeholder {
      color: #818A91;
      font-style: italic; }
    .FacetFormDateFields_date__form-control__LCHEH::-moz-placeholder {
      color: #818A91;
      font-style: italic; }
    .FacetFormDateFields_date__form-control__LCHEH:-moz-placeholder {
      color: #818A91;
      font-style: italic; }
    .FacetFormDateFields_date__form-control__LCHEH:-ms-input-placeholder {
      color: #818A91;
      font-style: italic; }
  .FacetFormDateFields_date__error__2s-7y {
    color: #22737B;
    font-size: 0.7rem;
    line-height: 1rem;
    display: inline-block; }

.FacetFormDateFields_input__2JkCF {
  flex: 0 1 120px;
  padding-left: 10px; }

.FacetFormDateFields_select__1bEs2 {
  flex: 0 0 80px;
  padding-left: 10px; }

.FacetFormDateFields_checkbox__2bIkA {
  color: #22737B;
  align-items: center;
  line-height: 18px;
  display: flex;
  width: 100%; }
  .FacetFormDateFields_checkbox__label__1xPjA {
    display: flex;
    flex: 1 1;
    cursor: pointer; }
    .FacetFormDateFields_checkbox__label__1xPjA:hover .FacetFormDateFields_checkbox__label--text__1-M1U {
      text-decoration: underline; }
    .FacetFormDateFields_checkbox__label--text__1-M1U {
      flex: 1 1; }
    .FacetFormDateFields_checkbox__label--count__3qqhW {
      color: #91999F; }

.FacetActions_actions__3asUj {
  list-style-type: none;
  margin: 0 -7px;
  padding: 0;
  display: flex;
  line-height: 1.3rem; }
  @media screen and (min-width: 1800px) {
    .FacetActions_actions__3asUj {
      margin: 0 -10px; } }
  .FacetActions_actions__item__3H99y {
    padding: 0 7px;
    position: relative; }
    @media screen and (min-width: 1800px) {
      .FacetActions_actions__item__3H99y {
        padding: 0 10px; } }
    .FacetActions_actions__item__3H99y:not(:last-child):after {
      content: "|";
      position: absolute;
      right: -2px;
      top: 50%;
      color: #B2B2B2;
      line-height: 0; }
      @media screen and (min-width: 1800px) {
        .FacetActions_actions__item__3H99y:not(:last-child):after {
          right: -3px; } }
  .FacetActions_actions__button__1UVWL {
    padding: 0;
    font-size: 0.8rem; }
    @media screen and (min-width: 1800px) {
      .FacetActions_actions__button__1UVWL {
        font-size: 0.9rem; } }
  .FacetActions_actions__delete__ZT12J {
    padding: 0;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .FacetActions_actions__delete__ZT12J {
        visibility: hidden;
        pointer-events: none; } }

.contrast .FacetActions_actions__button__1UVWL {
  color: #FFF500; }

.FacetForm_form__head__2gXUS {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 10px 7px; }

.FacetForm_form__content__3IFL2 {
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  padding: 1px 10px;
  overflow-y: auto;
  max-height: 200px; }

.FacetForm_form__list__2eW00 {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .FacetForm_form__list-item__13zjA {
    display: flex;
    margin: 11px 0; }
    .FacetForm_form__list-item__13zjA:hover .FacetForm_form__text--value__FxvQO {
      text-decoration: underline; }
    .FacetForm_form__list-item--label__1lArC {
      flex: 1 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
      width: 90%;
      align-items: baseline;
      color: #4B4B4B; }

.FacetForm_form__bottom__2gOWl {
  padding: 9px 10px 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.FacetForm_form__submit__3EcZ0 {
  padding: 3px 17px;
  font-size: 0.8rem; }
  @media screen and (min-width: 1800px) {
    .FacetForm_form__submit__3EcZ0 {
      font-size: 0.85rem; } }

.FacetForm_form__text--value__FxvQO {
  color: #22737B;
  font-size: 0.8rem;
  word-break: break-all;
  flex: 1 1; }
  @media screen and (min-width: 1800px) {
    .FacetForm_form__text--value__FxvQO {
      font-size: 0.9rem; } }

.FacetForm_form__text--count__1h0vk {
  color: #686F78;
  white-space: nowrap;
  font-size: 0.8rem;
  margin-left: 5px; }
  @media screen and (min-width: 1800px) {
    .FacetForm_form__text--count__1h0vk {
      font-size: 0.9rem;
      padding-right: 20px; } }

.contrast .FacetForm_form__text--value__FxvQO {
  color: #FFF500; }

.contrast .FacetForm_form__text--count__1h0vk {
  color: #FFF; }

.FacetChosen_chosen__list__1g_ks {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .FacetChosen_chosen__list-item__vbHb4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2px; }
    .FacetChosen_chosen__list-item__vbHb4:hover {
      background: #f5f5f5; }
      .FacetChosen_chosen__list-item__vbHb4:hover .FacetChosen_chosen__list-item--delete__3vL3P {
        visibility: visible;
        pointer-events: auto; }
    .FacetChosen_chosen__list-item--delete__3vL3P {
      padding: 0;
      margin: 0; }
      @media screen and (min-width: 992px) {
        .FacetChosen_chosen__list-item--delete__3vL3P {
          visibility: hidden;
          pointer-events: none; } }
    .FacetChosen_chosen__list-item--text__1NOv- {
      color: black;
      font-size: 0.8rem; }
      @media screen and (min-width: 1800px) {
        .FacetChosen_chosen__list-item--text__1NOv- {
          font-size: 0.9rem; } }

.FacetChosen_chosen__clear__1tosy {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0 5px 0; }

.FacetChosen_chosen__button__7LhsF {
  padding: 0;
  font-size: 0.8rem; }
  @media screen and (min-width: 1800px) {
    .FacetChosen_chosen__button__7LhsF {
      font-size: 0.9rem; } }

.contrast .FacetChosen_chosen__list-item__vbHb4:hover {
  background: #FFF500; }
  .contrast .FacetChosen_chosen__list-item__vbHb4:hover .FacetChosen_chosen__list-item--text__1NOv- {
    color: #0D0C0C; }

.Facet_facet__toggle__3uO6E {
  text-align: left;
  width: 100%;
  position: relative;
  font-size: 0.8rem;
  color: #29828a;
  border-top: 1px solid #dadada;
  letter-spacing: -0.1px;
  border-radius: 0;
  padding: 5px 0px; }
  @media screen and (min-width: 1800px) {
    .Facet_facet__toggle__3uO6E {
      font-size: 0.95rem; } }
  .Facet_facet__toggle__3uO6E:after {
    position: absolute;
    right: 8px;
    top: calc(50% - 3px); }
  .Facet_facet__toggle__3uO6E:hover, .Facet_facet__toggle__3uO6E:focus {
    box-shadow: 0px 2px 0px 0px #29828a;
    z-index: 9; }
  .Facet_facet__toggle__3uO6E[disabled] {
    pointer-events: none;
    opacity: 1;
    color: #999999; }
    .Facet_facet__toggle__3uO6E[disabled]:after {
      display: none; }
  .Facet_facet__toggle__3uO6E[aria-expanded="true"] {
    border-radius: 5px;
    font-weight: 600;
    background: #236b83;
    color: white;
    font-size: 0.9rem;
    padding: 7px 11px;
    letter-spacing: 0.3px;
    border-top: 1px solid transparent; }
    .Facet_facet__toggle__3uO6E[aria-expanded="true"]:after {
      right: 10px; }
    .Facet_facet__toggle__3uO6E[aria-expanded="true"]:hover, .Facet_facet__toggle__3uO6E[aria-expanded="true"]:focus {
      box-shadow: none; }

.Facet_facet__menu__3quaT {
  position: static !important;
  -webkit-transform: none !important;
          transform: none !important;
  float: none;
  padding: 0;
  margin: 3px 0;
  border-radius: 5px;
  border-color: #dadada; }

.Facet_facet__no-facet__mR5RB {
  padding: 8px 10px 7px;
  font-size: 0.83rem;
  line-height: 1rem;
  color: #4B4B4B; }

.contrast .Facet_facet__toggle__3uO6E {
  color: #FFF500;
  border-color: #333333; }
  .contrast .Facet_facet__toggle__3uO6E:hover, .contrast .Facet_facet__toggle__3uO6E:focus {
    box-shadow: 0px 2px 0px 0px #FFF500; }
  .contrast .Facet_facet__toggle__3uO6E[aria-expanded="true"] {
    background: #FFF500;
    color: #0D0C0C; }

.contrast .Facet_facet__no-facet__mR5RB {
  color: #fff; }

.FacetSearch_search-form__submit__3p6xW {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; }

.FacetSearch_search-form__input__SNg9O {
  border: none;
  border-bottom: solid 1px #A5A5A5;
  box-shadow: none;
  border-radius: 0;
  font-size: 0.83rem;
  line-height: 1rem;
  color: #4B4B4B;
  padding: 0; }
  .FacetSearch_search-form__input__SNg9O::-webkit-input-placeholder {
    color: #818A91;
    font-style: italic; }
  .FacetSearch_search-form__input__SNg9O::-moz-placeholder {
    color: #818A91;
    font-style: italic; }
  .FacetSearch_search-form__input__SNg9O:-moz-placeholder {
    color: #818A91;
    font-style: italic; }
  .FacetSearch_search-form__input__SNg9O:-ms-input-placeholder {
    color: #818A91;
    font-style: italic; }
  .FacetSearch_search-form__input__SNg9O:focus {
    border-color: #64A3AC; }
    .FacetSearch_search-form__input__SNg9O:focus + .FacetSearch_search-form__icon__1N7_i {
      border-color: #64A3AC; }

.FacetSearch_search-form__field__OjSRg {
  padding: 10px 10px 0 10px;
  display: flex; }

.FacetSearch_search-form__icon__1N7_i {
  border-bottom: solid 1px #A5A5A5;
  padding: 5px;
  line-height: 1rem;
  color: #22737B; }

.contrast .FacetSearch_search-form__input__SNg9O {
  background-color: #0C0B0B;
  border-color: #fff;
  color: #fff; }
  .contrast .FacetSearch_search-form__input__SNg9O::-webkit-input-placeholder {
    color: #fff; }
  .contrast .FacetSearch_search-form__input__SNg9O::-moz-placeholder {
    color: #fff; }
  .contrast .FacetSearch_search-form__input__SNg9O:-moz-placeholder {
    color: #fff; }
  .contrast .FacetSearch_search-form__input__SNg9O:-ms-input-placeholder {
    color: #fff; }
  .contrast .FacetSearch_search-form__input__SNg9O:focus {
    border-color: #FFF500; }
    .contrast .FacetSearch_search-form__input__SNg9O:focus + .FacetSearch_search-form__icon__1N7_i {
      border-color: #FFF500; }

.contrast .FacetSearch_search-form__icon__1N7_i {
  color: #FFF500;
  background-color: #0C0B0B;
  border-color: #fff; }

.Facets_facets__342TL {
  margin-top: 20px; }
  .Facets_facets__title__at-3l {
    display: flex;
    justify-content: space-between;
    align-items: baseline; }
  .Facets_facets__group__2FTdO {
    margin-top: 17px;
    border-bottom: 1px solid #dadada; }
  .Facets_facets__text--title__19Uho {
    font-weight: bold;
    font-size: 0.9rem; }
    @media screen and (min-width: 1800px) {
      .Facets_facets__text--title__19Uho {
        font-size: 1.1rem;
        letter-spacing: 0.1px; } }

.contrast .Facets_facets__group__2FTdO {
  border-color: #333333; }

.Sidebar_sidebar__1hfG2 {
  position: relative; }
  @media screen and (min-width: 992px) {
    .Sidebar_sidebar__1hfG2 {
      padding: 28px 35px 10px 0; } }
  @media screen and (min-width: 1800px) {
    .Sidebar_sidebar__1hfG2 {
      padding: 40px 35px 10px 0; } }
  .Sidebar_sidebar__title__wVkC1 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700; }
    @media screen and (max-width: 991.98px) {
      .Sidebar_sidebar__title__wVkC1 {
        margin: 0; } }
    .Sidebar_sidebar__title-container__2opXb {
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 991.98px) {
        .Sidebar_sidebar__title-container__2opXb {
          background-color: #ECEEEF;
          padding: 10px; } }
      @media screen and (max-width: 991.98px) {
        .Sidebar_sidebar__title-container--hidden__1_fgL {
          background-color: #296376; }
          .Sidebar_sidebar__title-container--hidden__1_fgL .Sidebar_sidebar-filters__title__Biz9o,
          .Sidebar_sidebar__title-container--hidden__1_fgL .Sidebar_sidebar-filters__catalogue-collapse__2KVGR {
            color: #FFFFFF; } }
  @media screen and (max-width: 991.98px) {
    .Sidebar_sidebar__catalogue-hidden__2Q-zp .Sidebar_sidebar-filters__catalogue-list__1wHkO {
      display: none; } }
  .Sidebar_sidebar__catalogue-top__3G1GI {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 991.98px) {
      .Sidebar_sidebar__catalogue-top__3G1GI {
        margin: 12px 10px 0; } }
    @media screen and (max-width: 991.98px) {
      .Sidebar_sidebar__catalogue-top--selected__3zLE7 {
        background-color: #ECEEEF;
        margin: 0;
        padding: 10px; } }
    @media screen and (max-width: 991.98px) {
      .Sidebar_sidebar__catalogue-top--selected__3zLE7 .Sidebar_sidebar-filters__catalogue-title__3wJZz {
        display: none; } }
    @media screen and (max-width: 991.98px) {
      .Sidebar_sidebar__catalogue-top--selected__3zLE7 .Sidebar_sidebar-filters__catalogue-selected__1ybAc {
        display: flex; } }
    @media screen and (max-width: 991.98px) {
      .Sidebar_sidebar__catalogue-top--clear__2cBWj {
        display: none; } }
  .Sidebar_sidebar__catalogue-collapse__18Qtz {
    display: none;
    padding: 0; }
    @media screen and (max-width: 991.98px) {
      .Sidebar_sidebar__catalogue-collapse__18Qtz {
        display: inline-block;
        font-size: 0.8rem; } }
    .Sidebar_sidebar__catalogue-collapse__18Qtz i:before {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .Sidebar_sidebar__catalogue-collapse--hidden__3Bwcs i:before {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
  .Sidebar_sidebar__catalogue-title__8tG1l {
    font-size: 0.9rem;
    font-weight: 700; }
  .Sidebar_sidebar__catalogue-selected__1MUdj {
    color: #565656;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 0.8rem;
    display: none; }
    .Sidebar_sidebar__catalogue-selected--span__1qINq {
      color: #4B4B4B;
      font-weight: 400; }
  .Sidebar_sidebar__catalogue-button__5Npc- {
    padding: 0;
    font-size: 0.8rem; }
  .Sidebar_sidebar__catalogue-list__2jvbZ {
    padding: 10px 0 50px;
    list-style: none; }
    @media screen and (max-width: 991.98px) {
      .Sidebar_sidebar__catalogue-list__2jvbZ {
        margin: 0 10px;
        padding-bottom: 25px; } }
  .Sidebar_sidebar__catalogue-item__3n3gG {
    color: #4B4B4B;
    padding-top: 10px; }
    .Sidebar_sidebar__catalogue-item--container__1K4Mo {
      display: flex;
      justify-content: space-between; }
    .Sidebar_sidebar__catalogue-item--label__dcxla {
      align-items: center;
      display: flex; }
    .Sidebar_sidebar__catalogue-item--text__315HH {
      font-size: 0.875rem;
      color: #22737B; }
      .Sidebar_sidebar__catalogue-item--text__315HH:hover {
        text-decoration: underline; }
    .Sidebar_sidebar__catalogue-item--count__1VQPA {
      color: #91999F;
      font-size: 0.9375rem; }
    .Sidebar_sidebar__catalogue-item--input__3Etns {
      width: 14px;
      height: 14px;
      margin-right: 10px; }
      .Sidebar_sidebar__catalogue-item--input__3Etns:checked + .Sidebar_sidebar-filters__catalogue-item--text__R789l {
        color: #4B4B4B; }

.contrast .Sidebar_sidebar__1hfG2 {
  color: #FFF; }

.error {
  padding: 100px 0 150px;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 991.98px) {
    .error {
      padding: 50px 0 100px; } }
  @media screen and (max-width: 575.98px) {
    .error {
      padding-left: 15px;
      padding-right: 15px; } }
  .error__header {
    font-size: 1.3rem;
    margin: 15px 0; }
  .error__code {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 20px; }
  .error__causes {
    margin-bottom: 10px; }
    .error__causes-list {
      font-size: 0.9rem; }
  .error__apologize {
    margin: 10px 0 5px;
    font-weight: 600; }
  .error__back, .error__back:hover, .error__back:focus {
    color: #22737B; }

.ImageLink_ImageLink__2b_rK:hover {
  opacity: 0.8; }

.ImageLink_ImageLink__2b_rK:focus {
  outline: 1px solid #818A91;
  outline-offset: 5px; }

.contrast .ImageLink_ImageLink__2b_rK:focus {
  outline: 1px solid #fff; }

.header__wrapper {
  position: fixed;
  width: 100%;
  z-index: 1; }
  .header__wrapper--sticky {
    z-index: 99; }

@media (max-width: 1024px) {
  .header__container {
    flex-wrap: wrap !important; } }

@media (min-width: 1025px) {
  .header__container {
    flex-wrap: nowrap !important;
    align-items: flex-start !important; } }

.header__sticky {
  box-shadow: 0 0 6px 0px #707070; }
  @media (max-width: 274px) {
    .header__sticky {
      margin-top: -65px; } }

.header__bar {
  background-color: #fff;
  padding: 20px 15px 15px; }
  @media (max-width: 991px) {
    .header__bar {
      padding: 20px 35px 20px 15px; } }
  @media (max-width: 480px) {
    .header__bar {
      padding: 15px 25px; } }
  .header__bar-logo {
    display: flex; }
    @media (max-width: 1024px) {
      .header__bar-logo {
        padding-bottom: 10px; } }
    @media screen and (min-width: 992px) and (max-width: 1199.98px) {
      .header__bar-logo {
        margin-top: 3px; } }
    .header__bar-logo--box-eu {
      position: relative;
      padding: 0 18px 0 0; }
      @media screen and (max-width: 575.98px) {
        .header__bar-logo--box-eu {
          padding: 0 8px 0 0; } }
      @media screen and (min-width: 1200px) {
        .header__bar-logo--box-eu {
          padding-right: 25px; } }
      @media screen and (min-width: 1920px) {
        .header__bar-logo--box-eu {
          padding-right: 30px; } }
      .header__bar-logo--box-eu::after {
        position: absolute;
        right: 0;
        top: 0;
        top: -3px;
        width: 1px;
        height: 100%;
        background: #cccccc;
        display: block;
        content: ""; }
    .header__bar-logo--box-uwr {
      position: relative;
      padding: 0 18px; }
      @media screen and (max-width: 575.98px) {
        .header__bar-logo--box-uwr {
          padding: 0 8px; } }
      @media screen and (min-width: 1200px) {
        .header__bar-logo--box-uwr {
          padding-right: 25px; } }
      @media screen and (min-width: 1920px) {
        .header__bar-logo--box-uwr {
          padding-right: 30px; } }
      .header__bar-logo--box-uwr::after {
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: #cccccc;
        display: block;
        content: ""; }
        @media screen and (min-width: 1200px) {
          .header__bar-logo--box-uwr::after {
            top: -3px; } }
    @media screen and (min-width: 1920px) {
      .header__bar-logo--box-leo {
        padding-left: 5px; } }
  .header__bar-logo--img-leo {
    position: relative;
    width: 120px;
    top: -1px; }
    @media screen and (min-width: 1200px) {
      .header__bar-logo--img-leo {
        width: 225px;
        top: -10px; } }
    @media screen and (min-width: 1920px) {
      .header__bar-logo--img-leo {
        top: -15px;
        width: 300px; } }
    @media screen and (max-width: 991.98px) {
      .header__bar-logo--img-leo {
        width: 160px;
        top: -10px;
        margin-bottom: -20px; } }
  .header__bar-logo--img-uwr {
    width: 75px;
    -webkit-filter: brightness(0);
            filter: brightness(0); }
    @media screen and (min-width: 1200px) {
      .header__bar-logo--img-uwr {
        width: 115px; } }
    @media screen and (min-width: 1920px) {
      .header__bar-logo--img-uwr {
        width: 150px; } }
  .header__bar-logo--img-eu {
    width: 60px; }
    @media screen and (min-width: 1200px) {
      .header__bar-logo--img-eu {
        width: 75px; } }
    @media screen and (min-width: 1920px) {
      .header__bar-logo--img-eu {
        width: 100px; } }
  .header__bar-search {
    flex: 2 1 auto; }
    @media (max-width: 1024px) {
      .header__bar-search {
        order: 1;
        flex: 0 1 100%; } }
    .header__bar-search.contrast__logo {
      display: none; }
    @media (min-width: 1025px) {
      .header__bar-search .mainPage {
        display: none; } }
  .header__bar .row {
    width: calc(100% + 30px); }
    @media (max-width: 991px) {
      .header__bar .row {
        width: calc(100% + 15px);
        margin: 0 auto; } }
  .header__bar .navbar-brand {
    padding: 0; }
  .header__bar .dropdown-toggle::after {
    color: #22737B; }
  .header__bar-menu {
    display: flex; }
    .header__bar-menu--hamburger {
      border: 0;
      padding: 0; }
      .header__bar-menu--hamburger:focus {
        outline: 0; }
      .header__bar-menu--hamburger span {
        width: 20px;
        height: 3px;
        background-color: #2C3343;
        margin: 4px 0;
        display: block; }
      .header__bar-menu--hamburger:not(.collapsed) {
        z-index: 1021;
        position: absolute;
        top: -30px;
        right: 20px;
        width: 25px;
        height: 25px; }
        .header__bar-menu--hamburger:not(.collapsed) span {
          background-color: #296376;
          width: 15px;
          height: 2px;
          position: absolute;
          right: 5px; }
          .header__bar-menu--hamburger:not(.collapsed) span:nth-of-type(1) {
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
            top: 8px; }
          .header__bar-menu--hamburger:not(.collapsed) span:nth-of-type(2) {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            margin: -6px 0;
            top: 18px; }
          .header__bar-menu--hamburger:not(.collapsed) span:nth-child(3) {
            display: none; }
    @media (max-width: 991px) {
      .header__bar-menu .navbar-collapse {
        position: absolute;
        width: calc( 100% - 20px);
        right: 0;
        top: -40px;
        height: 100vh;
        background: #FFF;
        z-index: 1020;
        -webkit-transition: none;
        transition: none; } }
    .header__bar-menu .navbar-collapse .navbar-nav {
      text-align: left; }
    .header__bar-menu .navbar-collapse .dropdown-menu {
      right: 0;
      left: auto;
      margin-top: 4px;
      min-width: 260px;
      max-height: calc(100vh - 97px);
      overflow-y: auto; }
      @media (max-width: 991px) {
        .header__bar-menu .navbar-collapse .dropdown-menu {
          border-radius: 0;
          margin: 0;
          border: 0;
          max-height: calc(100vh - 135px);
          overflow-y: auto; } }
      @media (max-width: 320px) {
        .header__bar-menu .navbar-collapse .dropdown-menu {
          max-height: calc(100vh - 150px);
          overflow-y: auto; } }
    @media (max-width: 991px) {
      .header__bar-menu .navbar-collapse .header__menu-item .nav-link {
        margin: 2px 0 0;
        padding: 9px 25px;
        background-color: #ECEEEF;
        border-radius: 0;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .header__bar-menu .navbar-collapse .header__menu-item .nav-link[aria-expanded="true"] {
          background-color: #236B83; } }

.header__menu-title {
  display: none; }
  @media (max-width: 991px) {
    .header__menu-title {
      display: block;
      padding: 15px 25px;
      color: #868686;
      font-size: 0.875rem;
      text-align: left; } }

.header__menu-item.nav-item .nav-link {
  color: #4B4B4B;
  line-height: 1.4375rem;
  font-size: 1rem;
  border-radius: 4px;
  padding: 6px 10px;
  margin-left: 5px; }
  .header__menu-item.nav-item .nav-link:after {
    margin-left: 8px;
    font-size: 0.875rem; }
  .header__menu-item.nav-item .nav-link:hover {
    color: #22737B; }

.header__menu-item.nav-item.show .nav-link {
  background-color: #236B83;
  color: #FFF; }
  .header__menu-item.nav-item.show .nav-link:after {
    color: #FFF; }

.header__menu-list--item.dropdown-item {
  color: #373A3C;
  font-size: 0.875rem; }
  @media (max-width: 991px) {
    .header__menu-list--item.dropdown-item {
      padding: 5px 40px;
      white-space: pre-wrap; } }
  .header__menu-list--item.dropdown-item:hover {
    background-color: #F5F5F5; }
  .header__menu-list--item.dropdown-item:active, .header__menu-list--item.dropdown-item.active {
    background-color: #E4F4F5; }
  .header__menu-list--item.dropdown-item.active {
    color: #296376; }

.header__top-bar {
  background-color: #236B83;
  padding: 6px 15px; }
  .header__top-bar .row {
    justify-content: flex-end;
    align-items: center; }
  .header__top-bar--link {
    color: #fff;
    text-decoration: underline;
    padding: 0;
    font-size: 0.875rem;
    line-height: 1.438rem; }
    @media (max-width: 991px) {
      .header__top-bar--link {
        font-size: 0.75rem; } }
    .header__top-bar--link:hover, .header__top-bar--link:focus {
      color: #fff; }
    .header__top-bar--link:focus {
      outline: 1px solid #fff;
      outline-offset: 2px; }

.header__lang {
  padding: 0;
  margin-left: 30px; }
  @media screen and (max-width: 575.98px) {
    .header__lang {
      margin-left: 15px; } }
  .header__lang-current.nav-item .nav-link {
    color: #fff;
    padding: 4px 9px;
    font-size: 0.875rem;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 4px; }
    @media (max-width: 991px) {
      .header__lang-current.nav-item .nav-link {
        font-size: 0.75rem; } }
    .header__lang-current.nav-item .nav-link:hover, .header__lang-current.nav-item .nav-link:focus {
      border-color: #fff;
      color: #fff;
      outline: none; }
  .header__lang-current.nav-item.show .nav-link {
    background: #fff;
    color: #373A3C;
    border-color: #fff; }
  .header__lang-current .dropdown-toggle::after {
    margin-left: 14px; }
    @media (max-width: 991px) {
      .header__lang-current .dropdown-toggle::after {
        margin-left: 10px; } }
  .header__lang-menu .collapse {
    display: flex; }
  .header__lang-menu .dropdown-menu {
    padding: 4px 0;
    min-width: 100%;
    text-transform: uppercase;
    position: absolute;
    margin-top: 6px; }
    .header__lang-menu .dropdown-menu .dropdown-item {
      padding: 5px 10px;
      font-size: 0.875rem;
      text-align: right; }
      @media (max-width: 991px) {
        .header__lang-menu .dropdown-menu .dropdown-item {
          font-size: 0.75rem; } }
      .header__lang-menu .dropdown-menu .dropdown-item:active {
        background: #E4F4F5;
        color: #373A3C; }
  .header__lang-item:last-child {
    margin-top: 2px; }
  .header__lang-item.active {
    color: #296376;
    background-color: #E4F4F5; }
  .header__lang-item--icon {
    display: none; }
    .header__lang-item--icon::before {
      margin-left: 0;
      margin-right: 5px; }
  .header__lang-item--current {
    background-color: #E4F4F5;
    color: #296376;
    pointer-events: none; }
    .header__lang-item--current .header__lang-item--icon {
      display: inline; }

.header__access {
  margin-left: 30px;
  display: flex;
  align-items: baseline; }
  @media screen and (max-width: 575.98px) {
    .header__access {
      margin-left: 15px; } }

.header__font-btn-span, .header__contrast-btn-span {
  display: none; }

.header__contrast-btn {
  padding: 0;
  background: transparent;
  border: none;
  font-size: 1.04rem; }
  .header__contrast-btn:hover, .header__contrast-btn:focus, .header__contrast-btn.btn-primary:not(:disabled):not(.disabled):active {
    background: transparent; }
  .header__contrast-btn .header__contrast-normal {
    display: block; }
  .header__contrast-btn .header__contrast-hover,
  .header__contrast-btn .header__contrast-active {
    display: none; }
  .header__contrast-btn:hover {
    opacity: 0.8; }
  .header__contrast-btn:focus {
    outline: 1px solid #fff;
    outline-offset: 0; }

.header__font-btn {
  padding: 0;
  border: none;
  font-size: 1.04rem;
  margin-right: 10px; }
  .header__font-btn:hover, .header__font-btn:focus, .header__font-btn.btn-primary:not(:disabled):not(.disabled):active {
    background: transparent;
    text-decoration: none; }
  .header__font-btn:hover {
    opacity: 0.8; }
  .header__font-btn:focus {
    outline: 1px solid #fff;
    outline-offset: 4px; }

.header__font-normal {
  display: flex; }

.header__font-medium, .header__font-big, .header__font-hover {
  display: none; }

.footer__partners {
  background-color: #fff;
  padding: 65px 0 70px;
  border-top: 1px solid #EAEAEA; }
  .footer__partners .row {
    flex-direction: column; }
  .footer__partners-text {
    text-align: center;
    margin: 0 auto;
    max-width: 800px;
    padding-bottom: 25px;
    font-size: 1rem;
    line-height: 1.25rem; }
    @media (max-width: 1366px) {
      .footer__partners-text {
        font-size: 0.75rem;
        line-height: 1.125rem; } }
    @media (max-width: 575px) {
      .footer__partners-text {
        padding: 0 15px 25px 15px; } }
  .footer__partners-logos, .footer__partners-logos--contrast {
    margin: 0 auto;
    display: flex;
    align-items: center; }
    @media (max-width: 375px) {
      .footer__partners-logos, .footer__partners-logos--contrast {
        flex-direction: column; } }
    .footer__partners-logos a, .footer__partners-logos--contrast a {
      margin: 10px 25px 0; }
      @media (min-width: 376px) {
        .footer__partners-logos a:first-child, .footer__partners-logos--contrast a:first-child {
          margin-left: 0; }
        .footer__partners-logos a:last-child, .footer__partners-logos--contrast a:last-child {
          margin-right: 0; } }
      @media (max-width: 767px) {
        .footer__partners-logos a, .footer__partners-logos--contrast a {
          margin: 10px 15px 0; } }
      @media (max-width: 480px) {
        .footer__partners-logos a, .footer__partners-logos--contrast a {
          margin: 10px 5px 0; } }
      @media (max-width: 375px) {
        .footer__partners-logos a, .footer__partners-logos--contrast a {
          margin: 10px auto; } }
      @media (max-width: 480px) {
        .footer__partners-logos a img, .footer__partners-logos--contrast a img {
          height: 35px; } }
    .footer__partners-logos--contrast {
      display: none; }

.footer__bottom-bar {
  background-color: #EAEAEA;
  font-size: 0.75rem; }
  @media (min-width: 992px) {
    .footer__bottom-bar--text {
      margin-right: 15px;
      padding: 0; } }
  @media (min-width: 1025px) {
    .footer__bottom-bar--text {
      margin-right: 20px; } }
  .footer__bottom-bar--link {
    padding: 0;
    font-size: 0.75rem;
    color: #22737B; }
    @media (min-width: 992px) {
      .footer__bottom-bar--link {
        margin-right: 15px; } }
    @media (min-width: 1025px) {
      .footer__bottom-bar--link {
        margin-right: 20px; } }
    .footer__bottom-bar--link:hover, .footer__bottom-bar--link:focus {
      color: #093539;
      outline: none; }
    .footer__bottom-bar--link:focus {
      outline: 1px solid #093539;
      outline-offset: 2px;
      box-shadow: none; }
  .footer__bottom-bar .row {
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991px) {
      .footer__bottom-bar .row {
        height: 160px;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center; } }
  .footer__bottom-bar--container {
    display: flex;
    height: 35%; }
    @media (max-width: 991px) {
      .footer__bottom-bar--container {
        flex-direction: column; } }
    .footer__bottom-bar--container .btn-link-external {
      line-height: 1rem; }
    .footer__bottom-bar--container-right {
      height: 35%; }
      @media (max-width: 991px) {
        .footer__bottom-bar--container-right {
          max-width: 220px;
          margin: 0 auto; } }
      @media (min-width: 992px) {
        .footer__bottom-bar--container-right {
          padding-top: 0; } }
  .footer__bottom-bar--author-full {
    display: none;
    padding-bottom: 2px; }
    @media (min-width: 1201px) {
      .footer__bottom-bar--author-full {
        display: inline-block; } }
  .footer__bottom-bar--author-small {
    display: none;
    padding-bottom: 2px; }
    @media (max-width: 1200px) {
      .footer__bottom-bar--author-small {
        display: inline-block; } }
  .footer__bottom-bar--author .btn-link-external {
    margin-left: 2px; }
    .footer__bottom-bar--author .btn-link-external:hover span {
      text-decoration: underline; }

.cookies {
  background: #1D1B19;
  color: #FFFFFF;
  padding: 12px 15px;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0; }
  @media (max-width: 1366px) {
    .cookies {
      padding: 10px 15px 9px; } }
  @media (max-width: 480px) {
    .cookies {
      padding: 15px 25px 14px; } }
  .cookies .row {
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991px) {
      .cookies .row {
        flex-direction: column;
        align-items: normal; } }
  .cookies__left {
    text-align: left;
    flex: 1 1; }
    .cookies__left-icon {
      font-size: 1.3rem;
      display: inline-flex; }
      .cookies__left-icon:before {
        line-height: 1.4rem; }
    .cookies__left-text {
      font-size: 0.875rem;
      line-height: 1.5rem; }
      @media (max-width: 1366px) {
        .cookies__left-text {
          font-size: 0.75rem; } }
      @media (max-width: 991px) {
        .cookies__left-text {
          line-height: 1.125rem; } }
    .cookies__left-link {
      color: #FFFFFF;
      text-decoration: underline; }
      .cookies__left-link:hover {
        color: #FFFFFF; }
      .cookies__left-link:focus {
        outline: 1px solid #FFFFFF;
        outline-offset: 2px; }
  .cookies__btn {
    align-self: center;
    padding: 7px 25px 7px;
    font-size: 0.875rem; }
    @media (max-width: 1366px) {
      .cookies__btn {
        font-size: 0.75rem; } }
    @media (max-width: 991px) {
      .cookies__btn {
        align-self: flex-end;
        margin-top: 5px;
        padding: 4px 18px 6px; } }
    .cookies__btn:focus {
      border-color: white;
      color: white; }

.App_app__3KUk4 {
  height: 100%;
  min-height: 100vh; }
  .App_app__container__2_SaP {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden; }
  .App_app__main__2WU28 {
    flex: 1 1;
    margin-top: 200px;
    display: flex;
    flex-direction: column; }
    @media (min-width: 1024px) {
      .App_app__main__2WU28 {
        margin-top: 170px; } }

.font-medium .App_app__main__2WU28 {
  margin-top: 210px; }
  @media (min-width: 1024px) {
    .font-medium .App_app__main__2WU28 {
      margin-top: 185px; } }

.font-big .App_app__main__2WU28 {
  margin-top: 210px; }
  @media (min-width: 1024px) {
    .font-big .App_app__main__2WU28 {
      margin-top: 195px; } }

