.tt {
    &.show {
        opacity: 1; }
    &.type-info {
        background: white;
        color: black;
        border: 1px solid #d6d6d6;
        border-radius: 0;
        font-size: 0.75rem;
        line-height: 0.9rem;
        max-width: 430px;
        box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.15);

        &.place-bottom:after {
            border-bottom-color: white; }

        &.place-bottom:before {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            top: -7px;
            left: 50%;
            margin-left: -8px;
            border-bottom-color: #d6d6d6;
            border-bottom-style: solid;
            border-bottom-width: 6px; }

        &.place-top:after {
            border-top-color: white; }

        &.place-top:before {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            bottom: -7px;
            left: 50%;
            margin-left: -8px;
            border-top-color: #d6d6d6;
            border-top-style: solid;
            border-top-width: 6px; }

        &.place-right:after {
            border-right-color: white; }

        &.place-right:before {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            left: -7px;
            top: 50%;
            margin-top: -4px;
            border-right-color: #d6d6d6;
            border-right-style: solid;
            border-right-width: 6px; } } }
