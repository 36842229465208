@import "@/styles/_breakpoints.sass";

.page {
    text-align: left;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    &__no-results {
      padding: 25px; }

    &__main {
      flex: 1; }

    &__title {
        font-size: 1.25rem;
        color: #4B4B4B;
        margin: 5px 0 0;
        @include mobile-small {
            padding: 0 15px; } }

    &__line {
        margin: 25px 0 0;
        border-bottom: 1px solid #EAEAEA; }

    &__content {
        display: flex;
        padding-bottom: 8px;
        flex: 1 1;
        min-height: 600px;
        @include touch {
            flex-direction: column; }
        i {
          font-size: 1.2rem; }
        &[aria-current="page"] {
          color: #4B4B4B;
          font-weight: 700;
          font-size: 0.875rem;
          pointer-events: none;
          i {
            display: inline-block; } } }
    &-content {

      padding: 0 0 40px;
      color: #4B4B4B;
      flex: 1 1; } }
.collapseMenu {
  @include touch {
    display: none; }
  &-active {
    i:before {
      transform: rotate(0deg); } } }


 :global(.contrast) {
  .page {
    &__title {
      color: #FFF; }
    &__no-results {
      color: #FFF; } } }
