@import "@/styles/_breakpoints.sass";

@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content; } } }

@mixin round-border($val) {
  border-radius: $val; }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); } //IE8

@mixin list-column($count, $gap, $column-rule) {
  -moz-column-count: $count;
  -moz-column-gap: $gap;
  -webkit-column-count: $count;
  -webkit-column-gap: $gap;
  column-count: $count;
  column-gap: $gap;
  -webkit-column-rule: $column-rule;
  -moz-column-rule: $column-rule;
  column-rule: $column-rule; }

@mixin columns-avoid {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  column-fill: balance;
  -moz-column-fill: balance;
  -webkit-column-fill: balance; }

@mixin linear-gradient($color1, $color2) {
  background: -webkit-linear-gradient(top, $color1 22%, $color2 100%);
  background: -o-linear-gradient(top, $color1 22%, $color2 100%);
  background: -ms-linear-gradient(top, $color1 22%, $color2 100%);
  background: -moz-linear-gradient(top, $color1 22%, $color2 100%);
  background: linear-gradient(to bottom, $color1 22%, $color2 100%); }

@mixin text-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word; }

@mixin absolute-center {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

@mixin transition-all {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in; }

@mixin custom-background($color) {
  position: relative;
  background: $color;
  &::after {
    content: '';
    display: block;
    background: $color;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    z-index: -2;
    margin-right: -100%;
    top: 0;
    @include touch-sec {
      width: 200%;
      margin-right: -50%; } } }

@mixin scrollable-menu($px-value) {
  max-height: calc(100vh - #{$px-value});
  overflow-y: auto; }
