@import "@/styles/_breakpoints.sass";

.app {
    height: 100%;
    min-height: 100vh;
    &__container {
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        overflow-x: hidden; }

    &__main {
        flex: 1;
        margin-top: 200px;
        display: flex;
        flex-direction: column;
        @media (min-width: 1024px) {
            margin-top: 170px; } } }

 :global(.font-medium) {
    .app {
        &__main {
            margin-top: 210px;
            @media (min-width: 1024px) {
                margin-top: 185px; } } } }

 :global(.font-big) {
    .app {
        &__main {
            margin-top: 210px;
            @media (min-width: 1024px) {
                margin-top: 195px; } } } }
