@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.dropdown {
    position: relative;

    .btn-regular {
        padding: 5px 0 5px 0;
        border-bottom: solid 1px #A5A5A5;
        border-radius: 0;
        font-size: 0.875rem;
        color: #515A5D;

        &:focus {
            outline: 0;
            box-shadow: none; }

        &:after {
            color: #22737B;
            margin-left: 40px; }

        & + .dropdown-menu {
            max-height: 270px;
            overflow: auto;
            font-size: 0.875rem;
            min-width: 150px;

            .dropdown-item {
                color: #373A3C;

                &:hover,
                &:focus {
                    background: #F5F5F5; } } } }

    &.show {
        .btn-regular {
            background: #236B83;
            color: #fff;
            border: solid 1px #236B83;
            border-radius: 4px;
            padding-left: 5px;
            padding-right: 5px;

            &:after {
                color: #fff;
                margin-left: 40px; } } } }
