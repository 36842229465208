@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.sidebar-info {
    position: relative;
    padding: 25px 0;
    @include desktop {
        padding: 28px 35px 10px 0; }
    @include fullhd {
        padding: 40px 35px 10px 0; }

    &:after {
        @include desktop {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: -15px;
            width: 100vw;
            height: 100%;
            pointer-events: none;
            border-bottom: 1px solid #fafafa; } }

    &__showmap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: rgb(255, 255, 255);
        overflow: auto;
        outline: none;
        z-index: 999;

        @include desktop {
            border: 1px solid rgb(204, 204, 204);
            top: 20px;
            left: 20px;
            right: 20px;
            bottom: 20px;
            padding: 10px;
            border-radius: 4px; }

        @include hd {
            top: 40px;
            left: 40px;
            right: 40px;
            bottom: 40px;
            padding: 20px; } }

    &__text {
        &--title {
            font-weight: bold;
            margin: 0;
            font-size: 0.9rem; }
        @include fullhd {
            font-size: 1.25rem; } }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    &__actions {
        list-style-type: none;
        margin: 0 -7px;
        padding: 0;
        display: flex;
        line-height: 1.3rem;

        @include fullhd {
            margin: 0 -10px; }

        &--item {
            padding: 0 7px;
            position: relative;
            @include fullhd {
                padding: 0 10px; }

            &:not(:last-child) {
                &:after {
                    content: "|";
                    position: absolute;
                    right: -2px;
                    top: 50%;
                    color: #B2B2B2;
                    line-height: 0; }
                @include fullhd {
                    right: -3px; } } }

        &--button {
            padding: 0;
            font-size: 0.8rem;
            color: #22737B;
            @include fullhd {
                font-size: 0.9rem; }
            &:hover {
                color: #22737B; } } }

    &__block {
        margin: 10px 0 23px;

        &:first-child {
            margin-bottom: 35px; }

        &--link {
            padding: 0;
            text-align: left;
            font-size: 0.8rem;
            @include fullhd {
                font-size: 0.93rem; }

            &[target="_blank"] {
                i {
                    position: relative;
                    top: -5px; } } } }

    &__textbox {
        margin: 0;
        line-height: 1.8rem;
        font-size: 0.8rem;
        @include fullhd {
            font-size: 0.93rem; }

        &--query {
            margin-left: 5px;

            [data-tip] {
                position: relative;
                top: -5px; } }

        &--item {
            display: flex;
            align-items: baseline; }

        &--type {
            margin-right: 5px; }

        &--conj {
            background: rgba(#000, 0.035);
            min-width: 1.5em;
            text-align: center;
            display: inline-block;
            margin-right: 5px;
            padding: 0 5px;
            border: 1px solid #fff; }

        &--era {
            margin-left: 3px; } } }

 :global(.contrast) {
    .sidebar-info {
        color: #FFF;
        &::after {
            border-color: #333; }
        &__block--link {
            color: #FFF500; }
        &__actions--button {
            color: #FFF500; }
        &__textbox i {
            color: #FFF500; }

        &__showmap {
            background: #000000;
            border-color: #FFF000; } } }
