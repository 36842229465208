.ImageLink {
    &:hover {
        opacity: 0.8; }

    &:focus {
        outline: 1px solid #818A91;
        outline-offset: 5px; } }

 :global(.contrast) {
    .ImageLink {
        &:focus {
            outline: 1px solid #fff; } } }
