.app {
    text-align: center;
    flex: 1;
    @media (min-width: 1025px) {
        margin-top: -123px; }
    @media (min-width: 1200px) {
        margin-top: -41px; }

    &__container {
        height: 100%;
        display: flex;
        flex-direction: column; } }
