@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.form {
    &__head {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 8px 10px 7px; }

    &__content {
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        padding: 1px 10px;
        overflow-y: auto;
        max-height: 200px; }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        &-item {
            display: flex;
            margin: 11px 0;

            &:hover {
                .form__text--value {
                    text-decoration: underline; } }

            &--label {
                flex: 1 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                cursor: pointer;
                width: 90%;
                align-items: baseline;

                input[value="true"] + .form__text--value {}
                color: #4B4B4B; } } }

    &__bottom {
        padding: 9px 10px 12px;
        display: flex;
        justify-content: flex-end;
        align-items: center; }

    &__submit {
        padding: 3px 17px;
        font-size: 0.8rem;
        @include fullhd {
            font-size: 0.85rem; } }

    &__text {
        &--value {
            color: #22737B;
            font-size: 0.8rem;
            word-break: break-all;
            flex: 1;
            @include fullhd {
                font-size: 0.9rem; } }

        &--count {
            color: #686F78;
            white-space: nowrap;
            font-size: 0.8rem;
            margin-left: 5px;
            @include fullhd {
                font-size: 0.9rem;
                padding-right: 20px; } } } }

 :global(.contrast) {
    .form {
        &__text {
            &--value {
                color: #FFF500; }
            &--count {
                color: #FFF; } } } }
