.Checkbox {
    &.checkbox {
        &--europeana {
            top: -4px;
            width: 18px;
            height: 18px;
            margin-right: 5px;

            svg {
                transform: scale(0.85); }

            .square {
                stroke: #54A7AE;
                stroke-width: 1px;
                fill: rgba(29, 27, 25, 0.6); }

            &.unchecked {
                .square {
                    fill-opacity: 1; } }

            &.checked, &.checked:hover {
                .square {
                    stroke: #22737B;
                    fill: #3A979F; } }

            &:hover {
                .square {
                    stroke: #71CDD5;
                    fill: rgba(58, 151, 159, 0.47); } }

            .tick {
                stroke-width: 2px;
                d: path("M 7 7 v 7 h 12"); } }

        &--facet {
            top: -3px;
            width: 18px;
            height: 18px;
            margin-right: 5px;

            svg {
                transform: scale(0.65); }

            .square {
                stroke: #dddddd;
                stroke-width: 1px;
                fill: #f4f4f4; }

            &.unchecked {
                .square {
                    fill-opacity: 1; } }

            &.checked, &.checked:hover {
                .square {
                    stroke: #22737B;
                    fill: #22737B; } }

            &:hover {
                .square {
                    stroke: #22737B;
                    fill: #e4f4f5; } }

            .tick {
                stroke-width: 2px;
                d: path("M 7 7 v 7 h 12"); } }

        &--light {
            top: -5px;
            left: -3px;
            width: 18px;
            height: 18px;
            margin-right: 10px;

            svg {
                transform: scale(0.85); }

            .square {
                stroke: #54A7AE;
                stroke-width: 1px;
                fill: #F5F5F5; }

            &.unchecked {
                .square {
                    fill-opacity: 1; } }

            &.checked, &.checked:hover {
                .square {
                    stroke: #22737B;
                    fill: #3A979F; } }

            &:hover, &:focus {
                .square {
                    stroke: #71CDD5;
                    fill: rgba(58, 151, 159, 0.47); } }

            .tick {
                stroke-width: 2px;
                d: path("M 7 7 v 7 h 12"); } } } }
