@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.app {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  &__title {
    font-size: 1.25rem;
    color: #4B4B4B;
    margin: 5px 0 0;
    @include mobile-small {
      padding: 0 15px; } }
  &__line {
    border-top: 1px solid #EAEAEA;
    margin-top: 25px; }
  &__container {
    @include touch-sec {
      flex-direction: column; } }
  &__content {
    display: flex;
    flex: 1 1;
    @include touch-sec {
      flex-direction: column; } }
  &__menu {
    background-color: #FFF;
    border-right: 1px solid #EAEAEA;
    flex: 0 0 395px;
    padding: 30px 15px 30px 0;
    @include touch-sec {
      border-right: 0;
      flex: 1; }
    @include mobile-small {
      padding: 30px 15px; }
    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      @include touch-sec {
        margin-left: 10px; }
      &--top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 25px;
        @include touch-sec {
          padding: 10px;
          background-color: #ECEEEF;
          align-items: center; } }
      &--title {
        font-size: 1rem;
        font-weight: 700;
        margin: 0; }
      &--btn {
        padding: 0;
        line-height: initial;
        display: inline-block;
        @include desktop {
          display: none; }
        i {
          font-size: 1.2rem;
          line-height: 1rem;
          &:before {
            transform: rotate(180deg); } }
        &:focus,
        &:hover {
          text-decoration: none;
          color: #22737B; } }
      &--link {
        color: #22737B;
        font-size: 0.9375rem;
        line-height: 2rem;
        width: 100%;
        display: flex;
        i {
          font-size: 1.2rem;
          display: none; }
        &:hover {
          color: #22737B; }
        &:active {
          color: #4D4D4D; }
        &[aria-current="page"] {
          color: #4B4B4B;
          font-weight: 700;
          font-size: 0.875rem;
          pointer-events: none;
          i {
            display: inline-block; } } } } }

  &__article {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 1rem;
      padding: 20px 0 20px 70px;
      font-weight: 700;
      margin: 0;
      @include custom-background(#EAEAEA);
      @include touch-sec {
        padding-left: 0; }
      @include mobile-small {
        padding: 25px 15px; } }

    &-content {
      padding: 0 0 40px;
      margin-top: 2px;
      color: #4B4B4B;
      flex: 1 1;
      @include custom-background(#F5F5F5);

      p, h1, h2, h3, h4, h5, h6, pre {
        margin: 0;
        position: relative;
        padding: 40px 0 0 70px;
        @include touch-sec {
          padding-left: 0; }
        @include mobile-small {
          padding: 40px 15px 0; } }

      h1, h2, h3, h4, h5, h6 {
        font-size: 1rem;
        font-weight: 700; }
      p {
        font-size: 0.8125rem;
        line-height: 1.625rem; }
      pre {
        font-size: 0.9375rem;
        text-transform: uppercase;
        font-family: inherit;
        font-weight: 700;
        letter-spacing: 0.5px; }
      blockquote {
        background-color: #EAEAEA;
        margin: 20px 0 0;
        @include custom-background(#EAEAEA);
        &::after {
          z-index: -1; }
        p {
          padding-top: 30px;
          padding-bottom: 20px; } }

      a {
        color: #004FA3; } } }

  &__table {
    @include custom-background(#F5F5F5);

    &-title {
      font-size: 1.125rem;
      font-weight: 700;
      padding: 25px 0 25px 70px;
      margin: 0;
      background-color: #F5F5F5;
      color: #4B4B4B;
      @include touch-sec {
        padding-left: 0; }
      @include mobile-small {
        padding: 25px 15px; } }
    &-header {
      padding: 15px 0 15px 70px;
      display: flex;
      @include touch-sec {
        display: none; }
      @include custom-background(#EAEAEA);
      &::after {
        z-index: -1; }

      &--column {
        flex: 1;
        padding-right: 15px;
        font-size: 0.875rem; } }
    &-content {
      background-color: #F5F5F5;
      list-style: none;
      padding: 15px 0 15px 70px;
      margin: 0;
      @include touch-sec {
        padding-left: 0; }
      @include mobile-small {
        padding: 15px; }
      @include custom-background(#F5F5F5);
      &--portal {
        text-align: left; }
      &--item {
        display: flex;
        margin-bottom: 20px;
        z-index: 0;
        @include touch-sec {
          flex-direction: column;
          margin-bottom: 0;
          padding: 20px 0 25px;
          position: relative;
          &:nth-child(odd) {
            @include custom-background(#EAEAEA);
            &::after {
              z-index: -1; } } } }
      &--column {
        flex: 1;
        padding-right: 15px;
        font-size: 0.875rem;
        @include touch-sec {
          margin-bottom: 20px; }
        &:last-child {
          margin-bottom: 0; }
        &-text {
          display: none;
          font-size: 0.8125rem;
          font-weight: 700;
          @include touch-sec {
            display: flex;
            margin-bottom: 5px; } } }
      &--link {
        padding: 5px 13px;
        background-color: #236B83;
        color: #FFF;
        border-radius: 3px;
        font-size: 0.875rem;
        display: inline-flex;

        &:hover,
        &:focus {
          color: #FFF;
          background-color: #185367;
          text-decoration: none; }
        &:active {
          background-color: #0E2E39; } }

      &--portal {
        font-size: 0.875rem; } } } }
.collapseMenu {
  @media (max-width: 991px) {
    display: none; }
  &-active {
    i:before {
      transform: rotate(0deg); } } }


 :global(.contrast) {
  .app {
    &__title {
      color: #FFF; }
    &__menu {
      background-color: #0D0C0C;
      border-color: #333333;
      &-list {
        &--title {
          color: #FFF; }
        &--link {
          color: #FFF500; }
        &--top {
          @include touch-sec {
            background-color: #0D0C0C;
            border: 1px solid #FFF500; } } } }
    &__article {
      background-color: #333333;
      &-title {
        background-color: #0D0C0C;
        border-top: 1px solid #333333;
        color: #FFF;
        &::after {
          background-color: #0D0C0C;
          border-top: 1px solid #333333; } }
      &-content {
        background-color: #0D0C0C;
        color: #FFF;
        &::after {
          background-color: #0D0C0C; }
        a {
          color: #FFF500; }
        blockquote {
          background-color: #0D0C0C;
          &::after {
            background-color: #0D0C0C; } }
        h1, h2, h3, h4, h5, h6, pre {
          color: #FFF; } } }

    &__table {
      &::after {
        background-color: #0D0C0C; }
      &-title {
        background: #0D0C0C;
        color: #FFF; }
      &-header {
        background: #0D0C0C;
        color: #FFF;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #333333;
        &::after {
          background: #0D0C0C;
          border-top: 1px solid #333333;
          border-bottom: 1px solid #333333; } }
      &-content {
        background: #0D0C0C;
        color: #FFF;
        a {
          color: #FFF500; }
        &--link {
          background-color: #0D0C0C;
          border: 1px solid #FFF500;
          &:hover,
          &:focus {
            color: #0D0C0C;
            background-color: #FFF500; } }
        &--item {
          &:nth-child(odd)::after {
            border-top: 1px solid #333333;
            border-bottom: 1px solid #333333;
            background: #0D0C0C; } } } } } }
