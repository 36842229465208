@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

$bg-img: url('../../assets/images/search/bg.png');

.search {
    background-image: $bg-img;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-color: #161616;
    position: relative;
    height: 565px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include touch {
        height: 350px; }
    @include widescreen {
        height: 405px; }
    @include hd {
        height: 565px; }

    &__container {
        @include touch {
            padding: 0;
            margin-top: 6px;
            max-width: none; }
        @include widescreen {
            max-width: 800px; }
        @include hd {
            max-width: 1055px; } }

    &__row {
        @include touch {
            margin-left: 0;
            margin-right: 0; } }

    &__col {
        @include touch {
            padding: 0; } }

    &__header {
        margin-bottom: 21px; }

    &__form-wrapper, &__form-wrapper-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 22.5px;
        @media(max-width: 1024px) {
            display: none; }
        &.header {
            position: relative;
            margin-top: 10px;
            @media(max-width: 1199px) {
                top: 15px; }
            @media(max-width: 1024px) {
                display: flex; }

            .search {
                &__advsearch__link {
                    font-size: 0.8rem;
                    line-height: 1.5rem; } }

            &:first-of-type {
                @media (min-width: 1024px) {
                    margin-top: 0; } } }

        @media(max-width: 1024px) {
            display: none; } }

    &__inputrow {
        @media(max-width: 1024px) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin: 0; }

        @include desktop {
            margin-top: 1rem;
            margin-bottom: 1rem; } }

    &__inputgroup {
        display: flex;
        flex-direction: column;
        position: static;

        @include desktop-only {
            position: relative;
            top: -16px; } }

    &__form {
        .search__button {
            z-index: 0;
            background: #326b82;
            color: white;
            border-color: #71a6ae;
            border-radius: 0px;
            &:disabled {
                background-color: #ECEEEF;
                border-color: #DDDDDD;
                color: #828a91; }

            &:hover {
                background: #245366;
                color: white;
                border-color: #52858e; } }
        &.header {
            padding: 0px 20px;
            @media screen and (max-width: 1024px) {
                padding: 0px; } }

        &:focus-within {
            .search__form-wrapper {
                outline: 1px solid #64A3AC; }

            .search__input > input {
                border: 1px solid #64A3AC !important; }

            .search__button {
                background: #236B83;
                color: #fff;
                border-color: #64A3AC; } } }

    &__button {
        @include touch {
            border-radius: 0; }
        @include desktop {
            width: 78px; }
        @include widescreen {
            width: 68px; }
        @include hd {
            width: 78px; }

        &:focus {
            border: 1px solid #dfe1e1;
            box-shadow: none; }

        &:hover {
            background: #245366;
            color: white;
            border-color: #52858e; }

        &:disabled {
            pointer-events: none;
            opacity: 1; }

        &.header {
            width: 50px; }

        span {
            display: none; } }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: -5px;
        z-index: 0;
        height: 60px;

        &:before {
            content: "";
            display: block;
            border-radius: 30px;
            width: 180px;
            height: 60px;
            background: rgba(#1D1B19,0.7);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 1; }


        &:after {
            content: "";
            display: block;
            width: 190px;
            height: 70px;
            border-radius: 35px;
            background-image: $bg-img;
            background-repeat: no-repeat;
            background-size: auto;
            background-position: center -155px;
            position: absolute;
            z-index: 0; }


        img {
            width: 116px;
            z-index: 2;
            filter: brightness(0) invert(1); } }

    &__motto {
        background: rgba(#1D1B19,0.7);
        color: white;
        font-size: 1.25rem;
        padding: 17px 15px 16px;
        @include touch {
            font-size: 0.95rem;
            padding: 21px 15px 13px;
            line-height: 1.3;
            span {
                max-width: 320px; } }
        @include widescreen {
            font-size: 1rem;
            padding: 12px 15px 13px; }
        @include hd {
            font-size: 1.25rem;
            padding: 17px 15px 16px; } }

    &__input {
        height: 100%;
        flex: 1;
        height: 54px;
        @include touch {
            height: 45px; }
        @include widescreen {
            height: 46px; }
        @include hd {
            height: 58px; }
        &.header {
            height: 40px; } }

    &__advsearch {
        &__link {
            border: 0;
            font-size: 0.9rem;
            outline: 0;
            color: #71CDD5;
            background: rgba(28, 28, 28, 0.52);
            float: right;
            white-space: nowrap;
            padding: 0 5px;
            &:hover {
                color: #71CDD5;
                span {
                    text-decoration: underline; } }
            &:focus {
                color: #71CDD5;
                span {
                    text-decoration: underline; } }
            &.header {
                background-color: transparent;
                span {
                    color: #22737B; }
                i {
                    color: #22737B; }
                &:active {
                    color: #4B4B4B;
                    span {
                        color: #4B4B4B; }
                    i {
                        color: #4B4B4B; } } }

            &:active {
                color: white; }

            @include hd {
                font-size: 1rem; }

            i {
                position: relative;
                top: -3px; } } }

    &__europeana {
        color: white;
        background: rgba(28, 28, 28, 0.52);
        display: flex;
        align-items: center;

        &.header {
            color: #212529;
            background: transparent;
            line-height: 1.5rem;
            padding-left: 0;

            .search {
                &__checkbox {
                    margin-right: 0;
                    top: -5px; } }

            label {
                font-size: 0.8rem; } }

        label {
            margin-bottom: 0;
            padding: 0 5px;
            font-size: 0.9rem;
            white-space: nowrap;
            cursor: pointer;

            &:hover, &:focus {
                text-decoration: underline; }

            @include hd {
                font-size: 1rem;
 } }                // margin-left: 5px
        span {
            margin-left: 5px; }
        i {
            color: #71CDD5;
            position: relative;
            top: -3px; }

        svg {
            transform: scale(0.65);
            @include hd {
                transform: scale(0.85); } }

        &__label {
            &--span {
                display: none; } } } }

 :global(.contrast) {
    .search {
        background-blend-mode: difference;
        &__motto, {
            background: #1D1B19; }
        &__logo {
            &::before {
                background: #1D1B19; }
            &::after {
                background: rgba(29, 27, 25, 0.7); } }
        &__advsearch {
            &__link {
                &,
                &.header span,
                &.header i {
                    color: #FFF500;
                    background: #1C1C1C;
                    &:active {
                        color: #FFF; } } } }
        &__europeana {
            i {
                color: #FFF500; }
            &.header {
                span {
                    color: #FFF; } } }
        &__button {
            background: #1C1C1C;
            border: 2px solid #FFF500;
            color: #FFF; }
        &__form {
            .search__button {
                &:disabled {
                    background: #1C1C1C;
                    border: 2px solid #FFF500;
                    color: #FFF; } }
            &:focus-within {
                .search__form-wrapper {
                    outline: 1px solid #FFF500; }

                .search__input > input {
                    border: 1px solid #FFF500 !important; }
                .search__button {
                    &,
                    &:hover,
                    &:focus {
                        background: #FFF500;
                        color: #1C1C1C;
                        border-color: #FFF500; } } } } } }

 :global(.font-medium) {
    .search {
        &__input {
            height: 43px; } } }

 :global(.font-big) {
    .search {
        &__button {
            @media (max-width: 1024px) {
                height: 40px;
                padding: 0; } } } }
