@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.chosen {
    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 2px;

            &:hover {
                background: #f5f5f5;

                .chosen__list-item--delete {
                    visibility: visible;
                    pointer-events: auto; } }

            &--delete {
                padding: 0;
                margin: 0;
                @include desktop {
                    visibility: hidden;
                    pointer-events: none; } }

            &--text {
                color: black;
                font-size: 0.8rem;
                @include fullhd {
                    font-size: 0.9rem; } } } }

    &__clear {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 0 5px 0; }

    &__button {
        padding: 0;
        font-size: 0.8rem;
        @include fullhd {
            font-size: 0.9rem; } } }

 :global(.contrast) {
    .chosen {
        &__list {
            &-item {
                &:hover {
                    background: #FFF500;
                    .chosen__list-item--text {
                        color: #0D0C0C; } } } } } }
