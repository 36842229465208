@import "@/styles/_breakpoints.sass";

.resources {
    background-color: #F5F5F5;
    padding: 50px 0 80px;
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-title {
            color: #4B4B4B;
            font-size: 1rem;
            margin: 0;
            display: flex;
            flex-direction: column;
            @include desktop {
                font-size: 1.25rem; }
            @include widescreen {
                font-size: 1.375rem; }
            @include fullhd {
                font-size: 1.75rem; }
            &::after {
                content: '';
                display: block;
                align-self: center;
                width: 145px;
                height: 1px;
                background-color: #C9C9C9;
                margin-top: 20px;
                @include fullhd {
                    margin-top: 60px; } } }
        &-img {
            height: 55px;
            @include desktop {
                height: 60px;
                margin: 0; }
            @include hd {
                height: 80px; }
            @include fullhd {
                height: 90px; }

            &--right {
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1); } } }

    &__content {
        padding-top: 50px;
        &-blocks {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
            list-style: none;


            @include mobile {
                max-width: 270px;
                margin: 0 auto; }
            @include tablet {
                display: inline-grid;
                grid-gap: 30px 30px;
                grid-template-columns: 330px 330px;
                grid-template-rows: 1fr auto; }
            @include widescreen {
                grid-gap: 40px 40px;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr auto; } }
        &-link {
            background-color: #236B83;
            border: 1px solid #64A3AC;
            color: #FFFFFF;
            font-size: 0.875rem;
            border-radius: 4px;
            padding: 15px;

            @include tablet {
                grid-column: span 2;
                max-width: 350px;
                margin: 0 auto;
                font-size: 1rem;
                grid-row-end: auto; }
            @include widescreen {
                grid-column: 2/3;
                max-width: initial;
                margin: 0;
                grid-row-end: none; }
            @include fullhd {
                font-size: 1.0625rem;
                padding: 25px; }
            &:hover, &:focus {
                background-color: #185367;
                border-color: #508D95;
                color: #FFFFFF;
                text-decoration: none;
                outline: none; }
            &:focus {
                box-shadow: 0 0 0 2px #71CDD5; }

            &:active {
                background-color: #0E2E39;
                border-color: #3E767E; } } }




    &__block {
        background-color: #FFFFFF;
        text-align: left;
        @include mobile {
            margin-bottom: 20px; }

        &-top {
            margin: 0;
            height: 115px;

            position: relative;
            @include tablet {
                height: 160px; }
            @media (min-width:1920px) {
                height: 180px; }
            &--img {
                display: flex;
                width: 100%;
                background-size: cover;
                height: 100%;

                position: absolute;
                top: 0;
                transition: 0.3s; }

            &--desc {
                color: #4B4B4B;
                font-size: 1rem;
                display: -webkit-box;
                padding: 25px 15px 5px;
                -webkit-box-orient: vertical;
                overflow: hidden;

                opacity: 0;
                width: 100%;
                height: 100%;
                top: 0;
                @media (min-width:1920px) {
                    padding: 45px 25px 5px; } }
            &--text {
                transform: translate3d(0,20px,0);
                transition: transform 0.35s;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @include mobile {
                    -webkit-line-clamp: 3; } } }
        &-bottom {
            display: flex;
            height: 62px;
            @include hd {
                height: 72px; }
            @media (min-width:1920px) {
                height: 95px; }
            &--title {
                font-size: 0.875rem;
                color: #4B4B4B;
                padding: 15px;
                flex: 1;
                margin: 0;
                font-weight: 700;
                @include desktop {
                    font-size: 1rem; }
                @media (min-width:1920px) {
                    font-size: 1.125rem;
                    padding: 20px 25px; }
                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden; } }
            &--icon {
                background-color: #ECEEEF;
                color: #818A91;
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 1 62px;
                @include hd {
                    flex: 0 1 72px; }
                @media (min-width:1920px) {
                    flex: 0 1 95px; } } }
        &-link {
            display: block;
            box-shadow: 3px 2px 6px 2px rgba(197, 197, 197, 0.72);
            transition: 0.3s;
            &:hover, &:focus {
                text-decoration: none;
                box-shadow: 3px 2px 6px 2px #adadad;
                outline: none;
                transition: 0.3s;

                .resources__block {
                    &-top {
                        &--desc {
                            opacity: 1;
                            transition: 0.3s; }
                        &--img {
                            opacity: 0;
                            transition: 0.3s; }
                        &--text {
                            transform: translate3d(0,0,0); } }

                    &-bottom {
                        &--title {
                            background-color: #F5F5F5;
                            transition: 0.3s; }
                        &--icon {
                            background-color: #236B83;
                            color: #FFFFFF;
                            transition: 0.3s; } } } }

            &:focus {
                outline: 1px solid #818A91; }

            &:active {
                .resources__block-bottom {
                    &--title {
                        background-color: #E4F4F5; } } } } } }


 :global(.contrast) {
    .resources {
        background-color: #0D0C0C;
        &__top {
            &-title {
                color: #FFF; } }
        &__block {
            background-color: #171514;
            &-bottom {
                &--title {
                    color: #FFF500; }
                &--icon {
                    background-color: #FFF500;
                    color: #171514; } }
            &-top {
                &--desc {
                    color: #FFF; } }
            &-link {
                box-shadow: 0px 0px 0px 1px #707070;
                &:hover,
                &:focus {
                    outline: 0;
                    box-shadow: 0px 0px 0px 2px #FFF500;
                    .resources__block-bottom {
                        &--icon {
                            background-color: #FFF500;
                            color: #171514; }
                        &--title {
                            background-color: #171514; } } } } }
        &__content {
            &-link {
                background-color: #FFF500;
                border: 2px solid #FFF500;
                color: #171514;
                &:hover,
                &:focus {
                    background-color: #0B0B0A;
                    color: #FFF500;
                    box-shadow: none; }
                &:active {
                    background-color: #262626; } } } } }
