.footer {
    &__partners {
        background-color: #fff;
        padding: 65px 0 70px;
        border-top: 1px solid #EAEAEA;
        .row {
            flex-direction: column; }
        &-text {
            text-align: center;
            margin: 0 auto;
            max-width: 800px;
            padding-bottom: 25px;
            font-size: 1rem;
            line-height: 1.25rem;
            @media (max-width: 1366px) {
                font-size: 0.75rem;
                line-height: 1.125rem; }
            @media (max-width: 575px) {
                padding: 0 15px 25px 15px; } }
        &-logos {
            margin: 0 auto;
            display: flex;
            align-items: center;
            @media (max-width: 375px) {
                flex-direction: column; }
            a {
                margin: 10px 25px 0;
                @media (min-width:376px) {
                    &:first-child {
                        margin-left: 0; }
                    &:last-child {
                        margin-right: 0; } }
                @media (max-width: 767px) {
                    margin: 10px 15px 0; }
                @media (max-width: 480px) {
                    margin: 10px 5px 0; }
                @media (max-width: 375px) {
                    margin: 10px auto; }
                img {
                    @media (max-width: 480px) {
                        height: 35px; } } }
            &--contrast {
                @extend .footer__partners-logos;
                display: none; } } }

    &__bottom-bar {
        background-color: #EAEAEA;
        font-size: 0.75rem;
        &--text {
            @media (min-width: 992px) {
                margin-right: 15px;
                padding: 0; }
            @media (min-width: 1025px) {
                margin-right: 20px; } }
        &--link {
            padding: 0;
            font-size: 0.75rem;
            color: #22737B;
            @media (min-width: 992px) {
                margin-right: 15px; }
            @media (min-width: 1025px) {
                margin-right: 20px; }
            &:hover,
            &:focus {
                color: #093539;
                outline: none; }

            &:focus {
                outline: 1px solid #093539;
                outline-offset: 2px;
                box-shadow: none; } }
        .row {
            height: 72px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media (max-width: 991px) {
                height: 160px;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                text-align: center; } }
        &--container {
            display: flex;
            height: 35%;
            @media (max-width: 991px) {
                flex-direction: column; }
            .btn-link-external {
                line-height: 1rem; }
            &-right {
                height: 35%;
                @media (max-width: 991px) {
                    max-width: 220px;
                    margin: 0 auto; }
                @media (min-width: 992px) {
                    padding-top: 0; } } }
        &--author {
            &-full {
                display: none;
                padding-bottom: 2px;
                @media (min-width: 1201px) {
                    display: inline-block; } }
            &-small {
                display: none;
                padding-bottom: 2px;
                @media (max-width: 1200px) {
                    display: inline-block; } }
            .btn-link-external {
                margin-left: 2px;
                &:hover {
                    span {
                        text-decoration: underline; } } } } } }
