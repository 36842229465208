@font-face {
  font-family: 'fw_100';
  src: url('./../assets/icons/fw_100.eot?43979120');
  src: url('./../assets/icons/fw_100.eot?43979120#iefix') format("embedded-opentype"), url('./../assets/icons/fw_100.woff2?43979120') format("woff2"), url('./../assets/icons/fw_100.woff?43979120') format("woff"), url('./../assets/icons/fw_100.ttf?43979120') format("truetype"), url('./../assets/icons/fw_100.svg?43979120#fw_100') format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "fw_100";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.icon-question:before {
    content: '\e800'; }

.icon-accept:before {
    content: '\e801'; }

.icon-accept-double:before {
    content: '\e802'; }

.icon-add:before {
    content: '\e803'; }

.icon-add-area:before {
    content: '\e804'; }

.icon-add-circle:before {
    content: '\e805'; }

.icon-add-reversed:before {
    content: '\e806'; }

.icon-arrow-double-left-full:before {
    content: '\e807'; }

.icon-arrow-double-right-full:before {
    content: '\e808'; }

.icon-arrow-down-full:before {
    content: '\e809'; }

.icon-arrow-down-long:before {
    content: '\e80a'; }

.icon-arrow-left:before {
    content: '\e80b'; }

.icon-arrow-left-full:before {
    content: '\e80c'; }

.icon-arrow-right:before {
    content: '\e80d'; }

.icon-arrow-right-full:before {
    content: '\e80e'; }

.icon-arrow-up-full:before {
    content: '\e80f'; }

.icon-arrow-up-long:before {
    content: '\e810'; }

.icon-bib:before {
    content: '\e811'; }

.icon-bold:before {
    content: '\e812'; }

.icon-book:before {
    content: '\e813'; }

.icon-book-add:before {
    content: '\e814'; }

.icon-book-locked:before {
    content: '\e815'; }

.icon-books:before {
    content: '\e816'; }

.icon-box:before {
    content: '\e817'; }

.icon-calendar:before {
    content: '\e818'; }

.icon-calendar2:before {
    content: '\e819'; }

.icon-calendar-checked:before {
    content: '\e81a'; }

.icon-chevron-double-left:before {
    content: '\e81b'; }

.icon-chevron-double-right:before {
    content: '\e81c'; }

.icon-chevron-down:before {
    content: '\e81d'; }

.icon-chevron-left:before {
    content: '\e81e'; }

.icon-chevron-right:before {
    content: '\e81f'; }

.icon-chevron-right-circle:before {
    content: '\e820'; }

.icon-chevron-up:before {
    content: '\e821'; }

.icon-close:before {
    content: '\e822'; }

.icon-close-circle:before {
    content: '\e823'; }

.icon-comment:before {
    content: '\e824'; }

.icon-comment2:before {
    content: '\e825'; }

.icon-compare:before {
    content: '\e826'; }

.icon-contrast:before {
    content: '\e827'; }

.icon-contrast-active:before {
    content: '\e828'; }

.icon-contrast-reversed:before {
    content: '\e829'; }

.icon-cookies:before {
    content: '\e82a'; }

.icon-dash-short:before {
    content: '\e82b'; }

.icon-dash-wide:before {
    content: '\e82c'; }

.icon-dataset:before {
    content: '\e82d'; }

.icon-datasets:before {
    content: '\e82e'; }

.icon-dot:before {
    content: '\e82f'; }

.icon-download:before {
    content: '\e830'; }

.icon-edit:before {
    content: '\e831'; }

.icon-external:before {
    content: '\e832'; }

.icon-eye:before {
    content: '\e833'; }

.icon-eye-disabled:before {
    content: '\e834'; }

.icon-facebook:before {
    content: '\e835'; }

.icon-files:before {
    content: '\e836'; }

.icon-files2:before {
    content: '\e837'; }

.icon-font:before {
    content: '\e838'; }

.icon-font-active:before {
    content: '\e839'; }

.icon-font-double-active:before {
    content: '\e83a'; }

.icon-font-reversed:before {
    content: '\e83b'; }

.icon-forward:before {
    content: '\e83c'; }

.icon-full-screen:before {
    content: '\e83d'; }

.icon-full-screen-close:before {
    content: '\e83e'; }

.icon-geolocation:before {
    content: '\e83f'; }

.icon-grid:before {
    content: '\e840'; }

.icon-hamburger:before {
    content: '\e841'; }

.icon-header:before {
    content: '\e842'; }

.icon-header2:before {
    content: '\e843'; }

.icon-header3:before {
    content: '\e844'; }

.icon-heart:before {
    content: '\e845'; }

.icon-home:before {
    content: '\e846'; }

.icon-image:before {
    content: '\e847'; }

.icon-image-close:before {
    content: '\e848'; }

.icon-image-geolocation:before {
    content: '\e849'; }

.icon-image-locked:before {
    content: '\e84a'; }

.icon-image-time:before {
    content: '\e84b'; }

.icon-info:before {
    content: '\e84c'; }

.icon-instagram:before {
    content: '\e84d'; }

.icon-italic:before {
    content: '\e84e'; }

.icon-like:before {
    content: '\e84f'; }

.icon-link2:before {
    content: '\e850'; }

.icon-link:before {
    content: '\e851'; }

.icon-list:before {
    content: '\e852'; }

.icon-list-end:before {
    content: '\e853'; }

.icon-locked:before {
    content: '\e854'; }

.icon-locked-circle:before {
    content: '\e855'; }

.icon-mail:before {
    content: '\e856'; }

.icon-more:before {
    content: '\e857'; }

.icon-page-description:before {
    content: '\e858'; }

.icon-page-number:before {
    content: '\e859'; }

.icon-question-reversed:before {
    content: '\e85a'; }

.icon-quote:before {
    content: '\e85b'; }

.icon-reload:before {
    content: '\e85c'; }

.icon-remove-reversed:before {
    content: '\e85d'; }

.icon-search:before {
    content: '\e85e'; }

.icon-search2:before {
    content: '\e85f'; }

.icon-search-cmwl:before {
    content: '\e860'; }

.icon-settings:before {
    content: '\e861'; }

.icon-settings2:before {
    content: '\e862'; }

.icon-settings3:before {
    content: '\e863'; }

.icon-share:before {
    content: '\e864'; }

.icon-solution:before {
    content: '\e865'; }

.icon-star:before {
    content: '\e866'; }

.icon-symbol:before {
    content: '\e867'; }

.icon-symbol2:before {
    content: '\e868'; }

.icon-time:before {
    content: '\e869'; }

.icon-to-end:before {
    content: '\e86a'; }

.icon-to-start:before {
    content: '\e86b'; }

.icon-transcription:before {
    content: '\e86c'; }

.icon-twitter:before {
    content: '\e86d'; }

.icon-unlocked:before {
    content: '\e86e'; }

.icon-verse-answer:before {
    content: '\e86f'; }

.icon-verse-auto-transcription:before {
    content: '\e870'; }

.icon-verse-clear:before {
    content: '\e871'; }

.icon-verse-comment:before {
    content: '\e872'; }

.icon-verse-delete:before {
    content: '\e873'; }

.icon-verse-down:before {
    content: '\e874'; }

.icon-verse-footer:before {
    content: '\e875'; }

.icon-verse-header:before {
    content: '\e876'; }

.icon-verse-move:before {
    content: '\e877'; }

.icon-verse-up:before {
    content: '\e878'; }

.icon-warning:before {
    content: '\e879'; }

.icon-zoom-in:before {
    content: '\e87a'; }

.icon-zoom-out:before {
    content: '\e87b'; }
