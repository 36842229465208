@import "@/styles/_mixins.sass";
@import "@/styles/_breakpoints.sass";

.date {
    margin: 20px 0;

    &__shadow {
        opacity: 0.5;
        pointer-events: none; }

    &__container {
        display: flex;
        color: #4B4B4B;
        font-size: 0.9rem;
        line-height: calc(1em + 0.65rem + 2px);
        margin: 7px 0; }

    &__form-control {
        background-color: transparent;
        border-radius: 0;
        border-color: transparent;
        border-bottom: solid 1px #A5A5A5;
        color: #4B4B4B;
        font-size: 0.85rem;
        padding: 3px 0;
        line-height: 1;
        width: 100%;
        height: calc(1em + 0.65rem + 2px);
        &:hover {
            border-bottom: solid 1px #22737B; }
        &:active {
            border-bottom: solid 1px #4B4B4B; }
        &:focus {
            border-color: transparent;
            border-bottom-color: #707070; }
        @include placeholder {
            color: #818A91;
            font-style: italic; } }

    &__error {
        color: #22737B;
        font-size: 0.7rem;
        line-height: 1rem;
        display: inline-block; } }

.input {
    flex: 0 1 120px;
    padding-left: 10px; }

.select {
    flex: 0 0 80px;
    padding-left: 10px; }

.checkbox {
    color: #22737B;
    align-items: center;
    line-height: 18px;
    display: flex;
    width: 100%;
    &__label {
        display: flex;
        flex: 1;
        cursor: pointer;
        &:hover {
            .checkbox__label--text {
                text-decoration: underline; } }

        &--text {
            flex: 1; }
        &--count {
            color: #91999F; } } }
