$contrast-yellow: #FFF500;
$contrast-black: #171514;
$contrast-white: #FFFFFF;

.contrast {
    background-color: #0D0C0C;
    .header {
        &__contrast {
            &-btn {
                .header__contrast {
                    &-active {
                        display: block;
                        color: $contrast-yellow;
                        background-color: $contrast-black; }
                    &-normal,
                    &-hover {
                        display: none; } } } }

        &__top {
            &-bar {
                background-color: $contrast-black;
                border-bottom: 1px solid #707070;
                &--link {
                    color: $contrast-yellow; } } }
        &__lang {
            &-current {
                &.nav-item {
                    .nav-link {
                        color: $contrast-yellow;
                        &:hover,
                        &:focus {
                            border-color: $contrast-yellow;
                            color: $contrast-yellow; } }
                    &.show {
                        .nav-link {
                            background: $contrast-yellow;
                            color: $contrast-black;
                            border-color: $contrast-yellow; } } } }
            &-item {
                &--current {
                    &.dropdown-item {
                        background-color: $contrast-white;
                        color: $contrast-black; } } } }
        &__font-btn {
            &.btn-primary {
                color: $contrast-yellow;
                background-color: transparent;
                border-color: transparent; } }
        &__bar {
            background-color: $contrast-black !important;
            border-bottom: 1px solid #707070;
            // &-logo--img
            //     display: none
            //     &.contrast__logo
            //         display: flex
            &-menu {
                &--hamburger {
                    span {
                        background-color: $contrast-yellow; }
                    &:not(.collapsed) span {
                        background-color: $contrast-yellow; } }
                .navbar-collapse {
                    background: $contrast-black;
                    border: 1px solid #333;
                    .header__menu-item .nav-link {
                        background-color: $contrast-black;
                        border-color: $contrast-yellow;
                        &:hover,
                        &:focus {
                            background-color: $contrast-yellow;
                            color: $contrast-black;
                            &:after {
                                color: $contrast-black; } }
                        &[aria-expanded="true"] {
                            background-color: $contrast-yellow;
                            color: $contrast-black; } } } }

            &-logo--img {
                &-leo, &-uwr {
                    filter: brightness(0) invert(1); }
                &-eu {
                    filter: invert(1); } } }
        &__menu {
            &-item.nav-item {
                .nav-link {
                    color: $contrast-yellow;
                    border: 1px solid transparent;
                    &:hover,
                    &:focus {
                        color: $contrast-yellow;
                        border-color: $contrast-yellow; }
                    &::after {
                        color: $contrast-yellow; } }
                &.show {
                    .nav-link {
                        background-color: $contrast-yellow;
                        color: $contrast-black;
                        &:after {
                            color: $contrast-black; } } } }
            &-list {
                &--item {
                    &.dropdown-item {
                        color: $contrast-yellow;
                        &:hover,
                        &:focus,
                        &:active,
                        &.active {
                            color: $contrast-black;
                            background-color: $contrast-yellow; } } } }
            &-title {
                color: $contrast-white; } } }

    .footer {
        &__bottom {
            &-bar {
                background-color: $contrast-black;
                color: $contrast-white;
                border-top: 1px solid #383636;
                a {
                    color: $contrast-yellow;
                    &:focus {
                        outline: 1px solid $contrast-yellow;
                        outline-offset: 2px;
                        box-shadow: none; } } } }
        &__partners {
            background-color: #141414;
            border-top-color: #383636;
            &-text {
                color: $contrast-white; }
            &-logos {
                display: none;
                &--contrast {
                    display: flex; } } } }

    .dropdown {
        &-menu {
            background-color: $contrast-black;
            border-color: $contrast-yellow;
            .dropdown-item {
                &:active {
                    background: $contrast-yellow;
                    color: $contrast-black; } } }
        &-item {
            color: $contrast-yellow;
            &:hover,
            &:focus {
                background: $contrast-yellow;
                color: $contrast-black; } } }

    .cookies {
        border-top: 2px solid $contrast-yellow;
        border-bottom: 2px solid $contrast-yellow;
        &__btn {
            border-color: $contrast-yellow;
            color: $contrast-yellow;
            &:hover,
            &:focus {
                background-color: $contrast-yellow;
                color: $contrast-black; } } }
    .content {
        &.light {
            background-color: $contrast-black; } }
    .btn {
        &-link, &-link-external {
            color: $contrast-yellow;
            &:hover,
            &:focus {
                color: $contrast-yellow; } }
        &-primary {
            background-color: $contrast-yellow;
            border-color: $contrast-yellow;
            color: $contrast-black;
            &:hover,
            &:focus,
            &:active,
            &:not(:disabled):not(.disabled):active {
                background-color: $contrast-black;
                border-color: $contrast-yellow;
                color: $contrast-yellow; }
            &.disabled,
            &:disabled {
                background-color: $contrast-yellow;
                border-color: $contrast-yellow;
                color: $contrast-black; } } }
    .dropdown {
        .btn-regular {
            border-bottom-color: $contrast-yellow;
            color: $contrast-yellow;
            &::after {
                color: $contrast-yellow; }
            + .dropdown-menu .dropdown-item {
                color: $contrast-yellow;
                &:hover {
                    background: $contrast-yellow;
                    color: $contrast-black; } } }
        &.show {
            .btn-regular {
                background: $contrast-yellow;
                border-color: $contrast-yellow;
                color: $contrast-black;
                &::after {
                    color: $contrast-black; } } } }
    .Checkbox {
        &.checkbox {
            &--light,
            &--europeana {
                .square {
                    stroke: $contrast-yellow;
                    fill: $contrast-black; }
                &.checked {
                    &:hover,
                    &:focus, {
                        .square {
                            fill: $contrast-yellow; }
                        .tick {
                            stroke: $contrast-black; } } } } } }

    .ReactModal__Overlay {
        background: rgba($contrast-black, 0.8) !important; }

    .map {
        &__marker {
            &--cluster, &--object {
                -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(1);
                filter: invert(30%) grayscale(100%) brightness(70%) contrast(1); } } }

    .leaflet {
        &-tile {
            filter: grayscale(100%); }

        &-popup {
            &-content-wrapper, &-tip {
                background: black; } }

        &-container {
            a {
                color: black;

                &.leaflet-popup-close-button {
                    color: #FFF000; } } } } }

