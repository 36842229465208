.page-size {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &__list {
        list-style: none;
        margin: 0;
        padding: 0 7px; }

    &__item {
        display: inline-block; }

    &__text {
        margin: 0;
        color: #4B4B4B;
        font-size: 0.8rem;
        line-height: calc(1rem + 2px); }

    &__button {
        color: #22737B;
        font-size: 0.8rem;
        border: solid 1px transparent;
        background: transparent;
        line-height: 1rem;
        padding: 4px 5px;
        &:hover,
        &:focus {
            text-decoration: underline; } }

    &__button--active {
        color: #4B4B4B;
        pointer-events: none; } }

 :global(.contrast) {
    .page-size {
        &__text {
            color: #FFF; }
        &__button {
            color: #FFF500;
            &--active {
                color: #FFF; } } } }
