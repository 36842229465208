@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.auto-input {
    width: 100%;
    position: relative;
    width: 100%;

    &__form-control {
        height: 100%;
        border-radius: 0px; }

    &__label {
        width: 100%;
        height: 100%; }

    &__label-span {
        display: none; }

    &__list {
        background: #fff;
        list-style: none;
        text-align: left;
        margin: 0;
        padding: 5px 0;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 2;
        width: 100%; }

    &__list-button {
        width: 100%;
        text-align: left;
        background: #fff;
        border: solid 1px #fff;
        color: #212529;
        padding: 0 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8rem;

        &:hover, &:focus {
            background-color: #F5F5F5; }

        &:focus {
            border: 1px solid #64A3AC;
            outline: 0; }

        &:active {
            background: #e6f4f5;
            color: #212529; } } }

.primary {
    .auto-input {
        &__form-control {
            background: #FFFFFF;

            @include touch {
                border-radius: 0; }
            @include desktop-only {
                font-size: 1.1em; }
            @include placeholder {
                color: #818A91;
                font-style: italic; }

            &:hover {
                background: #f5f5f5; }

            &:active {
                background: #e6f4f5; }

            &:focus {
                border: 1px solid #64A3AC;
                box-shadow: none;
                background: #FFFFFF; } } } }

.small {
    .auto-input {
        &__form-control {
            background-color: transparent;
            border-radius: 0;
            border-color: transparent;
            border-bottom: solid 1px #A5A5A5;
            color: #4B4B4B;
            font-size: 0.85rem;
            padding: 3px 0;
            line-height: 1;
            height: calc(1em + 0.65rem + 2px);
            &:hover {
                border-bottom: solid 1px #22737B; }
            &:active {
                border-bottom: solid 1px #4B4B4B; }
            &:focus {
                border-color: transparent;
                border-bottom-color: #707070; }
            @include placeholder {
                color: #818A91;
                font-style: italic; } }

        &__field-error {
            color: #22737B;
            font-size: 0.7rem;
            line-height: 1rem;
            display: inline-block; } } }

 :global(.contrast) {
    .primary {
        .auto-input {
            &__form-control {
                background: #121212;
                border: 2px solid #FFF500;
                color: #FFF500;
                @include placeholder {
                    color: #FFF; } }
            &__list-button {
                background: #0D0C0C;
                border-color: #333;
                &:hover,
                &:focus {
                    background:  #FFF500; } } } }
    .small {
        .auto-input {
            &__form-control {
                border-bottom-color: #FFF500;
                color: #FFF500;
                @include placeholder {
                    color: #FFF; } } } } }
