$col-xxs: 576px;
$col-xs: 768px;
$col-desktop: 992px;
$col-large: 1200px;
$col-xlarge: 1415px;
$col-xxlarge: 1800px;
$col-superlarge: 1920px;

@mixin mobile-small {
  @media screen and (max-width: 575.98px) {
    @content; } }

@mixin small-devices {
  @media screen and (min-width: $col-xxs) {
    @content; } }

@mixin small-devices-only {
  @media screen and (min-width: $col-xxs) and (max-width: 767.98px) {
    @content; } }

@mixin mobile {
  @media screen and (max-width: 767.98px) {
    @content; } }

@mixin tablet {
  @media screen and (min-width: $col-xs) {
    @content; } }

@mixin tablet-only {
  @media screen and (min-width: $col-xs) and (max-width: 991.98px) {
    @content; } }

@mixin touch {
  @media screen and (max-width: 991.98px) {
    @content; } }

@mixin desktop {
  @media screen and (min-width: $col-desktop) {
    @content; } }

@mixin desktop-only {
  @media screen and (min-width: $col-desktop) and (max-width: 1199.98px) {
    @content; } }

@mixin touch-sec {
  @media screen and (max-width: 1199.98px) {
    @content; } }

@mixin widescreen {
  @media screen and (min-width: $col-large) {
    @content; } }

//bootstrap breakpoints finishes here

@mixin widescreen-only {
  @media screen and (min-width: $col-large) and (max-width: $col-xlarge - 1) {
    @content; } }

@mixin hd {
  @media screen and (min-width: $col-xlarge) {
    @content; } }

@mixin fullhd {
  @media screen and (min-width: $col-xxlarge) {
    @content; } }

@mixin superhd {
  @media screen and (min-width: $col-superlarge) {
    @content; } }
