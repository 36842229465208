@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.facet {
    &__toggle {
        text-align: left;
        width: 100%;
        position: relative;
        font-size: 0.8rem;
        color: #29828a;
        border-top: 1px solid #dadada;
        letter-spacing: -0.1px;
        border-radius: 0;
        padding: 5px 0px;
        @include fullhd {
            font-size: 0.95rem; }

        &:after {
            position: absolute;
            right: 8px;
            top: calc(50% - 3px); }

        &:hover, &:focus {
            box-shadow: 0px 2px 0px 0px #29828a;
            z-index: 9; }

        &[disabled] {
            pointer-events: none;
            opacity: 1;
            color: #999999;
            &:after {
                display: none; } }

        &[aria-expanded="true"] {
            border-radius: 5px;
            font-weight: 600;
            background: #236b83;
            color: white;
            font-size: 0.9rem;
            padding: 7px 11px;
            letter-spacing: 0.3px;
            border-top: 1px solid transparent;
            &:after {
                right: 10px; }

            &:hover, &:focus {
                box-shadow: none; } } }

    &__menu {
        position: static !important;
        transform: none !important;
        float: none;
        padding: 0;
        margin: 3px 0;
        border-radius: 5px;
        border-color: #dadada; }

    &__no-facet {
        padding: 8px 10px 7px;
        font-size: 0.83rem;
        line-height: 1rem;
        color: #4B4B4B; } }


 :global(.contrast) {
    .facet {
        &__toggle {
            color: #FFF500;
            border-color: #333333;
            &:hover,
            &:focus {
                box-shadow: 0px 2px 0px 0px #FFF500; }
            &[aria-expanded="true"] {
                background: #FFF500;
                color: #0D0C0C; } }
        &__no-facet {
            color: #fff; } } }
