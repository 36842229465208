
@import "@/styles/_breakpoints.sass";

.results {
    background-color: #F5F5F5;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 60px 15px 60px 40px;
    position: relative;

    @include touch {
        padding: 40px 0; }
    &::after {
        content: '';
        display: block;
        background: #F5F5F5;
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        z-index: -1;
        margin-right: -100%;

        @include touch {
            width: 200%;
            margin-right: -50%; } }

    @include mobile-small {
        padding: 40px 15px 0; }

    &__nav {
        background: #EAEAEA;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 0;
        @include mobile {
            justify-content: flex-end; }
        @include desktop {
            padding-left: 30px; }
        @include hd {
            padding-left: 70px; }
        @include fullhd {
            padding-left: 95px; }

        &::after {
            content: '';
            display: block;
            background: #EAEAEA;
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0;
            z-index: -1;
            margin-right: -100%;
            top: 0;
            @include touch {
                width: 200%;
                margin-right: -50%; } }

        &--bottom {
            justify-content: flex-end; } }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        column-count: 3;
        column-gap: 20px;
        -webkit-column-rule: 1px solid #E8E8E8;
        -moz-column-rule: 1px solid #E8E8E8;
        column-rule: 1px solid #E8E8E8;
        @include touch {
            column-count: 1; }

        &-item {
            break-inside: avoid-column;
            &--link {
                color: #22737B;
                font-size: 0.875rem;
                line-height: 1.3rem;
                width: 100%;
                display: flex;
                padding-bottom: 8px;
                padding-left: 25px;
                &:hover {
                    color: #22737B; }
                &:active {
                    color: #4D4D4D; }
                @include touch {
                    padding-left: 0px; }
                i {
                    font-size: 1.2rem;
                    display: none;

                    &[aria-current="page"] {
                        color: #4B4B4B;
                        font-weight: 700;
                        font-size: 0.875rem;
                        pointer-events: none;
                        i {
                            display: inline-block; } } } } } }

    &__content {
        padding: 0 0 40px;
        color: #4B4B4B;
        flex: 1 1;

        .collapseMenu {
            @include touch {
                display: none; }
            &-active {
                i:before {
                    transform: rotate(0deg); } } } } }

 :global(.contrast) {
    .results {
        background-color: #0D0C0C;
        &::after {
            background: #0D0C0C; }
        &__list {
            column-rule: 1px solid #333;
            &-item--link {
                color: #FFF500; } }
        &__nav {
            background-color: #0D0C0C;
            &::after {
                background: #0D0C0C; } } } }
