@import "@/styles/_breakpoints.sass";

.pagination {

    &__pages {
        display: inline-block; }

    &__button {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }

    &__form {
        display: inline-block; }

    &__input {
        background: transparent;
        font-size: 0.8rem;
        border: none;
        border-bottom: solid 1px #A5A5A5;
        width: 50px;
        text-align: right;
        padding: 0 10px;
        line-height: 1rem;
        color: #22737B; }

    &__input-label {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }

    &__total {
        display: inline-block; }

    &__total-label {
        color: #4B4B4B;
        font-size: 0.8rem;
        margin: 0 3px 0 8px; }

    &__list {
        margin: 0;
        padding: 0;
        display: inline-block; }

    &__list-item {
        display: inline-block; }

    &__item-button {
        color: #22737B;
        font-size: 0.8rem;
        border: solid 1px transparent;
        background: transparent;
        line-height: calc(1rem + 1px);
        padding: 4px 5px;
        &:hover,
        &:focus {
            text-decoration: underline; }

        &--disabled {
            color: #4B4B4B;
            pointer-events: none;
            &:hover,
            &:focus {
                text-decoration: none; } } }

    &__item-button--active {
        color: #4B4B4B;
        pointer-events: none;
        &:hover,
        &:focus {
            text-decoration: none; } }

    &__item-separator {
        color: #4B4B4B;
        font-size: 0.8rem;
        margin: 0 5px; }

    &__next,
    &__previous {
        color: #22737B;
        font-size: 0.8rem;
        border: solid 1px transparent;
        background: transparent;
        line-height: calc(1rem + 1px);
        padding: 4px 5px;
        position: relative;
        display: inline-block;
        &:hover,
        &:focus {
            text-decoration: underline; }

        span {
            @include mobile-small {
                display: none; } } }

    &__next {
        margin-left: 10px;
        padding-right: 12px;
        @include mobile-small {
            margin: 0;
            padding: 10px; }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            width: 0;
            top: 8px;
            height: 0;
            @include mobile-small {
                top: 2px;
                left: 7px;
                right: auto; } }

        &:after {
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 4px solid #22737B; } }

    &__previous {
        margin-right: 10px;
        padding-left: 12px;
        @include mobile-small {
            margin: 0;
            padding: 10px; }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 8px;
            width: 0;
            height: 0;
            @include mobile-small {
                top: 2px;
                right: 7px;
                left: auto; } }

        &:before {
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-right: 4px solid #22737B; } } }

 :global(.contrast) {
    .pagination {
        &__item {
            &-button {
                color: #FFF500; }
            &-separator {
                color: #FFF; } }
        &__total-label {
            color: #FFF; }
        &__next,
        &__previous {
            color: #FFF500;
            &::after {
                border-left-color: #FFF500; }
            &::before {
                border-right-color: #FFF500; } }

        &__input {
            color: #FFF500;

            &-label {
                color: #FFF500; } } } }

 :global(.font-big) {
    .pagination {
        &__next {
            @include desktop {
                margin-left: 5px;
                padding-right: 10px; } }
        &__previous {
            @include desktop {
                margin-right: 5px;
                padding-left: 10px; } } } }
