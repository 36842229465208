@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.facets {
    margin-top: 20px;

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: baseline; }

    &__group {
        margin-top: 17px;
        border-bottom: 1px solid #dadada; }

    &__text {
        &--title {
            font-weight: bold;
            font-size: 0.9rem;
            @include fullhd {
                font-size: 1.1rem;
                letter-spacing: 0.1px; } } } }

 :global(.contrast) {
    .facets {
        &__group {
            border-color: #333333; } } }
