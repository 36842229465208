
@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.categories {
    &__title {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
        @include touch {
            margin: 0; }

        &-container {
            display: flex;
            justify-content: space-between;
            @include touch {
                background-color: #ECEEEF;
                padding: 10px; }

            &--hidden {
                @include touch {
                    background-color: #296376;
                    .categories__title,
                    .categories__catalogue-collapse {
                        color: #FFFFFF; } } } } }

    &__catalogue {
        &--hidden {
            @include touch {
                .categories__catalogue-list {
                    display: none; } } }

        &-top {
            display: flex;
            justify-content: space-between;

            @include touch {
                margin: 12px 10px 0; }

            &--selected {
                @include touch {
                    background-color: #ECEEEF;
                    margin: 0;
                    padding: 10px; }

                .categories__catalogue {
                    &-title {
                        @include touch {
                            display: none; } }
                    &-selected {
                        @include touch {
                            display: flex; } } } }

            &--clear {
                @include touch {
                    display: none; } } }

        &-collapse {
            display: none;
            padding: 0;
            @include touch {
                display: inline-block;
                font-size: 0.8rem; }

            i:before {
                transform: rotate(180deg); }

            &--hidden {
                i:before {
                    transform: rotate(0deg); } } }

        &-title {
            font-size: 0.9rem;
            font-weight: 700; }

        &-selected {
            color: #565656;
            display: none;
            flex-direction: column;
            font-weight: 700;
            font-size: 0.8rem;
            &--span {
                color: #4B4B4B;
                font-weight: 400; } }

        &-button {
            padding: 0;
            font-size: 0.8rem; }

        &-list {
            padding: 10px 0 50px;
            list-style: none;
            @include touch {
                margin: 0 10px;
                padding-bottom: 25px;
                border-bottom: 1px solid #EAEAEA; } }

        &-item {
            color: #4B4B4B;
            padding-top: 10px;

            &--container {
                display: flex;
                justify-content: space-between; }

            &--label {
                align-items: center;
                display: flex;
                cursor: pointer; }

            &--text {
                font-size: 0.875rem;
                color: #22737B;
                &:hover {
                    text-decoration: underline; } }

            &--count {
                color: #595959;
                font-size: 0.9375rem; }

            &--input {
                width: 14px;
                height: 14px;
                margin-right: 10px;
                &:checked + .categories__catalogue-item--text {
                    color: #4B4B4B; } } } } }

 :global(.contrast) {
    .categories {
        &__catalogue {
            &-button {
                color: #FFF500; }
            &-item {
                &--text {
                    color: #FFF500; }
                &--count {
                    color: #FFF; }
                &--input {
                    &:checked + .categories__catalogue-item--text {
                        color: #FFF; } } }
            &-top--selected {
                background-color: #0d0c0c;
                border: 1px solid #333; }
            &-selected {
                color: #FFF;
                &--span {
                    color: #FFF; } } }
        &__title-container {
            background-color: #0d0c0c;
            border: 1px solid #333; } } }
