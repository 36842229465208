@import "@/styles/_breakpoints.sass";
@import "../../styles/_mixins.sass";

.header {
    &__wrapper {
        position: fixed;
        width: 100%;
        z-index: 1;
        &--sticky {
            z-index: 99; } }
    &__container {
        @media (max-width: 1024px) {
            flex-wrap: wrap !important; }
        @media (min-width: 1025px) {
            flex-wrap: nowrap !important;
            align-items: flex-start !important; } }
    &__sticky {
        box-shadow: 0 0 6px 0px #707070;
        @media (max-width: 274px) {
            margin-top: -65px; } }
    &__bar {
        background-color: #fff;
        padding: 20px 15px 15px;
        @media (max-width: 991px) {
            padding: 20px 35px 20px 15px; }
        @media (max-width: 480px) {
            padding: 15px 25px; }

        &-logo {
            display: flex;
            @media (max-width: 1024px) {
                padding-bottom: 10px; }
            @include desktop-only {
                margin-top: 3px; }

            &--box {
                &-eu {
                    position: relative;
                    padding: 0 18px 0 0;
                    @include mobile-small {
                        padding: 0 8px 0 0; }
                    @include widescreen {
                        padding-right: 25px; }
                    @include superhd {
                        padding-right: 30px; }
                    &::after {
                        position: absolute;
                        right: 0;
                        top: 0;
                        @include widescreen;
                        top: -3px;
                        width: 1px;
                        height: 100%;
                        background: #cccccc;
                        display: block;
                        content: ""; } }

                &-uwr {
                    position: relative;
                    padding: 0 18px;
                    @include mobile-small {
                        padding: 0 8px; }
                    @include widescreen {
                        padding-right: 25px; }
                    @include superhd {
                        padding-right: 30px; }
                    &::after {
                        position: absolute;
                        right: 0;
                        top: 0;
                        @include widescreen {
                            top: -3px; }
                        width: 1px;
                        height: 100%;
                        background: #cccccc;
                        display: block;
                        content: ""; } }

                &-leo {
                    @include superhd {
                        padding-left: 5px; } } } }

        &-logo--img-leo {
            position: relative;
            width: 120px;
            top: -1px;
            @include widescreen {
                width: 225px;
                top: -10px; }
            @include superhd {
                top: -15px;
                width: 300px; }
            @include touch {
                width: 160px;
                top: -10px;
                margin-bottom: -20px; } }

        &-logo--img-uwr {
            width: 75px;
            filter: brightness(0);
            @include widescreen {
                width: 115px; }
            @include superhd {
                width: 150px; } }

        &-logo--img-eu {
            width: 60px;
            @include widescreen {
                width: 75px; }
            @include superhd {
                width: 100px; } }


        &-search {
            flex: 2 1 auto;

            @media (max-width: 1024px) {
                order: 1;
                flex: 0 1 100%; }

            &.contrast__logo {
                display: none; }

            .mainPage {
                @media(min-width: 1025px) {
                    display: none; } } }

        .row {
            width: calc(100% + 30px);
            @media (max-width: 991px) {
                width: calc(100% + 15px);
                margin: 0 auto; } }
        .navbar-brand {
            padding: 0; }
        .dropdown-toggle::after {
            color: #22737B; }
        &-menu {
            display: flex;
            &--hamburger {
                border: 0;
                padding: 0;
                &:focus {
                    outline: 0; }
                span {
                    width: 20px;
                    height: 3px;
                    background-color: #2C3343;
                    margin: 4px 0;
                    display: block; }
                &:not(.collapsed) {
                    z-index: 1021;
                    position: absolute;
                    top: -30px;
                    right: 20px;
                    width: 25px;
                    height: 25px;
                    span {
                        background-color: #296376;
                        width: 15px;
                        height: 2px;
                        position: absolute;
                        right: 5px;
                        &:nth-of-type(1) {
                            transform: rotate(45deg);
                            top: 8px; }
                        &:nth-of-type(2) {
                            transform: rotate(-45deg);
                            margin: -6px 0;
                            top: 18px; }
                        &:nth-child(3) {
                            display: none; } } } }

            .navbar-collapse {
                @media (max-width: 991px) {
                    position: absolute;
                    width: calc( 100% - 20px );
                    right: 0;
                    top: -40px;
                    height: 100vh;
                    background: #FFF;
                    z-index: 1020;
                    transition: none; }
                .navbar-nav {
                    text-align: left; }
                .dropdown-menu {
                    right: 0;
                    left: auto;
                    margin-top: 4px;
                    min-width: 260px;
                    @include scrollable-menu(97px);
                    @media (max-width: 991px) {
                        border-radius: 0;
                        margin: 0;
                        border: 0;
                        @include scrollable-menu(135px); }
                    @media (max-width: 320px) {
                        @include scrollable-menu(150px); } }
                .header__menu-item {
                    .nav-link {
                        @media (max-width: 991px) {
                            margin: 2px 0 0;
                            padding: 9px 25px;
                            background-color: #ECEEEF;
                            border-radius: 0;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            &[aria-expanded="true"] {
                                background-color: #236B83; } } } } } } }
    &__menu {
        &-title {
            display: none;
            @media (max-width: 991px) {
                display: block;
                padding: 15px 25px;
                color: #868686;
                font-size: 0.875rem;
                text-align: left; } }
        &-item {
            &.nav-item {
                .nav-link {
                    color: #4B4B4B;
                    line-height: 1.4375rem;
                    font-size: 1rem;
                    border-radius: 4px;
                    padding: 6px 10px;
                    margin-left: 5px;
                    &:after {
                        margin-left: 8px;
                        font-size: 0.875rem; }
                    &:hover {
                        color: #22737B; } }
                &.show {
                    .nav-link {
                        background-color: #236B83;
                        color: #FFF;
                        &:after {
                            color: #FFF; } } } } }
        &-list--item {
            &.dropdown-item {
                color: #373A3C;
                font-size: 0.875rem;
                @media (max-width: 991px) {
                    padding: 5px 40px;
                    white-space: pre-wrap; }
                &:hover {
                    background-color: #F5F5F5; }
                &:active,
                &.active {
                    background-color: #E4F4F5; }
                &.active {
                    color: #296376; } } } }
    &__top-bar {
        background-color: #236B83;
        padding: 6px 15px;
        .row {
            justify-content: flex-end;
            align-items: center; }
        &--link {
            color: #fff;
            text-decoration: underline;
            padding: 0;
            font-size: 0.875rem;
            line-height: 1.438rem;
            @media (max-width: 991px) {
                font-size: 0.75rem; }
            &:hover,
            &:focus {
                color: #fff; }

            &:focus {
                outline: 1px solid #fff;
                outline-offset: 2px; } } }
    &__lang {
        padding: 0;
        margin-left: 30px;
        @include mobile-small {
            margin-left: 15px; }
        &-current {
            &.nav-item {
                .nav-link {
                    color: #fff;
                    padding: 4px 9px;
                    font-size: 0.875rem;
                    text-transform: uppercase;
                    border: 1px solid transparent;
                    border-radius: 4px;
                    @media (max-width: 991px) {
                        font-size: 0.75rem; }
                    &:hover, &:focus {
                        border-color: #fff;
                        color: #fff;
                        outline: none; } }

                &.show {
                    .nav-link {
                        background: #fff;
                        color: #373A3C;
                        border-color: #fff; } } }
            .dropdown-toggle::after {
                margin-left: 14px;
                @media (max-width: 991px) {
                    margin-left: 10px; } } }
        &-menu {
            .collapse {
                display: flex; }
            .dropdown-menu {
                padding: 4px 0;
                min-width: 100%;
                text-transform: uppercase;
                position: absolute;
                margin-top: 6px;
                .dropdown-item {
                    padding: 5px 10px;
                    font-size: 0.875rem;
                    text-align: right;
                    @media (max-width: 991px) {
                        font-size: 0.75rem; }
                    &:active {
                        background: #E4F4F5;
                        color: #373A3C; } } } }
        &-item {
            &:last-child {
                margin-top: 2px; }
            &.active {
                color: #296376;
                background-color: #E4F4F5; }
            &--icon {
                display: none;
                &::before {
                    margin-left: 0;
                    margin-right: 5px; } }
            &--current {
                background-color: #E4F4F5;
                color: #296376;
                pointer-events: none;
                .header__lang-item--icon {
                    display: inline; } } } }
    &__access {
        margin-left: 30px;
        display: flex;
        align-items: baseline;
        @include mobile-small {
            margin-left: 15px; } }

    &__font, &__contrast {
        &-btn-span {
            display: none; } }

    &__contrast {
        &-btn {
            padding: 0;
            background: transparent;
            border: none;
            font-size: 1.04rem;
            &:hover,
            &:focus,
            &.btn-primary:not(:disabled):not(.disabled):active {
                background: transparent; }
            .header__contrast-normal {
                display: block; }
            .header__contrast-hover,
            .header__contrast-active {
                display: none; }
            &:hover {
                opacity: 0.8; }
            &:focus {
                outline: 1px solid #fff;
                outline-offset: 0; } } }
    &__font {
        &-btn {
            padding: 0;
            border: none;
            font-size: 1.04rem;
            margin-right: 10px;
            &:hover,
            &:focus,
            &.btn-primary:not(:disabled):not(.disabled):active {
                background: transparent;
                text-decoration: none; }
            &:hover {
                opacity: 0.8; }
            &:focus {
                outline: 1px solid #fff;
                outline-offset: 4px; } }
        &-normal {
            display: flex; }

        &-medium,
        &-big,
        &-hover {
            display: none; } } }
