@import "@/styles/_mixins.sass";
@import "@/styles/_breakpoints.sass";

.advanced-search {
    height: 100%;
    text-align: left;
    width: 100%;
    margin-bottom: 100px;

    &__form-control {
        background-color: transparent;
        border-radius: 0;
        border-color: transparent;
        border-bottom: solid 1px #A5A5A5;
        color: #4B4B4B;
        font-size: 0.85rem;
        padding: 3px 0;
        line-height: 1;
        height: calc(1em + 0.65rem + 2px);
        &:hover {
            border-bottom: solid 1px #22737B; }
        &:active {
            border-bottom: solid 1px #4B4B4B; }
        &:focus {
            border-color: transparent;
            border-bottom-color: #707070; }
        @include placeholder {
            color: #818A91;
            font-style: italic; } }

    &__breadcrumbs {
        padding: 0 10px;
        @include small-devices {
            padding: 0; } }

    &__header {
        color: #4B4B4B;
        font-size: 1.6rem;
        margin-top: 10px;
        padding: 0 30px;
        @include small-devices {
            padding: 0; } }

    &__text {
        margin-top: 60px;
        padding: 0 30px;
        @include small-devices {
            padding: 0; } }

    &__title {
        font-size: 1rem;
        color: #4B4B4B;
        font-weight: 700; }

    &__content {
        font-size: 0.875rem;
        line-height: 1.6rem;
        color: #4B4B4B;
        margin: 20px 0 30px; }

    &__link {
        @extend .advanced-search__content;
        display: inline;
        color: #22737B;
        margin-left: 3px;
        &:hover, &:focus, &:active {
            color: #22737B; } }

    &__form {
        background-color: #F5F5F5;
        padding: 10px;
        @include small-devices {
            padding: 40px; }
        @include widescreen {
            padding: 70px 70px 100px 70px; } }

    &__buttons {
        text-align: right;
        padding: 0 15px;
        margin-top: 10px; }

    &__button {
        &--reset,
        &--submit {
            font-size: 0.85rem;
            margin: 0 0 10px 15px; }
        &--submit {
            padding: 5px 40px;
            border-color: #707070; } }

    &__form-error {
        color: #22737B;
        font-size: 0.7rem;
        line-height: 1rem;
        display: inline-block;
        padding: 15px;
        text-align: right;
        width: 100%; }

    &__array {
        list-style: none;
        margin: 0;
        padding: 0; }

    &__field {
        display: flex;
        margin: 20px 15px;
        &:hover {
            background: #EAEAEA; }

        &--inner {
            display: flex;
            flex: 1;
            flex-direction: column;
            @include small-devices {
                padding-right: 20px;
                border-right: solid 1px #A5A5A5; }
            @include desktop {
                border-right: none;
                flex-direction: row; } }

        &--query-conj {
            display: flex;
            flex: 1;
            flex-direction: column;
            @include small-devices {
                flex-direction: row; } }

        &--query {
            width: 100%;
            margin: 10px 10px 10px 0;
            @include desktop {
                margin: 0 15px; }
            @include small-devices {
                flex: 1 1 400px; } }

        &--type {
            width: 250px;
            @include desktop {
                flex: 0 0 250px; } }

        &--conj {
            width: 80px;
            margin: 10px 0;
            @include desktop {
                margin: 0; }
            @include small-devices {
                display: flex;
                align-items: flex-end;
                flex: 0 0 80px; }
            @include widescreen {
                align-items: flex-start; } }


        &-text, &-select, &-date {
            position: relative;
            display: flex;
            flex-direction: column; }

        &-date {
            &--form-control {
                @extend .advanced-search__form-control;
                width: 80px; } }

        &-button {
            font-size: 0.85rem;
            padding-top: 0;
            padding-bottom: 0;
            align-self: flex-end;
            line-height: 1;
            align-self: flex-start;
            @include desktop {
                align-self: center;
                height: calc(1em + 0.65rem + 2px); } }

        &-error {
            color: #22737B;
            font-size: 0.7rem;
            line-height: 1rem;
            display: inline-block; } }

    &__checkbox {
        display: flex;
        align-items: center; }

    &__add {
        padding: 15px;

        &-button {
            font-size: 0.85rem;
            padding: 3px 10px 3px 5px;
            border-color: #707070; } }

    &__date {
        display: flex;
        flex-direction: column;
        @include widescreen {
            flex-direction: row; }

        &--from,
        &--to {
            align-items: flex-start;
            margin-right: 5px;
            line-height: 1.6rem; }

        &--from,
        &--to,
        &--timeline {
            flex: 1;
            display: flex;
            font-size: 0.9rem;
            color: #4B4B4B;

            .advanced-search__field {
                &-date,
                &-select {
                    margin: 0 7px; }

                &-select {
                    width: 60px;
                    @include tablet;
                    flex: 0 0 60px; } } }

        &--timeline {
            line-height: 1;
            margin-top: 10px;
            @include widescreen {
                margin-top: 0;
                padding-left: 10px;
                margin-left: 10px;
                border-left: solid 1px #A5A5A5; } } } }

 :global(.contrast) {
    .advanced-search {
        &__header,
        &__title,
        &__content {
            color: #FFF; }
        &__link {
            color: #FFF500; }
        &__form {
            background-color: #0D0C0C;
            border: 1px solid #333;
            &-control {
                border-bottom-color: #FFF500;
                color: #FFF;
                &:focus {
                    background: #FFF500;
                    border-color: #FFF500;
                    color: #0D0C0C; }
                option {
                    color: #FFF500;
                    background: #0D0C0C; } } }

        &__date {
            &--timeline,
            &--from,
            &--to {
                border-color: #333;
                color: #FFF; } }
        &__field {
            background: none;
            &-date--form-control {
                @include placeholder {
                    color: #FFF; }
                &:focus {
                    background: #0D0C0C;
                    border-color: #FFF500;
                    outline: none;
                    color: #FFF500; } }

            &-select {
                color: #FFF; } } } }



