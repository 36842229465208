@import "@/styles/_breakpoints.sass";

$iconImg: url('../../images/icon-image.svg');

.results {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__container {
        @include touch {
            flex-direction: column; } }

    &__title {
        padding: 12px 0 22px;
        font-size: 1.375rem;
        line-height: 1.4375rem;
        @include mobile {
            padding: 12px 15px 22px; }
        @include fullhd {
            font-size: 1.75rem;
            line-height: 2rem; } }

    &__sidebar {
        flex: 0 0 395px;
        @include mobile {
            padding: 0 15px; } }

    &__loading {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }



    &-metadata {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-top: 1px solid #DADADA;
        border-bottom: 1px solid #DADADA;
        padding: 12px 0;
        margin-left: 20px;
        @include touch {
            order: 4;
            margin-top: 15px;
            margin-left: 0; } }

    &--btn {
        font-size: 0.9375rem;
        box-shadow: 0px -11px 30px #f5f5f5;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 0 15px;
        @include touch {
            font-size: 0.875rem; }

        &:focus {
            box-shadow: 0px -11px 30px #f5f5f5; }
        &:hover {
            color: #22737B; }

        &-active {
            box-shadow: none;
            &:focus {
                box-shadow: none; } }

        i:before {
            transform: rotate(180deg); } }

    &--list {
        padding-left: 0;
        list-style: none;
        font-size: 0.875rem;
        color: #4B4B4B;
        text-align: left;
        flex: 1;
        overflow: hidden;
        @include touch {
            padding-bottom: 40px; }
        @include fullhd {
            flex: 0 0 415px; }
        &-height {
            max-height: 170px; }
        &-opened {
            max-height: 100%; } }

    &__line {
        margin: 0;
        border-color: #EAEAEA; }

    &__no-results {
        padding: 25px; }

    &__content {
        display: flex;
        @include touch {
            flex-direction: column; } }

    &__main {
        flex: 1;
        @include mobile {
            padding: 0 15px; } }

    &__list {
        background-color: #FFFFFF;
        list-style: none;
        padding: 0;
        margin-bottom: 3px; }

    &__item {
        display: flex;
        flex-direction: column;
        background-color: #F5F5F5;
        padding-top: 18px;
        padding-bottom: 18px;
        margin-top: 3px;
        position: relative;
        @include desktop {
            padding-left: 30px; }
        @include hd {
            padding-left: 70px; }
        @include fullhd {
            padding-left: 95px; }

        &::after {
            content: '';
            display: block;
            background: #F5F5F5;
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0;
            z-index: -1;
            margin-right: -100%;
            margin-top: -18px;
            @include touch {
                width: 200%;
                margin-right: -50%; } }

        &-external-link {
            border: 0;
            color: #22737B;
            text-align: left;
            font-size: 0.875rem;
            position: relative;
            vertical-align: baseline;
            @include touch {
                flex-direction: column;
                margin-bottom: 5px;
                font-size: 0.75rem; }
            &:focus {
                box-shadow: none; }
            &:active {
                color: #4B4B4B; }

            i {
                // position: absolute
                // right: -18px
                // top: -4px
                @include hd {
                    font-size: 0.75rem; }
                @include touch {
                    right: -15px;
                    top: -1px; } }

            &--no-link {
                @extend .results__item-title;
                color: #4B4B4B; } }

        &-content {
            display: flex;
            flex-wrap: wrap;
            position: relative; }

        &-bottom {
            display: flex;
            flex: 1;
            margin-top: 10px;
            @include mobile-small {
                flex-direction: column; }
            @include touch {
                align-items: flex-start; }

            &--left {
                display: flex;
                flex-direction: column;
                flex: 0 1 160px;
                @include mobile-small {
                    flex: 1; } }

            &--right {
                display: flex;
                flex: 1;
                justify-content: flex-end;
                align-self: flex-start;
                @include mobile-small {
                    align-self: flex-end;
                    flex-direction: column; } }

            &--type {
                font-size: 0.75rem;
                display: flex;
                color: #4F4F4F;
                i {
                    font-size: 1.2rem;
                    line-height: 1rem;
                    color: #9CA3A8;
                    @include touch {
                        font-size: 1rem; } } }

            &--copy {
                padding: 0;
                font-size: 0.875rem;
                i {
                    font-size: 1.1rem;
                    line-height: 0.8rem; } } }

        &-title {
            color: #22737B;
            text-align: left;
            margin-bottom: 14px;

            &:focus {
                box-shadow: none; }

            &:active {
                color: #4B4B4B; }

            @include touch {
                order: 2;
                flex: 1 calc(100% - 90px);
                font-size: 0.9375rem;
                line-height: 1.25rem;
                margin-left: 10px;
                margin-bottom: 0; }

            @include desktop {
                flex: 1 calc(100% - 60px);
                font-size: 0.9375rem; }

            @include hd {
                flex: 1 calc(100% - 70px);
                font-size: 1rem;
                line-height: 1.25rem; }

            @include fullhd {
                flex: 1 calc(100% - 95px);
                font-size: 1.25rem;
                line-height: 1.625rem; }
            i {
                @include hd {
                    font-size: 0.75rem; }
                @include fullhd {
                    font-size: 1rem; } }
            &--no-link {
                @extend .results__item-title;
                color: #4B4B4B; } }

        &-authors {
            flex: 1 1 100%;
            margin-top: -10px;
            font-size: 0.875rem;
            margin-bottom: 14px;

            @include touch {
                order: 3;
                font-size: 0.75rem;
                margin-top: 10px; }

            span:not(:last-child) {
                &:after {
                    content: "; "; } } } // TODO: do zamiany na przecinek po tym jak UWr zmieni dane

        &-img {
            display: flex;
            width: 160px;
            height: 220px;
            background-color: #EAEAEA;
            border: 1px solid #E0E0E0;
            background-size: cover;
            background-position: center;

            @include touch {
                width: 80px;
                height: 110px;
                order: 1;
                margin: 0; }

            &--error {
                @extend .results__item-img;
                background-image: $iconImg;
                background-size: 66px;
                background-repeat: no-repeat;
                @include touch {
                    background-size: 40px; } }

            i {
                font-size: 4.125rem;
                margin: auto;
                color: #D9D9D9;
                @include touch {
                    font-size: 2.5rem; } }
            img {
                margin: 0 auto;
                width: 66px;
                display: none;
                @include touch {
                    width: 40px; } } }

        &-metadata {
            display: flex;
            flex-direction: column;
            flex: 1;
            border-top: 1px solid #DADADA;
            border-bottom: 1px solid #DADADA;
            padding: 12px 0 50px;
            margin-left: 20px;
            position: relative;

            @include touch {
                order: 4;
                margin-top: 15px;
                margin-left: 0; }

            &--btn {
                font-size: 0.9375rem;
                box-shadow: 0px -11px 30px #f5f5f5;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding: 0 15px;
                position: absolute;
                bottom: 10px;

                &:focus {
                    box-shadow: 0px -11px 30px #f5f5f5; }

                &:hover {
                    color: #22737B; }

                @include touch {
                    font-size: 0.875rem; }

                &-active {
                    box-shadow: none;

                    &:focus {
                        box-shadow: none; }

                    i:before {
                        transform: rotate(180deg); } } }

            &--list {
                padding-left: 0;
                list-style: none;
                font-size: 0.875rem;
                color: #4B4B4B;
                text-align: left;
                // flex: 1
                // overflow: hidden

                // &-height
                //  // max-height: 170px

                // &-opened
                //  // max-height: 100%

                &-secondary {
                    display: none;

                    &:empty + button {
                        display: none; } }

                &-active {
                    display: block; }

                @include touch {
                    font-size: 0.75rem; } }

            &--item {
                display: flex;
                line-height: 1.75rem;
                margin-bottom: 5px;
                @include touch {
                    flex-direction: column;
                    line-height: 1.25rem;
                    margin-bottom: 5px; } }

            &--label {
                font-weight: 700;
                flex: 0 1 185px;
                line-height: 1.25rem;
                @include touch {
                    flex: 1; }

                i {
                    color: #22737B;
                    line-height: 1rem;
                    font-size: 0.875rem;
                    vertical-align: top; } }

            &--desc {
                flex: 1;
                word-break: break-word;
                line-height: 1.25rem;

                span:not(:last-child) {
                    &:after {
                        content: "; "; } } }

            &--chapter, &--publisher, &--newspaper {
                margin-bottom: 8px;
                font-size: 0.875rem;
                line-height: 1.25rem;

                @include touch {
                    font-size: 0.75rem; }

                span:not(:last-child) {
                    &:after {
                        content: ", "; } } }

            &--chapter {}


            &--info {
                margin-bottom: 8px; } } }


    &__share {
        line-height: 1.15;

        &:before {
            content: "|";
            margin-left: 10px;
            margin-right: 3px;
            color: #22737B; }

        &-button {
            @extend .results__item-bottom--copy; }

        &:after {
            display: none; }

        &-list {
            list-style-type: none;
            padding: 0;
            margin: 0; }

        &-item {
            a {
                display: flex;
                padding: 5px 10px;
                color: #22737B;

                &:focus, &:hover {
                    background: rgba(0,0,0,0.025); }

                svg {
                    flex: 0 0 40px;
                    width: 15px;
                    height: 15px;
                    position: relative;
                    top: 2px; }

                path {
                    fill: #8b8b8b; } } } }

    &__nav {
        background: #EAEAEA;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 0;
        @include mobile {
            justify-content: flex-end; }
        @include desktop {
            padding-left: 30px; }
        @include hd {
            padding-left: 70px; }
        @include fullhd {
            padding-left: 95px; }

        &::after {
            content: '';
            display: block;
            background: #EAEAEA;
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0;
            z-index: -1;
            margin-right: -100%;
            top: 0;
            @include touch {
                width: 200%;
                margin-right: -50%; } }

        &--bottom {
            justify-content: flex-end; } }

    &__page-size {
        @include mobile {
            display: none; } } }

 :global(.contrast) {
    .results {
        &__no-results {
            color: #FFF; }
        &__line {
            border-color: #333333; }
        &__sidebar {
            @include desktop {
                border-right: 1px solid #333; } }
        &__title {
            color: #FFF; }
        &__nav {
            background: #0D0C0C;
            border-bottom: 2px solid #333;
            &::after {
                background: #0D0C0C; } }
        &__loading {
            img {
                filter: invert(1) brightness(100); } }
        &__list {
            background-color: #333333; }
        &__item {
            background-color: #0D0C0C;
            &::after {
                background: #0D0C0C; }
            &-img {
                background-color: #070606;
                border-color: #707070;
                i {
                    color: #FFF; } }
            &-authors {
                color: #FFF; }
            &-title {
                color: #FFF500;
                &--no-link {
                    color: #FFF; } }
            &-metadata {
                border-color: #333333;
                &--list,
                &--newspaper,
                &--publisher,
                &--chapter {
                    color: #FFF; }
                &--label {
                    i {
                        color: #FFF500; } }
                &--btn {
                    box-shadow: 0px -11px 30px #0d0c0c;
                    color: #FFF500;
                    &-active {
                        &,
                        &:focus {
                            box-shadow: none; } } } }
            &-bottom {
                &--type {
                    color: #FFF;
                    i {
                        color: #9CA3A8; } } } }
        &__share-item a {
            color: #FFF500; } } }

 :global(.font-medium) {
    .results {
        &__item-metadata {
            &--item {
                line-height: 1.5rem; } } } }

 :global(.font-big) {
    .results {
        &__item-metadata {
            &--item {
                line-height: 1.4rem; } } } }
