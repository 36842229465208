@import "@/styles/_breakpoints.sass";

.breadcrumbs {
  width: 100%;
  padding: 10px 0;
  @include mobile {
    padding: 10px 15px; }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    &-item {
      font-size: 0.875rem;
      line-height: 1rem;
      margin-left: 10px;
      &:before {
        content: '';
        height: 10px;
        border-left: 1px solid #B2B2B2;
        margin-right: 10px;
        display: inline-flex; }
      &:first-of-type {
        margin-left: 0;
        &:before {
          content: none; } }
      &--home {
        color: #22737B;
        span {
          display: none; }
        &:hover {
          color: #22737B; }
        &:active {
          color: #4D4D4D; }
        &:focus {
          outline: 1px solid #236B83; } }
      &--link {
        color: #22737B;
        &:hover {
          color: #22737B; }
        &:active {
          color: #4D4D4D; }
        &:focus {
          outline: 1px solid #236B83; } }
      &--label {
        color: #4B4B4B; } } } }

 :global(.contrast) {
    .breadcrumbs__list {
      &-item {
        border-color: #5A5959;
        &--home {
          color: #FFF500;
          &:focus {
            outline: 1px solid #fff; } }
        &--label {
          color: #FFF;
          &:focus {
            outline: 1px solid #fff; } } } } }
