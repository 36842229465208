@import "@/styles/_breakpoints.sass";

.map {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: baseline; }

    &__info {
        margin-top: 20px;
        @include touch {
            display: none; } }

    &__legend {
        margin-top: 10px;
        border-top: 1px solid rgb(204, 204, 204);
        padding: 8px 10px 10px;
        background: #F5F5F5;
        display: flex;
        align-items: baseline;
        @include touch {
            display: none; }


        &--list {
            margin: 0;
            padding: 0;
            list-style-type: none;
            @include widescreen {
                display: flex; } }

        &--item {
            padding: 0 5px;
            position: relative;

            &:not(:last-child) {
                &:after {
                    @include widescreen {
                        position: absolute;
                        right: 4px;
                        top: 0.5em;
                        content: "";
                        width: 1px;
                        height: 1em;
                        display: block;
                        background: rgb(204, 204, 204); } } } }

        &--img-cluster, &--img-object {
            margin-right: 5px; }

        &--img-cluster {
            height: 22px; }

        &--img-object {
            height: 17px; } }

    &__box {
        flex: 1;
        position: relative;

        &--loading {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 999;

            img {
                width: 50px;
                @include touch {
                    width: 25px; } } } }

    &__close {
        span {
            @include touch {
                display: none; } } }

    &__popup {
        background: red;
        & > div {
            border-radius: 0 !important;
            max-height: 270px;
            overflow-y: auto; }

        & > a {
            margin: 10px 16px; }

        &--loading {
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 50px; } }


        &--list {
            margin: 0;
            padding: 0;
            list-style-type: none; }

        &--item {
            padding: 10px 0;

            &:not(:last-child) {
                border-bottom: 1px solid #ccc; } }

        &--buttons {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: 7px;
            margin-bottom: -3px; }


        &--button {
            background: #22677E;
            padding: 5px;
            margin: 3px 0;
            color: white !important; } }


    &__text {
        &--title {
            font-size: 1rem;
            line-height: 1.3rem;
            @include touch {
                padding: 0 10px;
                font-weight: bold;
                text-transform: uppercase; }
            @include desktop {
                font-size: 1.375rem;
                line-height: 1.4375rem; }
            @include fullhd {
                font-size: 1.75rem;
                line-height: 2rem; } }

        &--info-main {
            font-weight: bold;
            font-size: 1rem; }

        &--info {
            font-size: 0.9rem; }

        &--legend-title {
            font-size: 0.85em;
            font-weight: normal;
            margin-right: 5px; }

        &--legend-span {
            font-size: 0.85em;
            margin-right: 10px; }

        &--popup-title {
            font-size: 1rem;
            margin: 0; }

        &--popup-text {
            font-size: 0.8rem;
            margin: 5px 0 0 !important; } }


    &__container {
        height: 100%;
        width: 100%; }

    &__tooltip {
        &--cluster {
            background: transparent !important;
            border: 0 !important;
            border-radius: 0 !important;
            color: #fff !important;
            box-shadow: none !important;
            margin-left: 0 !important;
            width: 40px;
            text-align: center;

            &:before {
                display: none !important; } } } }

 :global(.contrast) {
    .map {
        &__legend {
            background: #fff000;
            border-color: #fff000;

            &--img-cluster, &--img-object {
                -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(1);
                filter: invert(30%) grayscale(100%) brightness(70%) contrast(1); }

            &--item {
                &:not(:last-child) {
                    &:after {
                        background: black; } } } }

        &__text {
            &--title, &--info-main, &--info, &--popup-title, &--popup-text {
                color: white; }

            &--legend-title, &--legend-span {
                color: black; } }

        &__popup {
            &--button {
                background: #fff000;
                color: black !important; } } } }


