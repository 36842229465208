@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&display=swap');

html, #root {
  height: 100%;
  font-size: 16px;
  &.medium {
    font-size: 18px; }
  &.big {
    font-size: 20px; } }

body {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

