@import "@/styles/_breakpoints.sass";

.error {
    padding: 100px 0 150px;
    display: flex;
    flex-direction: column;
    @include touch {
        padding: 50px 0 100px; }
    @include mobile-small {
        padding-left: 15px;
        padding-right: 15px; }

    &__header {
        font-size: 1.3rem;
        margin: 15px 0; }

    &__code {
        font-size: 1.2rem;
        font-weight: 600;
        margin: 0 0 20px; }

    &__causes {
        margin-bottom: 10px;
        &-list {
            font-size: 0.9rem; } }

    &__apologize {
        margin: 10px 0 5px;
        font-weight: 600; }

    &__back {
        &,
        &:hover,
        &:focus {
            color: #22737B; } } }
