@import 'bootstrap/scss/bootstrap.scss';
//PLACE BOOTSTRAP OVVERRIDES HERE

.container-fluid {
    padding: 0; }

.container {
    @media (min-width: 1920px) {
        max-width: 1740px; } }
.dropdown-menu {
    padding: 7px 0;
    z-index: 1; }

.dropdown {
    &.show {
        .dropdown-toggle::after {
            transform: rotate(180deg); } } }

.bg-light {
    background-color: #fff !important; }

.bg-transparent {
    background-color: transparent; }

.form-control {
    width: 100%;
    &:focus {
        box-shadow: none; } }

select.form-control {
    &:focus {
        background-color: #236B83;
        border: solid 1px #707070;
        color: #fff;
        @include placeholder;
        color: #fff; } }

label {
    margin: 0; }
