.cookies {
    background: #1D1B19;
    color: #FFFFFF;
    padding: 12px 15px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    @media (max-width: 1366px) {
        padding: 10px 15px 9px; }
    @media (max-width: 480px) {
        padding: 15px 25px 14px; }
    .row {
        justify-content: space-between;
        align-items: center;
        @media (max-width: 991px) {
            flex-direction: column;
            align-items: normal; } }
    &__left {
        text-align: left;
        flex: 1 1;
        &-icon {
            font-size: 1.3rem;
            display: inline-flex;
            &:before {
                line-height: 1.4rem; } }
        &-text {
            font-size: 0.875rem;
            line-height: 1.5rem;
            @media (max-width: 1366px) {
                font-size: 0.75rem; }
            @media (max-width: 991px) {
                line-height: 1.125rem; } }
        &-link {
            color: #FFFFFF;
            text-decoration: underline;
            &:hover {
                color: #FFFFFF; }

            &:focus {
                outline: 1px solid #FFFFFF;
                outline-offset: 2px; } } }
    &__btn {
        align-self: center;
        padding: 7px 25px 7px;
        font-size: 0.875rem;
        @media (max-width: 1366px) {
            font-size: 0.75rem; }
        @media (max-width: 991px) {
            align-self: flex-end;
            margin-top: 5px;
            padding: 4px 18px 6px; }

        &:focus {
            border-color: white;
            color: white; } } }


