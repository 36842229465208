@import "@/styles/_breakpoints.sass";

.menu {
    background-color: #FFF;
    flex: 0 0 395px;
    padding: 30px 0px;
    @include touch {
        flex: 0 1 auto; }

    &__container {
        margin: 0 0 15px; }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        @include mobile-small {
            margin-left: 10px; }

        &--top {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            @include mobile-small {
                padding: 4px 15px; } }

        &--title {
            font-size: 0.875rem;
            font-weight: 700;
            margin: 0;
            white-space: nowrap; }

        &--text {
            margin: auto;
            margin-left: 5px;
            font-weight: 500;
            font-size: 0.875rem;
            white-space: nowrap;
            @include touch {
                white-space: normal; } } } }

 :global(.contrast) {
    .menu {
        background-color: #0D0C0C;
        color: #FFF;
        border-top-color: #333;
        @include desktop {
            border-right: 1px solid #333; } } }
