@import "@/styles/_mixins.sass";
@import "@/styles/_breakpoints.sass";

.actions {
    list-style-type: none;
    margin: 0 -7px;
    padding: 0;
    display: flex;
    line-height: 1.3rem;
    @include fullhd {
        margin: 0 -10px; }

    &__item {
        padding: 0 7px;
        position: relative;
        @include fullhd {
            padding: 0 10px; }

        &:not(:last-child) {
            &:after {
                content: "|";
                position: absolute;
                right: -2px;
                top: 50%;
                color: #B2B2B2;
                line-height: 0;
                @include fullhd {
                    right: -3px; } } } }

    &__button {
        padding: 0;
        font-size: 0.8rem;
        @include fullhd {
            font-size: 0.9rem; } }

    &__delete {
        padding: 0;
        margin: 0;
        @include desktop {
            visibility: hidden;
            pointer-events: none; } } }

 :global(.contrast) {
    .actions {
        &__button {
            color: #FFF500; } } }
