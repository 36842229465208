.dropdown {
    &-toggle {
        &:focus {
            outline: 1px solid #236B83; } }

    &-item {
        &:focus {
            outline: 1px solid #236B83; } } }

.contrast {
    .dropdown {
        &-toggle, &-item {
            &:focus {
                outline: none; } } } }
