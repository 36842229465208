@import "@/styles/_breakpoints.sass";
@import "@/styles/_mixins.sass";

.btn {
  user-select: initial; }

.btn-primary {
  background-color: #236B83;
  border-color: #64A3AC;
  color: #fff;
  border-radius: 4px;
  &:hover,
  &:focus {
    background-color: #185367;
    border-color: #508D95; }
  &:focus {
    box-shadow: none;
    text-decoration: underline; }
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: #0E2E39;
    border-color: #3E767E;
    &:focus {
      box-shadow: none; } }
  &.disabled, &:disabled {
    background-color: #236B83;
    border-color: #64A3AC;
    color: #fff;
    opacity: 0.5; } }

.btn.disabled, .btn:disabled {
  opacity: 0.5; }

.btn-secondary {
  background-color: #22677E; }

.btn-success {
  background-color: #22677E; }

.btn-warning {
  background-color: #22677E; }

.btn-danger {
  background-color: #22677E; }

.btn-light {
  background-color: #22677E; }

.btn-dark {
  background-color: #22677E; }

.btn-gray {
  background-color: #ECEEEF;
  border-color: #DDDDDD;
  color: #828a91; }

.btn-shadow {
  outline: 0;
  color: #71CDD5;
  background: rgba(28, 28, 28, 0.52);

  &:hover {
    color: #71CDD5;
    background: rgba(28, 28, 28, 0.52);

    span {
      text-decoration: underline; } }

  &:active {
    color: white;
    background-color: rgba(28, 28, 28, 0.52); }

  &:focus {
    box-shadow: none; } }

.btn-transparent {
  background-color: transparent;
  color: #22737B; }

.btn-link {
  background-color: transparent;
  color: #22737B;
  &:active, &:hover, &:focus {
    color: #185367;
    text-decoration: underline; } }

.btn-link-external {
  background-color: transparent;
  color: #22737B;
  padding: 0;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  &:hover {
      color: #22737B;
      text-decoration: underline; }
  &:active {
      color: #185367; }
  i {
    font-size: 0.65rem;
    line-height: 0.7rem;
    vertical-align: text-top; } }

.btn-rounded {
  background-color: transparent;
  border: 1px solid #64A3AC;
  color: #71CDD5;
  border-radius: 2rem;
  &:hover {
    background-color: #236B83;
    color: #FFFFFF; }
  &:focus {
    box-shadow: none; }
  &:active {
    background-color: #185367; } }

.btn-link, .btn-link-external {
  &:focus {
    outline: 1px solid #22737B;
    outline-offset: 2px; } }

.contrast {
  .btn-link, .btn-link-external {
    &:focus {
      outline: 1px solid #fff000; } } }
